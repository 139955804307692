import React, { Fragment, useEffect, useRef, useState } from 'react';

import { Flex, Row, Col, Input, Typography, Button, Form, Modal, Divider, Select, Collapse, Avatar, Upload, Image } from 'antd';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DataTable from '../../components/data-table';
import CommonPopconfirm from '../../components/custom-popconfirm';

import SearchIcon from '../../components/icon/SearchIcon';
import CloseIcon from '../../components/icon/CloseIcon';
import EditIcon from '../../components/icon/EditIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';
import ExpandIcon from '../../components/icon/ExpandIcon';
import UploadIcon from '../../components/icon/UploadIcon';
import ScannerIcon from '../../components/icon/ScannerIcon';
import BlackCloseIcon from '../../components/icon/BlackCloseIcon';

import { createInventory, deleteInventory, getByIDBarcodeScanData, getByIdInventory, getInventory, getInventoryListData, getLagerByCityList, getRackByLagerList, getSelfNumberByLagerList, updateInventory } from './redux/slice';
import { black } from '../../utils/theme/config';
import { getCityListing, getRackNumberListing, getRackSelfNumberListing } from '../../redux/slice/global';
import openNotification from '../../components/notification';

const { Title, Text } = Typography;

const InventoryManagement = ({ permission }) => {
    const [form] = Form.useForm();
    const [filterForm] = Form.useForm();

    const dispatch = useDispatch();
    const { inventory, listing, auth } = useSelector(state => state);

    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [isEdit, setIsEdit] = useState(false);
    const [totalRecords, setTotalRecords] = useState([]);
    const [cityListing, setCityListing] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [editId, setEditId] = useState('');
    const [isWarehouseDisabled, setWarehouseDisabled] = useState(true);
    const [isRackDisabled, setRackDisabled] = useState(true);
    const [isSelfDisabled, setSelfDisabled] = useState(true);
    const [inventoryData, setInventoryData] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const qrCodeRef = useRef(null);
    const [filterFields, setFilterFields] = useState({});

    const checkPermissionsAndStartScanning = async () => {
        try {
            // Request camera permissions
            await navigator.mediaDevices.getUserMedia({ video: true });
            startScanning(); // Start scanning if permissions are granted
        } catch (err) {
            openNotification({ message: t('organizer_common_camerapermission'), type: 'error' })
        }
    };

    const startScanning = () => {
        const qrCodeRegionId = 'reader';

        if (!qrCodeRef.current) {
            qrCodeRef.current = new Html5Qrcode(qrCodeRegionId);
        }

        qrCodeRef.current
            .start(
                { facingMode: 'environment' }, // Use the back camera
                {
                    fps: 10,
                    qrbox: { width: 'auto', height: 'auto' },
                    supportedScanTypes: [
                        Html5QrcodeSupportedFormats.CODE_128,
                        Html5QrcodeSupportedFormats.EAN_13,
                        Html5QrcodeSupportedFormats.UPC_A,
                    ], // Add supported barcode formats
                },
                (decodedText) => {
                    if (decodedText) {
                        dispatch(getByIDBarcodeScanData({ id: decodedText, organizer: auth?.decodeToken?.organizer }))
                    }
                    stopScanning();
                },
                (errorMessage) => { }
            )
            .catch((err) => { });
    };

    useEffect(() => {
        if (Object.keys(inventory?.barcodeScanData).length > 0) {
            inventory?.barcodeScanData?.city && dispatch(getLagerByCityList({ city: inventory?.barcodeScanData?.city, organizer: auth?.decodeToken?.organizer }));
            dispatch(getRackByLagerList({ lagar: inventory?.barcodeScanData?.lager, organizer: auth?.decodeToken?.organizer }));
            dispatch(getSelfNumberByLagerList({ rackNumber: inventory?.barcodeScanData?.rackNumber, organizer: auth?.decodeToken?.organizer }));
            inventory?.barcodeScanData?.city ? setWarehouseDisabled(false) : setWarehouseDisabled(true);
            inventory?.barcodeScanData?.rackNumber ? setSelfDisabled(false) : setSelfDisabled(true);
            inventory?.barcodeScanData?.lager ? setRackDisabled(false) : setRackDisabled(true);
            form.setFieldsValue({ city: inventory?.barcodeScanData.city, warehouseName: inventory?.barcodeScanData.lager, rackNumber: inventory?.barcodeScanData?.rackNumber, rackSelfNumber: inventory?.barcodeScanData?.rackSelfNumber })
        }
    }, [inventory?.barcodeScanData, auth?.decodeToken?.organizer])

    const stopScanning = () => {
        qrCodeRef.current?.stop().then(() => {
            qrCodeRef.current = null;
        });
        setIsModalOpen(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setTimeout(() => checkPermissionsAndStartScanning(), 500); // Delay to ensure modal is rendered
    };

    const handleCloseModal = () => {
        stopScanning();
    };


    const handleOnFinish = async (values) => {
        delete values.files
        const formData = new FormData();
        for (const item of fileList ?? []) {
            if (item?.originFileObj) {
                formData.append('photo', item.originFileObj);
            } else if (item?.url) {
                try {
                    const response = await fetch(item.url);
                    const blob = await response.blob();
                    const fileName = item.name || 'file-from-url.png';
                    formData.append('photo', new File([blob], fileName, { type: blob.type }));
                } catch (error) {
                    console.error(`Failed to fetch file from URL: ${item.url}`, error);
                }
            }
        }

        Object.entries(values).forEach(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
                Object.entries(value).forEach(([subKey, subValue]) => {
                    formData.append(`${key}[${subKey}]`, subValue);
                });
            } else {
                formData.append(key, value);
            }
        });

        formData.append('user', auth.decodeToken._id);
        formData.append('organizer', auth?.decodeToken?.organizer);

        if (editId) {
            formData.append('id', editId);
            dispatch(updateInventory({ inventoryData: formData, organizer: auth?.decodeToken?.organizer }));
            form.resetFields();
            setFileList([]);
            setOpen(false);
        } else {
            dispatch(createInventory({ inventoryData: formData, organizer: auth?.decodeToken?.organizer }));
            form.resetFields();
            setFileList([]);
            setOpen(false);
        }
    };

    const handleClear = () => {
        form.resetFields();
        setFileList([]);

    };

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    const handleFilterFinish = (values) => {
        setFilterFields(values);
    };

    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    const handleFilterClear = () => {
        if (Object.keys(filterFields).length > 0) {
            setFilterFields({});
        }
        filterForm.resetFields();
    }

    const filterItems = [
        {
            key: '1',
            label: <Title level={4} type='success' className='text-primary'>{t('organizer_common_filter')}</Title>,
            children: <Fragment>
                <Form
                    form={filterForm}
                    className='user-filter'
                    name='filters'
                    colon={false}
                    layout='vertical'
                    onFinish={handleFilterFinish}
                >
                    <Row gutter={18}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='uniqueIdentifier'
                                label={t('organizer_common_UUID')}
                            >
                                <Input placeholder={t('organizer_common_UUID')} />
                            </Form.Item>
                        </Col>

                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='productSKU'
                                label={t('organizer_common_productSKU')}
                            >
                                <Input placeholder={t('organizer_common_productSKU')} />
                            </Form.Item>
                        </Col>

                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='rackNumber'
                                label={t('organizer_common_racknumber')}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder={t('organizer_common_racknumber')}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={listing?.listing?.rackNumbers}
                                    onChange={(value) => handleFieldChange('rackNumber', value)}
                                />
                            </Form.Item>
                        </Col>

                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='rackSelfNumber'
                                label={t('organizer_common_selfnumber')}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder={t('organizer_common_selfnumber')}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={listing?.listing?.rackSelfNumbers}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' htmlType='button' onClick={handleFilterClear}>{t('organizer_common_clearbutton')}</Button>
                        <Button type='primary' htmlType='submit'>{t('organizer_common_applybutton')}</Button>
                    </Flex>
                </Form>
            </Fragment >,
        }
    ];

    const handleOnChange = () => {
        setPageSize(pageSize);
        setPage(page);
    };

    const handleConfirm = (inventoryId) => {
        dispatch(deleteInventory({
            id: inventoryId,
            sortBy: '',
            order: '',
            search: '',
            page: page,
            limit: pageSize,
            organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer
        }));
    };

    const columns = [
        {
            title: t('organizer_common_photo'),
            dataIndex: 'photo',
            key: 'photo',
            // width: '200px',
            render: (_, record) => {
                return (
                    <Fragment>
                        <Avatar shape='square' src={`${process.env.REACT_APP_API_URI}/inventory/${record.photo[0]}`} size={'large'} />
                    </Fragment>
                )
            }
        },
        {
            title: t('organizer_common_UUID'),
            dataIndex: 'uniqueIdentifier',
            key: 'uniqueIdentifier',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'uniqueIdentifier'),
        },
        {
            title: t('organizer_common_productName'),
            dataIndex: 'productName',
            key: 'productName',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'productName'),
        },
        {
            title: t('organizer_common_productSKU'),
            dataIndex: 'productSKU',
            key: 'productSKU',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'productSKU'),
        },
        {
            title: t('organizer_warehousemanagement_lager_lagerID'),
            dataIndex: 'lagerID',
            key: 'lagerID',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'lagerID'),
        },
        {
            title: t('organizer_common_whName'),
            dataIndex: 'warehouseName',
            key: 'warehouseName',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'warehouseName'),
        },
        {
            title: t('organizer_common_racknumber'),
            dataIndex: 'rackNumber',
            key: 'rackNumber',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'rackNumber'),
        },
        {
            title: t('organizer_common_selfnumber'),
            dataIndex: 'rackSelfNumber',
            key: 'rackSelfNumber',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'rackSelfNumber'),
        },
        {
            title: t('organizer_common_quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'quantity'),
        },
    ];

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: <Flex justify='center'><Text>{t('superadmin_common_action')}</Text></Flex>,
            key: 'action',
            width: '50px',
            render: (_, record) => (
                <Flex justify='center'>
                    {
                        permission?.canUpdate &&
                        <Button type='text'
                            className='padding-1'
                            onClick={() => handleEditInventory(record)}
                        >
                            <EditIcon role='button' />
                        </Button>
                    }
                    {
                        permission?.canDelete &&
                        <Fragment>
                            {
                                <CommonPopconfirm
                                    icon={null}
                                    title={t('organizer_common_deleteTitle') + ' ' + t('organizer_common_inventory')?.toLowerCase()}
                                    description={t('organizer_common_confirmationmessage') + ' ' + t('organizer_common_inventory') + '?'}
                                    onConfirm={() => handleConfirm(record?._id)}
                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                >
                                    <Button
                                        type='text'
                                        className='padding-1'>
                                        <DeleteIcon
                                            role='button'
                                        />
                                    </Button>
                                </CommonPopconfirm>

                            }
                        </Fragment>
                    }
                </Flex>
            ),
        })
    };

    useEffect(() => {
        dispatch(getCityListing());
        auth?.decodeToken?.organizer && dispatch(getRackNumberListing(auth?.decodeToken?.organizer));
        auth?.decodeToken?.organizer && dispatch(getRackSelfNumberListing(auth?.decodeToken?.organizer));
    }, [auth?.decodeToken?.organizer]);

    useEffect(() => {
        setCityListing(listing?.listing?.city)
    }, [listing?.listing?.city]);

    const handleFieldChange = (type, value) => {
        if (type === 'city') {
            dispatch(getLagerByCityList({ city: value, organizer: auth?.decodeToken?.organizer }));
            form.setFieldsValue({ warehouseName: undefined });
            setWarehouseDisabled(false);
        }

        if (type === 'lager') {
            setRackDisabled(false);
            form.setFieldsValue({ rackNumber: undefined });
            dispatch(getRackByLagerList({ lagar: value, organizer: auth?.decodeToken?.organizer }));
        }

        if (type === 'rackNumber') {
            setSelfDisabled(false);
            form.setFieldsValue({ rackSelfNumber: undefined });
            dispatch(getSelfNumberByLagerList({ rackNumber: value, organizer: auth?.decodeToken?.organizer }));
        }
    }

    const onUploadChange = ({ file, fileList }) => {
        const isValidSize = file.size / 1024 / 1024 <= 5;

        if (!isValidSize) {
            openNotification({ type: 'error', message: t(`${file.name} exceeds the size limit of ${5}MB.`) });
            return;
        } else {
            setFileList(fileList);
        }
    };

    const handleDelete = (uid) => {
        setFileList((prevList) => prevList.filter((file) => file.uid !== uid));
    };

    useEffect(() => {
        setInventoryData(inventory?.inventoryList);
        setTotalRecords(inventory.totalRecords);
    }, [inventory?.inventoryList, inventory.totalRecords, filterFields]);

    useEffect(() => {
        auth?.decodeToken?.organizer && getInventoryList();
    }, [pageSize, page, sortBy, order, search, auth, filterFields]);

    const getInventoryList = () => {
        dispatch(getInventoryListData({
            sortBy: sortBy,
            order: order,
            search: search?.trim(),
            filter: {
                uniqueIdentifier: filterFields?.uniqueIdentifier || '',
                productSKU: filterFields?.productSKU || '',
                rackNumber: filterFields?.rackNumber || '',
                rackSelfNumber: filterFields?.rackSelfNumber || ''
            },
            page: page,
            limit: pageSize,
            organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer
        }))
    };

    const handleEditInventory = (record) => {
        setWarehouseDisabled(false);
        setSelfDisabled(false);
        setRackDisabled(false);
        setOpen(true);
        setIsEdit(true);
        setEditId(record._id);
        dispatch(getByIdInventory(record?._id));
    };

    useEffect(() => {
        if (Object.keys(inventory?.inventory).length > 0) {
            dispatch(getLagerByCityList({ city: inventory?.inventory?.city, organizer: auth?.decodeToken?.organizer }));
            dispatch(getRackByLagerList({ lagar: inventory?.inventory?.warehouseName, organizer: auth?.decodeToken?.organizer }));
            dispatch(getSelfNumberByLagerList({ rackNumber: inventory?.inventory?.rackNumber, organizer: auth?.decodeToken?.organizer }));

            form.setFieldsValue({
                ...inventory?.inventory,
            });

            inventory?.inventory?.photo && setFileList(
                inventory?.inventory?.photo?.map((item) => ({
                    uid: item?.uid || item,
                    name: item?.name || `Uploaded File`,
                    status: 'done',
                    url: item?.url || `${process.env.REACT_APP_API_URI}/inventory/${item}`,
                }))
            );
        }

    }, [inventory?.inventory, editId])

    return (
        <Fragment>
            <Row
                justify='space-between'
                align='middle'
                className='bg-white padding-1 rounded-1'
                gutter={[16, 16]}
            >

                <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Row align='middle' gutter={[16, 16]}>
                        <Col flex='none'>
                            <Title level={4} type='success'>{t('organizer_warehousemanagement_inventory_inventorytitle')}</Title>
                        </Col>
                        <Col flex='auto' xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Input
                                value={search}
                                placeholder={t('organizer_common_search')}
                                onChange={(e) => setSearch(e.target.value)}
                                prefix={<SearchIcon color='#000' size={20} />}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                    <Flex justify='end'>
                        {
                            (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                            <Button onClick={() => { setOpen(true); form.resetFields(); setIsEdit(false); setEditId(''); dispatch(getInventory({})); setFileList([]); }}>{t('organizer_warehousemanagement_inventory_addinventory')}</Button>
                        }
                        <Modal
                            open={open}
                            closeIcon={<CloseIcon color={black} role='button' />}
                            title={<Fragment><Title level={4} type='success'>{isEdit ? t('organizer_warehousemanagement_inventory_editinventory') : t('organizer_warehousemanagement_inventory_addinventory')}</Title><Divider /></Fragment>}
                            footer={null}
                            onCancel={() => { setOpen(false); setIsEdit(false); setEditId(''); form.resetFields(); dispatch(getInventory({})) }}
                            modalRender={(dom) => (
                                <Form
                                    layout='vertical'
                                    form={form}
                                    name='inventory-form'
                                    requiredMark={(label, isRule) => {
                                        return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                                    }}
                                    onFinish={(values) => handleOnFinish(values)}
                                >
                                    {dom}
                                </Form>
                            )}
                        >
                            <Row gutter={18}>
                                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Flex align='center' justify='end' className='h-100 padding-bottom-1'>
                                        <Button icon={<ScannerIcon onClick={handleOpenModal} role='button' />} type='text' />
                                    </Flex>
                                </Col>

                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Form.Item
                                        name='productName'
                                        label={t('organizer_common_productName')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_productName') + ' ' + t('organizer_common_isrequired'),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t('organizer_common_productName')} />
                                    </Form.Item>
                                </Col>

                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Form.Item
                                        name='city'
                                        label={t('organizer_common_city')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_city') + ' ' + t('organizer_common_isrequired'),
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder={t('organizer_common_city')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={(value) => handleFieldChange('city', value)}
                                            options={cityListing}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Form.Item
                                        name='warehouseName'
                                        label={t('organizer_common_warehouseName')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_warehouseName') + ' ' + t('organizer_common_isrequired'),
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder={t('organizer_common_warehouseName')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={(value) => handleFieldChange('lager', value)}
                                            disabled={isWarehouseDisabled}
                                            options={inventory?.warehouseName}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Form.Item
                                        name='rackNumber'
                                        label={t('organizer_common_racknumber')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_racknumbererror'),
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder={t('organizer_common_racknumber')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={inventory?.rackNumbersLists}
                                            disabled={isRackDisabled}
                                            onChange={(value) => handleFieldChange('rackNumber', value)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Form.Item
                                        name='rackSelfNumber'
                                        label={t('organizer_common_selfnumber')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_selfnumber') + ' ' + t('organizer_common_isrequired')
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder={t('organizer_common_selfnumber')}
                                            disabled={isSelfDisabled}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={inventory?.selfNumbersLists}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Form.Item
                                        name='quantity'
                                        label={t('organizer_common_quantity')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_quantity') + ' ' + t('organizer_common_isrequired'),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t('organizer_common_quantity')} />
                                    </Form.Item>
                                </Col>

                                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Form.Item
                                        name='files'
                                        // rules={[{ required: fileList?.length <= 0, message: t('organizer_common_uploadedfileerror') }]}
                                        valuePropName='files'
                                        getValueFromEvent={(e) => e && e.fileList}
                                    >
                                        <Upload.Dragger multiple={true} showUploadList={false} name='files' fileList={fileList} onChange={onUploadChange} accept='.png,.jpg,.jpeg,.svg,.webp,.heic,.heif'>
                                            <p className='ant-upload-drag-icon'>
                                                <UploadIcon size={30} />
                                            </p>
                                            <Text className='ant-upload-text' strong>{t('organizer_common_uploadedfilehere')}</Text>
                                        </Upload.Dragger>

                                        <Flex vertical gap={12} className='padding-top-1'>
                                            <Text type='secondary'>{t('organizer_common_uploadedphotodescription')}</Text>

                                            <Text type='secondary'>
                                                {t('organizer_common_uploaddocumentsize')}
                                            </Text>
                                        </Flex>
                                    </Form.Item>
                                </Col>

                                {fileList.length > 0 && (
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {fileList.map((file) => (
                                            <div key={file?.uid || file} style={{ position: 'relative', margin: '8px' }}>
                                                {file?.originFileObj ? (
                                                    <>
                                                        <Image
                                                            src={URL.createObjectURL(file.originFileObj)}
                                                            alt={file.name}
                                                            style={{
                                                                width: '100px',
                                                                height: '100px',
                                                                objectFit: 'cover',
                                                                borderRadius: '0.5rem',
                                                            }}
                                                        />
                                                        <Button
                                                            type='link'
                                                            icon={<BlackCloseIcon role='button' />}
                                                            onClick={() => handleDelete(file.uid)}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-14px',
                                                                right: '-9px',
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Image
                                                            src={file.url}
                                                            alt={file.name}
                                                            style={{
                                                                width: '100px',
                                                                height: '100px',
                                                                objectFit: 'cover',
                                                                borderRadius: '0.5rem',
                                                            }}
                                                        />
                                                        <Button
                                                            type='link'
                                                            icon={<BlackCloseIcon role='button' />}
                                                            onClick={() => handleDelete(file.uid)}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-14px',
                                                                right: '-9px',
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Row>

                            <Form.Item className='margin-0'>
                                <Row gutter={16}>
                                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Button type='primary' danger block htmlType='button' onClick={handleClear}>{t('organizer_common_cancelButton')}</Button>
                                    </Col>
                                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Button type='primary' block htmlType='submit' loading={inventory?.loading}>{t('organizer_common_submitButton')}</Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Modal>
                    </Flex>
                </Col>
            </Row>

            <Flex className='paddingY-3'>
                <Collapse
                    items={filterItems}
                    defaultActiveKey={['1']}
                    className='w-100'
                    bordered={false}
                    expandIcon={customExpandIcon}
                    expandIconPosition={'end'}
                />
            </Flex>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <DataTable
                        columns={columns}
                        pageSize={pageSize}
                        currentPage={page}
                        total={totalRecords}
                        scrollX={1000}
                        showSorterTooltip={false}
                        isPagination={true}
                        handleOnChange={handleOnChange}
                        data={inventoryData}
                        rowKey={(record) => record?._id}
                    />
                </Col>
            </Row>
            {/* Barcode Scan */}
            <Modal
                width={650}
                title='Barcode Scanner'
                open={isModalOpen}
                onCancel={handleCloseModal}
                footer={null}
            >
                <div id='reader' style={{ width: '100%', height: 'auto' }}></div>
            </Modal>
        </Fragment>
    )
}

export default InventoryManagement