import React, { useState, useEffect } from 'react';
import { Layout, Menu, Image, Flex, Typography } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import logo from '../../assets/logo/logo.svg';
import '../../styles/layout/sidebar.scss';
import { menuName } from '../../utils/constraints';
import WorkHistory from '../../pages/settings/WorkHistory';

const { Sider } = Layout;
const { Title } = Typography;

const drawerWidth = 260;

const SidebarComponent = ({ collapsed, setCollapsed, filteredRoutes }) => {
    const { listing } = useSelector(state => state)
    const [openKeys, setOpenKeys] = useState([]);
    const [selectedKey, setSelectedKey] = useState('');

    const location = useLocation();

    const findOpenKeys = (routes, pathname) => {
        for (const route of routes) {
            if (route.children) {
                const childRoute = route.children.find(child => child.label?.props?.to === pathname);
                if (childRoute) {
                    return [route.key];
                } else {
                    const parentKey = findOpenKeys(route.children, pathname);
                    if (parentKey) return parentKey;
                }
            }
        }
        return null;
    };

    useEffect(() => {
        if (filteredRoutes?.length > 0) {
            const currentRoute = filteredRoutes.find(route => route.label?.props?.to === location.pathname);

            if (currentRoute) {
                setSelectedKey(currentRoute.key);
            } else {
                const openKey = findOpenKeys(filteredRoutes, location.pathname);
                if (openKey) {
                    setOpenKeys(openKey);
                    const childRoute = filteredRoutes.flatMap(route => route.children || [])
                        .find(child => child.label?.props?.to === location.pathname);
                    if (childRoute) {
                        setSelectedKey(childRoute.key);
                    }
                }
            }
        }
    }, [location, filteredRoutes]);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    };

    return (
        <Sider
            className='rounded-2'
            breakpoint='lg'
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={drawerWidth}
            onBreakpoint={(broken) => setCollapsed(broken)}
        >
            <Flex align='center' justify='center' gap={16} className='padding-1'>
                <Image src={logo} preview={false} />
                {!collapsed && <Title type='danger' level={3}>Fliesen</Title>}
            </Flex>
            <Menu
                mode='inline'
                theme='light'
                className='rounded-2 padding-top-2 sidebar-menu'
                selectedKeys={[selectedKey]}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                // items={filteredRoutes?.map(i => {
                //     const timeTrackingMenu = filteredRoutes.find(route => route.menu === menuName.timeTracking);
                //     const vacationManagement = filteredRoutes.find(route => route.menu === menuName.vacationManagement);

                //     if (Object.keys(timeTrackingMenu ?? {}).length === 0) {
                //         return {
                //             ...i,
                //             label: listing?.language === 'en' ? i.label : i.germanLabel,
                //             ...(i?.children) && {
                //                 children: i?.children?.map(x => {
                //                     return {
                //                         ...x,
                //                         label: listing?.language === 'en' ? x.label : x.germanLabel
                //                     }
                //                 }).filter((item) => item.menu !== menuName.workHistory)
                //             }
                //         }
                //     }

                //     if (Object.keys(vacationManagement ?? {}).length === 0) {
                //         return {
                //             ...i,
                //             label: listing?.language === 'en' ? i.label : i.germanLabel,
                //             ...(i?.children) && {
                //                 children: i?.children?.map(x => {
                //                     return {
                //                         ...x,
                //                         label: listing?.language === 'en' ? x.label : x.germanLabel
                //                     }
                //                 }).filter((item) => item.menu !== menuName.addVacation)
                //             }
                //         }
                //     }
                //     return {
                //         ...i,
                //         label: listing?.language === 'en' ? i.label : i.germanLabel,
                //         ...(i?.children) && {
                //             children: i?.children?.map(x => {
                //                 return {
                //                     ...x,
                //                     label: listing?.language === 'en' ? x.label : x.germanLabel
                //                 }
                //             })
                //         }
                //     }
                // })}
                items={filteredRoutes?.map(i => {
                    const timeTrackingMenu = filteredRoutes.find(route => route.menu === menuName.timeTracking);
                    const vacationManagement = filteredRoutes.find(route => route.menu === menuName.vacationManagement);

                    // Check if both timeTrackingMenu and vacationManagement are empty
                    if (Object.keys(timeTrackingMenu ?? {}).length === 0 && Object.keys(vacationManagement ?? {}).length === 0) {
                        return {
                            ...i,
                            label: listing?.language === 'en' ? i.label : i.germanLabel,
                            ...(i?.children) && {
                                children: i?.children?.map(x => {
                                    return {
                                        ...x,
                                        label: listing?.language === 'en' ? x.label : x.germanLabel
                                    }
                                }).filter((item) => item.menu !== menuName.workHistory && item.menu !== menuName.addVacation)
                            }
                        }
                    }

                    // If only timeTrackingMenu is empty
                    if (Object.keys(timeTrackingMenu ?? {}).length === 0) {
                        return {
                            ...i,
                            label: listing?.language === 'en' ? i.label : i.germanLabel,
                            ...(i?.children) && {
                                children: i?.children?.map(x => {
                                    return {
                                        ...x,
                                        label: listing?.language === 'en' ? x.label : x.germanLabel
                                    }
                                }).filter((item) => item.menu !== menuName.workHistory)
                            }
                        }
                    }

                    // If only vacationManagement is empty
                    if (Object.keys(vacationManagement ?? {}).length === 0) {
                        return {
                            ...i,
                            label: listing?.language === 'en' ? i.label : i.germanLabel,
                            ...(i?.children) && {
                                children: i?.children?.map(x => {
                                    return {
                                        ...x,
                                        label: listing?.language === 'en' ? x.label : x.germanLabel
                                    }
                                }).filter((item) => item.menu !== menuName.addVacation)
                            }
                        }
                    }

                    // Default case when both are not empty
                    return {
                        ...i,
                        label: listing?.language === 'en' ? i.label : i.germanLabel,
                        ...(i?.children) && {
                            children: i?.children?.map(x => {
                                return {
                                    ...x,
                                    label: listing?.language === 'en' ? x.label : x.germanLabel
                                }
                            })
                        }
                    }
                })}

            />
        </Sider>
    );
};

export default SidebarComponent;
