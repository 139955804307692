import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

import logo from '../../assets/images/logo.png';
import user from '../../assets/images/userpdf.png';
import sunIcon from '../../assets/images/sunpdfIcon.png';
import noteIcon from '../../assets/images/notepdfIcon.png';
import diaryIcon from '../../assets/images/diaryIcon.png';
import galleryIocn from '../../assets/images/galleryIocn.jpg';
import dayjs from 'dayjs';
import NunitoRegular from '../../assets/fonts/Nunito/Nunito-Regular.ttf';
import NunitoBold from '../../assets/fonts/Nunito/Nunito-Bold.ttf';

import { useEffect, useState } from 'react';

Font.register({
    family: 'Nunito',
    fonts: [
        {
            src: NunitoRegular,
            fontWeight: 'normal',
        },
        {
            src: NunitoBold,
            fontWeight: 'bold',
        },
    ],
});

// Define styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Nunito', 
        padding: 20,
        fontSize: 12,
        position: 'relative',
    },
    header: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    bold: {
        fontWeight: 'bold',
    },
    normal: {
        fontWeight: 'normal',
    },
    section: {
        border: '1px solid #D9D9D9',
        padding: 8,
        width: '100%'
    },
    part: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    flex: {
        display: 'flex',
        flexDirection: 'row'
    },
    width25: {
        width: '25%'
    },
    secondaryColor: {
        color: '#90909A'
    },
    image: {
        width: 70,
        height: 70,
        margin: 5,
    },
    note: {
        fontSize: 10,
        marginTop: 5,
        fontStyle: 'italic',
    },
});

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

const PageComponent = ({ data }) => {
    return (
        <Document>
            <Page style={styles.page} size="A4">
                <View style={styles.section}>
                    <View style={styles.part}>
                        <Text style={{ width: '50%' }}>
                            <Text style={styles.header}>Bau-Tagesbericht</Text>
                        </Text>
                        <View style={{ width: '50%', display: 'flex', alignItems: 'flex-end', }}>
                            <View style={{ width: 80, height: 80, }}>
                                <Image style={{ width: "100%", height: "100%", borderRadius: 8 }} src={data?.company?.profilePicture ? `${process.env.REACT_APP_API_URI}/company/${data?.company?.profilePicture}` : logo} />
                            </View>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <View style={{ width: '50%' }}>
                            <Text><Text style={styles.bold}>Nr: </Text>  <Text>01</Text></Text>
                            <Text><Text style={styles.bold}>Datum:</Text>  <Text>{dayjs(data?.date).format('DD.MM.YYYY') || "-"}</Text></Text>
                            <View style={{ display: 'flex', flexDirection: 'column' }}>
                                <Text style={styles.bold}>Bauvorhaben : {data?.project} </Text>
                                <Text style={styles.bold}>Bauteil: {data?.bauvorhaben === "default" ? "-" : data?.bauvorhaben}</Text>
                            </View>
                        </View>
                        <View style={{ width: '50%', textAlign: 'right' }}>
                            <Text style={styles.normal}>{data?.company?.name ?? '-'}</Text>
                            <Text>{data?.company?.email ?? '-'}</Text>
                            <Text>{data?.company?.phone ?? '-'}</Text>
                            <Text>{data?.company?.website ?? '-'}</Text>
                            <Text>{data?.company?.taxNumber ?? '-'}</Text>
                            <Text>{data?.company?.address?.streetNo} {data?.company?.address?.city} {data?.company?.address?.pincode}</Text>
                        </View>
                    </View>
                </View>

                <View style={{ ...styles.section, borderTop: 0 }}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: 30 }}>
                            <Text>Anzahl der beschaftigten Arbeiter : {data?.totalEmployee}</Text>
                            <Text>Arbeitszeit von/bis : {data?.time}</Text>
                        </View>
                    </View>
                </View>
                {/* Workers List */}
                <View style={{ ...styles.section, borderTop: 0 }}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={user} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text style={styles.bold}>Beschäftigen Arbeiter</Text>
                    </View>
                </View>
                {/* Workers List Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    <View style={styles.flex}>
                        <Text style={{ width: '50%', ...styles.secondaryColor, textAlign: 'left' }}>Name</Text>
                        <Text style={{ width: '50%', ...styles.secondaryColor, textAlign: 'center' }}>Gesamtstunden</Text>
                    </View>
                </View>
                {/* Workers Lists */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    {
                        data?.worker?.map((item, index) => {
                            return <View style={styles.flex} key={index}>
                                <Text style={{ width: '50%', textAlign: 'left' }}>{item.name}</Text>
                                <Text style={{ width: '50%', textAlign: 'center' }}>{item.totalHrs}</Text>
                            </View>

                        })
                    }
                </View>
                {/* Weather */}
                <View style={{ ...styles.section, borderTop: 0 }}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={sunIcon} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text style={styles.bold}>Wetter</Text>
                    </View>
                </View>
                {/* Weather Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    <View style={styles.flex}>
                        <Text style={{ ...styles.width25, ...styles.secondaryColor, textAlign: 'left' }}>Wetter</Text>
                        <Text style={{ ...styles.width25, ...styles.secondaryColor, }}>Temperatur</Text>
                        <Text style={{ width: '50%', ...styles.secondaryColor, }}>Text</Text>
                    </View>
                </View>
                {/* Weather Lists*/}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    {
                        data?.weather?.map((item, index) => {
                            return <View style={styles.flex} key={index}>
                                <Text style={{ ...styles.width25, textAlign: 'left' }}>{item?.weather?.charAt(0)?.toUpperCase() + item?.weather?.slice(1)}</Text>
                                <Text style={{ ...styles.width25 }}>
                                    {item?.temperature}
                                    <Text style={{ fontSize: 8, verticalAlign: 'super' }}>°C</Text>
                                </Text>
                                <Text style={{ width: '50%', }}>{item?.note ?? '-'}</Text>
                            </View>

                        })
                    }
                </View>
                {/* Performance Result */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10 }}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={noteIcon} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text style={styles.bold}>Leistungsergebnisse</Text>
                    </View>
                </View>
                {/* Performance Result Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    <View style={styles.flex}>
                        <Text style={styles.secondaryColor}>Notiz</Text>
                    </View>
                </View>
                {/* Performance Result Lists*/}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    {
                        data?.performance?.map((item, index) => {
                            return <View style={{ display: 'flex' }} key={index}>
                                <Text style={{ padding: '10 0' }}>{item?.note}</Text>
                                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, rowGap: 5 }}>
                                    {
                                        item?.file?.map((i, index) => {
                                            return <View key={index} style={{ width: '19%' }}>
                                                <Image style={{ width: 80, height: 80, borderRadius: 8 }} src={i.base64} />
                                            </View>
                                        })
                                    }
                                </View>
                            </View>
                        })
                    }
                </View>
                {/* Disabilities */}
                <View style={{ ...styles.section, borderTop: 0 }} minPresenceAhead={200}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={noteIcon} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text style={styles.bold}>Behinderungen</Text>
                    </View>
                </View>
                {/* Disabilities Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    <View style={styles.flex}>
                        <Text style={styles.secondaryColor}>Notiz</Text>
                    </View>
                </View>
                {/* Disabilities Lists*/}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    {
                        data?.disabilities?.map((item, index) => {
                            return <View style={{ display: 'flex' }} key={index}>
                                <Text style={{ padding: '10 0' }}>{item.note}</Text>
                                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, rowGap: 5 }}>
                                    {
                                        item?.file?.map((i, index) => {
                                            return <View key={index} style={{ width: '19%' }}> <Image style={{ width: 80, height: 80, borderRadius: 8 }} src={i.base64} /></View>
                                        })
                                    }
                                </View>
                            </View>

                        })
                    }
                </View>

                {/* Performance Changes */}
                <View style={{ ...styles.section, borderTop: 0 }} minPresenceAhead={200}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={noteIcon} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text style={styles.bold}>Leistungsänderungen</Text>
                    </View>
                </View>
                {/* Performance Changes Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    <View style={styles.flex}>
                        <Text style={styles.secondaryColor}>Notiz</Text>
                    </View>
                </View>
                {/* Performance Changes Lists*/}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    {
                        data?.performanceChanges?.map((item, index) => {
                            return <View style={{ display: 'flex' }} key={index}>
                                <Text style={{ padding: '10 0' }}>{item.note}</Text>
                                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, rowGap: 5 }}>
                                    {
                                        item?.file?.map((i, index) => {
                                            return <View key={index} style={{ width: '19%' }}> <Image style={{ width: 80, height: 80, borderRadius: 8 }} src={i.base64} /></View>
                                        })
                                    }
                                </View>
                            </View>

                        })
                    }
                </View>

                {/* Conformation Diary */}
                <View style={{ ...styles.section, borderTop: 0 }} minPresenceAhead={200}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={diaryIcon} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text style={styles.bold}>Bestätigungsprotokoll</Text>
                    </View>
                </View>

                {/* Conformation Diary Signature */}
                <View style={{ ...styles.section, borderBottom: 0, borderTop: 0 }}>
                    <View style={{ ...styles.flex, gap: 16 }}>
                        <Text style={{ width: '50%' }}>Unterschrift der Bauherren / Bauleiter</Text>
                        <Text style={{ width: '50%', textAlign: 'center' }}>Unterschrift des Bauleiters</Text>
                    </View>
                </View>
                {/* Conformation Diary Signature Sign */}
                <View style={{ ...styles.section, borderTop: 0, marginTop: -20 }}>
                    <View style={{ ...styles.flex, gap: 16 }}>
                        <View style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                            {(data?.signature && data?.signature) && <Image src={data?.signature} style={{ width: 200, height: 100 }} />}
                        </View>
                        <View style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                            {(data?.managerSignature && data?.managerSignature) && <Image style={{ width: 120, height: 120 }} src={data?.managerSignature} />}
                        </View>
                    </View>
                </View>
            </Page>

            <Page style={styles.page} size="A4">
                {/* Photos */}
                <View style={{ ...styles.section, borderTop: 0 }}>
                    <View style={{ ...styles.flex, alignItems: 'center', gap: 16 }}>
                        <Text><Image src={galleryIocn} style={{ display: 'inline-block', verticalAlign: 'middle' }} /></Text><Text style={styles.bold}>Fotos</Text>
                    </View>
                </View>

                {/* Performance Result Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                    <View style={{ ...styles.flex, gap: 16 }}>
                        <Text style={styles.bold}>Leistungsergebnisse</Text>
                    </View>
                </View>
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 10, paddingRight: 10 }}>
                    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 20 }} >
                        {
                            data?.performance?.map((item) =>
                                item?.file?.map((file, index) => (
                                    <View
                                        key={index}
                                        style={{
                                            width: '48%',
                                            height: 220,
                                        }}
                                    >
                                        <Image
                                            style={{ width: '100%', height: '100%' }}
                                            src={file.base64}
                                        />
                                    </View>
                                ))
                            )
                        }
                    </View>
                </View>
                {/* Disabilities Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }} minPresenceAhead={200}>
                    <View style={{ ...styles.flex, gap: 16 }}>
                        <Text style={styles.bold}>Behinderungen</Text>
                    </View>
                </View>
                <View style={{ ...styles.section, zIndex: -1, borderTop: 0, padding: 10, paddingLeft: 10, paddingRight: 10 }}>
                    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 20 }} >
                        {
                            data?.disabilities?.map((item) =>
                                item?.file?.map((file, index) => (
                                    <View
                                        key={index}
                                        style={{
                                            width: '48%',
                                            height: 220,
                                        }}
                                    >
                                        <Image
                                            style={{ width: '100%', height: '100%' }}
                                            src={file.base64}
                                        />
                                    </View>
                                ))
                            )
                        }
                    </View>
                </View>

                {/* Performance Changes Title */}
                <View style={{ ...styles.section, borderTop: 0, padding: 10, paddingLeft: 16, paddingRight: 16 }} minPresenceAhead={200}>
                    <View style={{ ...styles.flex }}>
                        <Text style={styles.bold}>Leistungsänderungen</Text>
                    </View>
                </View>
                <View style={{ ...styles.section, zIndex: -1, borderTop: 0, padding: 10, paddingLeft: 10, paddingRight: 10 }} minPresenceAhead={280}>
                    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 20 }}>
                        {
                            data?.performanceChanges?.map((item) =>
                                item?.file?.map((file, index) => (
                                    <View
                                        key={index}
                                        style={{
                                            width: '48%',
                                            height: 220,
                                        }}
                                    >
                                        <Image
                                            style={{ width: '100%', height: '100%' }}
                                            src={file.base64}
                                        />
                                    </View>
                                ))
                            )
                        }
                    </View>
                </View>
            </Page>
        </Document >
    );
}
const preprocessData = async (data) => {
    const processFiles = async (items) => {
        if (!items) return [];
        return Promise.all(
            items.map(async (item) => ({
                ...item,
                file: await Promise.all(
                    item.file.map(async (f) => ({
                        ...f,
                        base64: await getBase64(f.originFileObj),
                    }))
                ),
            }))
        );
    };

    return {
        ...data,
        performance: await processFiles(data.performance),
        disabilities: await processFiles(data.disabilities),
        performanceChanges: await processFiles(data.performanceChanges),
    };
};

// Main Document Component
const ConstructionDiaryPDF = ({ data }) => {
    const [processedData, setProcessedData] = useState(null);

    useEffect(() => {
        const prepareData = async () => {
            const result = await preprocessData(data);
            setProcessedData(result);
        };
        prepareData();
    }, [data]);

    if (!processedData) {
        return <Text>Loading...</Text>; // Handle loading state
    }

    return <PageComponent data={processedData} />;
};

export default ConstructionDiaryPDF;
