import React, { Fragment, useEffect, useState } from 'react';

import { Form, Button, Input, Upload, Col, Row, Select, DatePicker, Typography, Flex, Divider, Switch } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { createVacation, getByIdVacation, getLeaveDateLists, getVacation, getVacationListData, setIsVacationSuccess, setIsVacationUpdating, updateVacation } from './redux/slice';
import { getAllWorkerListing } from '../../redux/slice/global';

import BackIcon from '../../components/icon/BackIcon';
import UploadIcon from '../../components/icon/UploadIcon';

import '../../styles/pages/new-vacation.scss';
import openNotification from '../../components/notification';

import '../../styles/pages/new-vacation.scss';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

dayjs.extend(isBetween);

const AddUpdateNewVacation = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { id } = useParams();
    const { profile, listing, auth } = useSelector(state => state);
    const [modalType, setModalType] = useState(false);
    const [leaveType, setLeaveType] = useState('');
    const [fileList, setFileList] = useState([]);
    const [document, setDocument] = useState({});
    const { t } = useTranslation();
    const [isCreated, setIsCreated] = useState(false);
    const [stammUserListing, setStammUserListing] = useState([]);
    const [leaveDateLists, setLeaveDateLists] = useState([]);
    const [selectDateType, setSelectDateType] = useState('dateRange');
    const [editDate, setEditDate] = useState();
    const [disabledDateType, setDisabledDateType] = useState(false)

    const handleOnFinish = (values) => {
        if (id) {
            dispatch(setIsVacationUpdating(false));
        }
        else {
            setIsCreated(true);
        }

        delete values?.dateType;

        if (selectDateType === 'dateRange') {
            const payload = {
                ...(values?.emergencyContactPerson) && { emergencyContactPerson: values?.emergencyContactPerson },
                leaveReason: values?.leaveReason ?? '',
                leaveType: values?.leaveType,
                isMultipleDate: false,
                leaveDateRange: {
                    startDate: dayjs(values?.date[0]).format('DD.MM.YYYY'),
                    endDate: dayjs(values?.date[1]).format('DD.MM.YYYY')
                },
                organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer,
                user: auth?.decodeToken?._id,
                uploadedFile: document
            }
            dispatch(getVacation(payload));
        }
        else {
            const payload = {
                ...(values?.emergencyContactPerson) && { emergencyContactPerson: values?.emergencyContactPerson },
                leaveReason: values?.leaveReason ?? '',
                leaveType: values?.leaveType,
                isMultipleDate: true,
                leaveDates: values?.date?.map((item) => dayjs(item).format('DD.MM.YYYY')?.toString()),
                organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer,
                user: auth?.decodeToken?._id,
                uploadedFile: document
            }
            dispatch(getVacation(payload));
        }
    }

    useEffect(() => {
        getLeavesDate();
    }, [auth?.decodeToken?.organizer])

    // Get Leaves and Replacement contact person user list
    const getLeavesDate = () => {
        auth?.decodeToken?.organizer && dispatch(getLeaveDateLists(auth?.decodeToken?._id));
        auth?.decodeToken?.organizer && dispatch(getAllWorkerListing(auth?.decodeToken?.organizer));
    }

    useEffect(() => {
        setLeaveDateLists(profile?.leaveDateLists);
    }, [profile?.leaveDateLists])

    useEffect(() => {
        if (listing.listing?.allWorkers?.length > 0) {
            setStammUserListing(listing.listing?.allWorkers)
        }
    }, [listing.listing.allWorkers]);

    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(setIsVacationUpdating(true));
            dispatch(getByIdVacation(id));
        }
    }, [id]);

    // Create and Upadte Vacation
    useEffect(() => {
        if (Object.keys(profile.vacation ?? {}).length > 0) {

            const formData = new FormData();

            formData.append('leaveReason', profile.vacation?.leaveReason)
            profile.vacation?.emergencyContactPerson && formData.append('emergencyContactPerson', profile.vacation?.emergencyContactPerson)
            formData.append('leaveType', profile.vacation.leaveType)
            formData.append('isMultipleDate', profile.vacation.isMultipleDate)
            formData.append('organizer', profile.vacation.organizer)
            formData.append('user', profile.vacation.user)
            Object.keys(profile.vacation?.uploadedFile ?? {}).length !== 0 ? formData.append('uploadedFile', profile.vacation?.uploadedFile) : formData.append('uploadedFile', '')
            Object.keys(profile.vacation?.leaveDateRange ?? {}).length !== 0 && Object.entries(profile.vacation?.leaveDateRange).forEach(([key, value]) => {
                formData.append(`leaveDateRange[${key}]`, value);
            });
            profile.vacation?.leaveDates?.length > 0 && formData.append('leaveDates', JSON.stringify(profile.vacation.leaveDates))

            if (!modalType && isCreated) {
                dispatch(createVacation(formData));
            } else {
                if (profile.isVacationUpdating) {
                    if (profile.vacation?.isMultipleDate) {
                        setSelectDateType('multipleDate');
                        setEditDate({ isMultipleDate: true, leaveDates: profile.vacation?.leaveDates });
                        form.setFieldsValue({ ...profile.vacation, dateType: 'multipleDate', date: profile.vacation?.leaveDates?.map((date) => dayjs(date)) });
                    }
                    else {
                        setSelectDateType('dateRange');
                        setEditDate({ isMultipleDate: false, leaveDateRange: profile?.vacation?.leaveDateRange });
                        form.setFieldsValue({ ...profile.vacation, dateType: 'dateRange', date: [dayjs(profile?.vacation?.leaveDateRange?.startDate), dayjs(profile?.vacation?.leaveDateRange?.endDate)] });
                    }
                    setDisabledDateType(true);
                    profile.vacation?.uploadedFile && setFileList([
                        {
                            uid: '-1',
                            name: profile.vacation?.uploadedFile, // File name
                            status: 'done',
                            url: `${process.env.REACT_APP_API_URI}/vacation/${profile.vacation?.uploadedFile}`, // File URL
                        },
                    ])
                } else {
                    formData.append('id', id);
                    dispatch(updateVacation(formData));
                }
            }
        }
        if (profile.isVacationSuccess) {
            handleClear();
            navigate(-1);
            setModalType(false);
            dispatch(getVacation({}));
            setIsCreated(false);
            setDisabledDateType(false);
            setFileList([]);
            setDocument({});
            dispatch(setIsVacationSuccess(false));
        }
    }, [profile?.isVacationUpdating, profile?.vacation, profile?.isVacationSuccess]);

    const handleClear = () => {
        form.resetFields();
    };

    const handleLeaveChange = (e) => {
        setLeaveType(e);
    }

    const onUploadChange = ({ file, fileList }) => {
        // Keep only the last uploaded file in the list
        if (fileList.length > 1) {
            fileList.splice(0, fileList.length - 1);
        }

        // Update the file list state
        setFileList(fileList);
        setDocument(file?.originFileObj)

        // Manually trigger validation to clear the error if the file is valid
        form.setFields([
            {
                name: 'files',
                errors: [], // Clear any previous errors
            },
        ]);
    };

    // Disabled Date Range
    const disabledDates = leaveDateLists?.map(date => dayjs(date)).filter(date => {
        if (editDate?.isMultipleDate) {
            return !editDate?.leaveDates?.some(specificDate => date.isSame(specificDate, 'day'))
        }
        else {
            return !date.isBetween(editDate?.leaveDateRange?.startDate, editDate?.leaveDateRange?.endDate, null, '[]'); // Exclude the range (inclusive)
        }
    });

    const disabledDate = (current) => {
        const isWeekend = current?.day() === 0 || current?.day() === 6; // 0 for Sunday, 6 for Saturday
        const isPastDate = current && current < dayjs().startOf('day');
        const isDisabledDate = disabledDates?.some(disabledDate => current?.isSame(disabledDate, 'day')); // Check if current is in the array
        return isPastDate || isWeekend || isDisabledDate;
    };

    // Disabled Multiple Date
    const disableMultipleDate = (current) => {
        const isWeekend = current?.day() === 0 || current?.day() === 6;
        const isPastDate = current && current < dayjs().startOf('day');
        const isDisabledDate = disabledDates?.some(disabledDate => current?.isSame(disabledDate, 'day')); // Check if current is in the array
        return isWeekend || isPastDate || isDisabledDate;
    }

    const validateFileType = (file) => {
        const allowedExtensions = [
            'pdf', 'jpg', 'jpeg', 'png', 'gif', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'rar', 'mp4', 'wmv', 'msg'
        ];

        // Extract the file extension
        let fileExtension = file.name.includes('.') ? file.name.split('.').pop() : file.type.split('/')[1];

        // Ensure no '+xml' is included in the extension (for SVG files)
        fileExtension = fileExtension.replace('+xml', '');

        // Check if the file extension is allowed
        const isValidExtension = allowedExtensions.includes(fileExtension.toLowerCase());

        if (!isValidExtension) {
            openNotification({
                type: 'error',
                message: 'Invalid file type!', // Direct error message
            });
            return Upload.LIST_IGNORE; // Ignore the file upload if invalid
        }
        const isValidSize = file.size / 1024 / 1024 <= 5;
        if (!isValidSize) {
            openNotification({ type: 'error', message: t('common_image_upload_error') });
            return Upload.LIST_IGNORE;
        }
        return true;
    }

    const handleOnChangeDateType = (e) => {
        setSelectDateType(e);
        form.setFieldsValue({ date: '' });
    }

    return (
        <Fragment>
            <Flex vertical gap={24} className='new-vacation'>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => navigate(-1, { state: '2' })}
                    />
                </Flex>
                <Flex vertical gap={8} className='bg-white padding-2'>
                    <Flex>
                        <Title level={4} type='success'>{id ? t('organizer_usermanagement_usertype_edit') + ' ' + t('organizer_vacationmanagement_leave') : t('organizer_profilesettings_addnewvaction')}</Title>
                    </Flex>
                    <Divider />
                    <Form
                        form={form}
                        name='vacation'
                        colon={true}
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        initialValues={{ dateType: 'dateRange' }}
                        layout='vertical'
                        onFinish={handleOnFinish}
                    >
                        <Fragment>
                            <Row gutter={18}>
                                {/* Leave Type */}
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='leaveType'
                                        label={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type') + ' ' + t('organizer_common_isrequired'),
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={handleLeaveChange}
                                            options={[
                                                {
                                                    label: t('organizer_common_formal') + ' ' + t('organizer_vacationmanagement_leave'),
                                                    value: 'formal'
                                                },
                                                {
                                                    label: t('organizer_common_sick') + ' ' + t('organizer_vacationmanagement_leave'),
                                                    value: 'sick'
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                {/* Date Selection Type */}
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='dateType'
                                        label={t('organizer_profilesettings_vacations_dateselectiontype')}
                                    >
                                        <Select
                                            showSearch
                                            placeholder={t('organizer_profilesettings_vacations_dateselectiontype')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={handleOnChangeDateType}
                                            disabled={disabledDateType}
                                            options={[
                                                {
                                                    label: 'Date Range',
                                                    value: 'dateRange'
                                                },
                                                {
                                                    label: 'Multiple Date',
                                                    value: 'multipleDate'
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                {/* Date Picker */}
                                <Col Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='date'
                                        label={t('organizer_profilesettings_vacations_leavedate')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('organizer_common_daterangeerror'),
                                            },
                                        ]}
                                    >
                                        {
                                            selectDateType === 'dateRange' &&
                                            <RangePicker
                                                className='w-100'
                                                disabledDate={disabledDate}
                                                format={'DD-MM-YYYY'}
                                            />
                                        }
                                        {
                                            selectDateType === 'multipleDate' &&
                                            <DatePicker
                                                multiple
                                                defaultValue={[dayjs('2024-12-30T00:00:00.000Z')]}
                                                placeholder={t('organizer_common_date')}
                                                maxTagCount='responsive'
                                                size='middle'
                                                format={'DD/MM/YYYY'}
                                                disabledDate={disableMultipleDate}
                                                className='vacation-datepicker'
                                            />
                                        }
                                    </Form.Item>
                                </Col>

                                {/* Replacement contact person */}
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='emergencyContactPerson'
                                        label={t('organizer_profilesettings_vacations_replacementcontactperson')}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder={t('organizer_profilesettings_vacations_replacementcontactperson')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={stammUserListing}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={18}>
                                {/* Leave Reason */}
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='leaveReason'
                                        label={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason')}
                                    >
                                        <TextArea rows={3} placeholder={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason')} autoSize={{ minRows: 3, maxRows: 6 }} />
                                    </Form.Item>
                                </Col>
                                {/* Upload Files */}
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='files'
                                        label={t('organizer_common_uploadedfile')}
                                        valuePropName='files'
                                        getValueFromEvent={(e) => e && e.fileList}
                                    >
                                        <Upload.Dragger
                                            beforeUpload={validateFileType}
                                            multiple={false}
                                            name='files'
                                            customRequest={({ file, onSuccess }) => {
                                                setTimeout(() => {
                                                    onSuccess('ok');
                                                }, 0);
                                            }}
                                            fileList={fileList}
                                            onChange={onUploadChange}
                                        >
                                            <Flex justify='center' align='center' gap={24}>
                                                <UploadIcon size={30} />
                                                <Text className='ant-upload-text' strong>{t('organizer_common_uploadedfilehere')}</Text>
                                            </Flex>
                                        </Upload.Dragger>
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Flex vertical gap={12} className='paddingY-3' justify='end'>
                                        <Text type='secondary'>{t('organizer_common_uploadedfileheredescription')}</Text>

                                        <Text type='secondary'>
                                            {t('organizer_common_uploaddocumentsize')}
                                        </Text>
                                    </Flex>
                                </Col>
                            </Row>
                        </Fragment>
                        <Flex justify='flex-end' gap={10} wrap={true}>
                            <Button danger type='primary' htmlType='button' onClick={() => navigate(-1)}>{t('organizer_common_cancelButton')}</Button>
                            <Button type='primary' htmlType='submit' loading={profile?.loadingVacation}>
                                {leaveType?.toLowerCase() === t('organizer_common_sick')?.toLowerCase() ? t('organizer_common_submitButton') : t('organizer_profilesettings_vacations_requestleave')}
                            </Button>
                        </Flex>
                    </Form>
                </Flex>
            </Flex>
        </Fragment >
    );
};

export default AddUpdateNewVacation;
