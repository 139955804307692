import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { Flex, Row, Col, Collapse, Input, Button, Form, Typography, Divider, Tag, Select, Modal, DatePicker, QRCode, Image, Upload, Avatar } from 'antd';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import CommonPopconfirm from '../../components/custom-popconfirm';
import DataTable from '../../components/data-table/index.jsx';

import { getAllStammdatenProjectListing, getCityListing, getRackNumberListing, getRackSelfNumberListing } from '../../redux/slice/global.js';
import { createEquipment, deleteEquipment, getByIdEquipment, getByIdGetLagerCityRackNumber, getEquipment, getEquipmentListsData, getLagerByCityList, getRackByLagerList, getSelfNumberByLagerList, setIsSuccess, setIsUpdating, updateEquipment } from './redux/slice.js';
import ExpandIcon from '../../components/icon/ExpandIcon';

import SearchIcon from '../../components/icon/SearchIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';
import EditIcon from '../../components/icon/EditIcon';
import CloseIcon from '../../components/icon/CloseIcon';
import UploadIcon from '../../components/icon/UploadIcon.jsx';
import BlackCloseIcon from '../../components/icon/BlackCloseIcon.jsx';
import BarcodeIcon from '../../components/icon/BarCodeIcon.jsx';

import { lightPrimary, lightSecondary, black } from '../../utils/theme/config';
import openNotification from '../../components/notification/index.jsx';
import html2canvas from 'html2canvas';

const { Title, Text } = Typography;

const EquipmentManagement = ({ permission }) => {
    const [equipmentList, setEquipmentList] = useState([]);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [filterForm] = Form.useForm();

    const dispatch = useDispatch();
    const { listing, auth, equipment } = useSelector((state) => state);

    const [search, setSearch] = useState('');
    const [equipmentListPageSize, setEquipmentListPageSize] = useState(10);
    const [equipmentListPage, setEquipmentListPage] = useState(1);
    const [equipmentListTotalRecords, setEquipmentListTotalRecords] = useState([]);
    const [open, setOpen] = useState(false);
    const [qrOpenModal, setQROpenModal] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [isProjectOrLager, setIsProjectOrLager] = useState('');
    const [stammProjects, setStammProjects] = useState([]);
    const [rackNumberLists, setRackNumberLists] = useState([]);
    const [rackSelfNumberLists, setRackSelfNumberLists] = useState([]);
    const [cityLists, setCityLists] = useState([]);
    const [isWarehouseDisabled, setWarehouseDisabled] = useState(true);
    const [isRackDisabled, setRackDisabled] = useState(true);
    const [isSelfDisabled, setSelfDisabled] = useState(true);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [filters, setFilters] = useState({});
    const [isCreated, setIsCreated] = useState(false);
    const [editId, setEditId] = useState('');
    const [qrId, setQrId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const qrCodeRef = useRef(null);
    const qrRef = useRef(null);
    const formqrRef = useRef(null);

    const checkPermissionsAndStartScanning = async () => {
        try {
            // Request camera permissions
            await navigator.mediaDevices.getUserMedia({ video: true });
            startScanning(); // Start scanning if permissions are granted
        } catch (err) {
            openNotification({ message: t('organizer_common_camerapermission'), type: 'error' })
        }
    };

    const startScanning = () => {
        const qrCodeRegionId = 'reader';

        if (!qrCodeRef.current) {
            qrCodeRef.current = new Html5Qrcode(qrCodeRegionId);
        }

        qrCodeRef.current
            .start(
                { facingMode: 'environment' }, // Use the back camera
                {
                    fps: 10,
                    qrbox: { width: 'auto', height: 'auto' },
                    supportedScanTypes: [
                        Html5QrcodeSupportedFormats.CODE_128,
                        Html5QrcodeSupportedFormats.EAN_13,
                        Html5QrcodeSupportedFormats.UPC_A,
                    ], // Add supported barcode formats
                },
                (decodedText) => {
                    if (decodedText) {
                        dispatch(getByIdGetLagerCityRackNumber({ id: decodedText, organizer: auth?.decodeToken?.organizer }))
                    }
                    stopScanning();
                },
                (errorMessage) => { }
            )
            .catch((err) => { });
    };

    useEffect(() => {
        if (Object.keys(equipment?.barcodeScanData ?? {}).length > 0) {
            equipment?.barcodeScanData?.city && dispatch(getLagerByCityList({ cityId: equipment?.barcodeScanData?.city, organizerId: auth?.decodeToken?.organizer }));
            equipment?.barcodeScanData?.rackNumber && dispatch(getSelfNumberByLagerList({ rackNumberId: equipment?.barcodeScanData?.rackNumber, organizerId: auth?.decodeToken?.organizer }));
            equipment?.barcodeScanData?.lager && dispatch(getRackByLagerList({ lagerId: equipment?.barcodeScanData?.lager, organizerId: auth?.decodeToken?.organizer }));
            equipment?.barcodeScanData?.city ? setWarehouseDisabled(false) : setWarehouseDisabled(true);
            equipment?.barcodeScanData?.rackNumber ? setSelfDisabled(false) : setSelfDisabled(true);
            equipment?.barcodeScanData?.lager ? setRackDisabled(false) : setRackDisabled(true);
            form.setFieldsValue({ city: equipment?.barcodeScanData.city, lager: equipment?.barcodeScanData.lager, rackNumber: equipment?.barcodeScanData?.rackNumber, rackSelfNumber: equipment?.barcodeScanData?.rackSelfNumber })
        }
    }, [equipment?.barcodeScanData, auth?.decodeToken?.organizer])


    const stopScanning = () => {
        qrCodeRef.current?.stop().then(() => {
            qrCodeRef.current = null;
        });
        setIsModalOpen(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setTimeout(() => checkPermissionsAndStartScanning(), 500); // Delay to ensure modal is rendered
    };

    const handleCloseModal = () => {
        stopScanning();
    };

    useEffect(() => {
        getStammProjectListing();
    }, [auth?.decodeToken?.organizer])

    const getStammProjectListing = () => {
        if (auth?.decodeToken?.organizer) {
            dispatch(getAllStammdatenProjectListing(auth?.decodeToken?.organizer));
            dispatch(getRackNumberListing(auth?.decodeToken?.organizer));
            dispatch(getRackSelfNumberListing(auth?.decodeToken?.organizer));
        }
    }

    // Get Eqipment lists
    const getEquipmentListData = useCallback(() => {
        if (auth?.decodeToken?.organizer) {
            dispatch(getEquipmentListsData({
                sortBy,
                order,
                search,
                filter: {
                    uniqueIdentifier: filters?.uniqueIdentifier || '',
                    rackNumber: filters?.rackNumber || '',
                    rackSelfNumber: filters?.rackSelfNumber || ''
                },
                page: equipmentListPage,
                limit: equipmentListPageSize,
                organizer: auth?.decodeToken?.organizer
            }));
        }
    }, [
        sortBy,
        order,
        search,
        filters,
        equipmentListPage,
        equipmentListPageSize,
        auth?.decodeToken?.organizer
    ]);

    useEffect(() => {
        getEquipmentListData();
    }, [getEquipmentListData]);

    useEffect(() => {
        setEquipmentList(equipment?.equipmentList);
        setEquipmentListTotalRecords(equipment?.totalRecords);
    }, [equipment?.equipmentList, equipment?.totalRecords])

    // Get city lists
    useEffect(() => {
        dispatch(getCityListing());
    }, [])

    useEffect(() => {
        setCityLists(listing.listing?.city)
    }, [listing.listing?.city])

    // Get Stammdaten Project lists
    useEffect(() => {
        setStammProjects(listing.listing?.stammProjects);
    }, [listing.listing?.stammProjects])

    // Get Racknumbers lists
    useEffect(() => {
        setRackNumberLists(listing.listing?.rackNumbers);
    }, [listing.listing?.rackNumbers])

    // Get RackselfNumbers lists
    useEffect(() => {
        setRackSelfNumberLists(listing.listing?.rackSelfNumbers);
    }, [listing.listing?.rackSelfNumbers])

    // Handle Filter finish
    const handleFilterFinish = (values) => {
        setFilters(values);
    }

    const handleFilterClear = () => {
        filterForm.resetFields();
        if (Object.keys(filters).length > 0) {
            setFilters({});
            getEquipmentListData();
        }
    }

    const handleOnFinish = (values) => {
        if (editId) {
            dispatch(setIsUpdating(false));
        }
        else {
            setIsCreated(true);
        }
        delete values?.files;
        delete values?.lagerorproject;
        dispatch(getEquipment({
            ...values,
            serialNumber: values.serialNumber || '',
            purchaseDate: dayjs(values?.purchaseDate).format('YYYY/MM/DD'),
            user: auth?.decodeToken?._id,
            organizer: auth?.decodeToken?.organizer,
            photo: fileList
        }))
    }

    // Create and Update Equipment
    useEffect(() => {
        const handleFormData = async () => {
            if (Object.keys(equipment?.equipment ?? {}).length > 0) {
                const formData = new FormData();

                formData.append('equipmentName', equipment?.equipment?.equipmentName);
                formData.append('serialNumber', equipment?.equipment?.serialNumber);
                formData.append('purchaseDate', equipment?.equipment?.purchaseDate);
                formData.append('status', equipment?.equipment?.status);
                formData.append('user', equipment?.equipment?.user);
                formData.append('organizer', equipment?.equipment?.organizer);

                if (equipment?.equipment?.project) {
                    formData.append('project', equipment?.equipment?.project);
                } else {
                    formData.append('lager', equipment?.equipment?.lager);
                    formData.append('city', equipment?.equipment?.city);
                    formData.append('rackNumber', equipment?.equipment?.rackNumber);
                    formData.append('rackSelfNumber', equipment?.equipment?.rackSelfNumber);
                }

                for (const item of equipment?.equipment?.photo ?? []) {
                    if (item?.originFileObj) {
                        formData.append('photo', item.originFileObj);
                    } else if (item?.url) {
                        try {
                            const response = await fetch(item.url);
                            const blob = await response.blob();

                            // Extract the file name and ensure it includes an extension
                            const originalName = item?.name || 'file-from-url';
                            const fileExtension = originalName?.includes('.')
                                ? originalName?.split('.')?.pop() // Use the extension from the original name
                                : blob?.type?.split('/')[1]?.replace('+xml', ''); // Remove '+xml' if present

                            const fileName = originalName?.includes('.')
                                ? originalName // Keep original name if it already has an extension
                                : `${originalName}.${fileExtension}`; // Append the correct extension

                            // Append the file with the correct name to FormData
                            formData.append('photo', new File([blob], fileName, { type: blob.type }));

                        } catch (error) { }
                    }
                }

                if (isCreated) {
                    dispatch(createEquipment({ formData, organizer: equipment?.equipment?.organizer }));
                } else {
                    if (equipment?.isUpdating) {
                        setFileList(
                            equipment?.equipment?.photo?.map((item) => ({
                                uid: item?.uid || item,
                                name: item?.name || `Uploaded File`,
                                status: 'done',
                                url: item?.url || `${process.env.REACT_APP_API_URI}/equipment/${item}`,
                            }))
                        );

                        if (equipment?.equipment?.project) {
                            setIsProjectOrLager('project');
                            form.setFieldsValue({
                                ...equipment?.equipment,
                                purchaseDate: dayjs(equipment?.equipment?.purchaseDate),
                                lagerorproject: 'project',
                            });
                        } else {
                            setIsProjectOrLager('lager');
                            dispatch(getLagerByCityList({ cityId: equipment?.equipment?.city, organizerId: auth?.decodeToken?.organizer }));
                            dispatch(getSelfNumberByLagerList({ rackNumberId: equipment?.equipment?.rackNumber, organizerId: auth?.decodeToken?.organizer }));
                            dispatch(getRackByLagerList({ lagerId: equipment?.equipment?.lager, organizerId: auth?.decodeToken?.organizer }));
                            setWarehouseDisabled(false);
                            setRackDisabled(false);
                            setSelfDisabled(false);
                            form.setFieldsValue({
                                ...equipment?.equipment,
                                purchaseDate: dayjs(equipment?.equipment?.purchaseDate),
                                lagerorproject: 'lager',
                            });
                        }
                    } else {
                        formData.append('id', editId);
                        editId && dispatch(updateEquipment({ formData, organizer: equipment?.equipment?.organizer }));
                    }
                }
            }

            if (equipment?.isSuccess) {
                handleClear();
                setOpen(false);
                setIsCreated(false);
                setFileList([]);
                setEditId('');
                setIsProjectOrLager('');
                dispatch(setIsSuccess(false));
            }
        };

        handleFormData();
    }, [equipment?.isUpdating, equipment?.equipment, equipment?.isSuccess]);

    // Filter item form
    const filterItems = [
        {
            key: '1',
            label: <Title level={4} type='success' className='text-primary'>{t('organizer_common_filter')}</Title>,
            children: <Fragment>
                <Form
                    form={filterForm}
                    className='user-filter'
                    name='filters'
                    colon={false}
                    layout='vertical'
                    onFinish={handleFilterFinish}
                >
                    <Row gutter={18}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='uniqueIdentifier'
                                label={t('organizer_common_UUID')}
                            >
                                <Input placeholder={t('organizer_common_UUID')} />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='rackNumber'
                                label={t('organizer_common_racknumber')}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder={t('organizer_common_racknumber')}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(e) => { setIsProjectOrLager(e); }}
                                    options={rackNumberLists}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='rackSelfNumber'
                                label={t('organizer_common_rackSelfNumber')}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder={t('organizer_common_racknumber')}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(e) => { setIsProjectOrLager(e); }}
                                    options={rackSelfNumberLists}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' htmlType='button' onClick={handleFilterClear}>{t('organizer_common_clearbutton')}</Button>
                        <Button type='primary' htmlType='submit'>{t('organizer_common_applybutton')}</Button>
                    </Flex>
                </Form>
            </Fragment >,
        }
    ];

    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    // Table Column Sort Funcation
    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    const equipmentListColumns = [
        {
            title: t('organizer_common_photo'),
            dataIndex: 'photo',
            key: 'photo',
            width: '100px',
            render: (_, record) => {
                return <Avatar shape='square' src={`${process.env.REACT_APP_API_URI}/equipment/${record?.photo[0]}`} size={'large'} />
            }
        },
        {
            title: t('organizer_common_UUID'),
            dataIndex: 'uniqueIdentifier',
            key: 'uniqueIdentifier',
            width: '100px',
        },
        {
            title: t('organizer_equipmentmanagement_equipmentname'),
            dataIndex: 'equipmentName',
            key: 'equipmentName',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'equipmentName'),
        },
        {
            title: t('organizer_equipmentmanagement_equipmentsn'),
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'serialNumber'),
            render: (_, record) => record?.serialNumber || '-'
        },
        {
            title: t('organizer_common_projectname'),
            dataIndex: 'project',
            key: 'project',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'project'),
            render: (_, record) => record?.project || '-'
        },
        {
            title: t('organizer_common_projectSKU'),
            dataIndex: 'equipmentSKU',
            key: 'equipmentSKU',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'equipmentSKU'),
            render: (_, record) => record?.equipmentSKU || '-'
        },
        {
            title: t('organizer_common_warehouseArea'),
            dataIndex: 'city',
            key: 'city',
            width: '200px',
            render: (_, record) => record?.city || '-',
            sorter: (_, __, order) => handleSortFunction(order, 'city'),
        },
        {
            title: t('organizer_common_lagername'),
            dataIndex: 'lager',
            key: 'lager',
            width: '200px',
            render: (_, record) => record?.lager || '-',
            sorter: (_, __, order) => handleSortFunction(order, 'lager'),
        },
        {
            title: t('organizer_equipmentmanagement_purchasedate'),
            dataIndex: 'purchaseDate',
            key: 'purchaseDate',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'purchaseDate'),
        },
        {
            title: t('organizer_equipmentmanagement_qrcode'),
            dataIndex: 'QRCode',
            key: 'QRCode',
            width: '200px',
            render: (_, record) => {
                return <QRCode className='cursor-pointer' value={record?._id} onClick={() => { setQROpenModal(true); setQrId(record?._id) }} type='svg' size={60} bordered={false} />
            }
        },
        {
            title: t('organizer_common_status'),
            dataIndex: 'status',
            key: 'status',
            width: '150px',
            sorter: (_, __, order) => handleSortFunction(order, 'status'),
            render: (_, record) => {
                return (
                    <Fragment>
                        {
                            record?.status?.toLowerCase() === 'active' ?
                                <Tag color={lightPrimary} className='text-center'>{t('organizer_common_active')}</Tag> :
                                <Tag color={lightSecondary} className='text-center'>{t('organizer_common_inactive')}</Tag>
                        }
                    </Fragment>
                );
            }
        },
    ];

    const handleConfirm = (deleteId) => {
        dispatch(deleteEquipment({
            id: deleteId,
            sortBy: '',
            order: '',
            search: '',
            filter: {
                uniqueIdentifier: filters?.uniqueIdentifier || '',
                rackNumber: filters?.rackNumber || '',
                rackSelfNumber: filters?.rackSelfNumber || ''
            },
            page: equipmentListPage,
            limit: equipmentListPageSize,
            organizer: auth?.decodeToken?.organizer && auth?.decodeToken?.organizer
        }))
    }

    const handleEditEquipment = (editId) => {
        setOpen(true);
        dispatch(getByIdEquipment(editId));
        dispatch(setIsUpdating(true));
        setEditId(editId);
    }

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        equipmentListColumns.push({
            title: <Flex justify='center'><Text>{t('organizer_common_action')}</Text></Flex>,
            key: 'action',
            width: '50px',
            render: (_, record) => (
                <Flex justify='center'>
                    {
                        permission?.canUpdate &&
                        <Button type='text'
                            className='padding-1'
                            onClick={() => handleEditEquipment(record?._id)}
                        >
                            <EditIcon role='button' />
                        </Button>
                    }
                    {
                        permission?.canDelete &&
                        <Fragment>
                            <CommonPopconfirm
                                title={t('organizer_common_deleteTitle') + ' ' + t('organizer_equipmentmanagement_equipment')}
                                description={t('organizer_common_confirmationmessage') + ' ' + t('organizer_equipmentmanagement_equipment')}
                                icon={null}
                                onConfirm={() => handleConfirm(record?._id)}
                                cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                            >
                                <Button type='text' className='padding-1'><DeleteIcon role='button' /></Button>
                            </CommonPopconfirm>

                        </Fragment>
                    }
                </Flex >
            ),
        })
    }

    const handleEquipmentListOnChange = (page, pageSize) => {
        setEquipmentListPageSize(pageSize);
        setEquipmentListPage(page);
    };

    const handleClear = () => {
        form.resetFields();
        setIsProjectOrLager('');
        setFileList([]);
        setEditId('');
        setOpen(false);
    };

    const onUploadChange = (info) => {
        setFileList(info.fileList);

        if (fileList?.length !== 0) {
            form.setFields([
                {
                    name: 'files',
                    errors: [], // Clear any previous errors
                },
            ]);
        }
    };

    const handleDelete = (uid) => {
        setFileList((prevList) => prevList.filter((file) => file.uid !== uid));
    };

    const handleFieldChange = (type, value) => {
        if (type === 'city') {
            dispatch(getLagerByCityList({ cityId: value, organizerId: auth?.decodeToken?.organizer }));
            form.setFieldsValue({ lager: undefined });
            setWarehouseDisabled(false);
        }

        if (type === 'lager') {
            setRackDisabled(false);
            form.setFieldsValue({ rackNumber: undefined });
            dispatch(getRackByLagerList({ lagerId: value, organizerId: auth?.decodeToken?.organizer }));
        }

        if (type === 'rackNumber') {
            setSelfDisabled(false);
            form.setFieldsValue({ rackSelfNumber: undefined });
            dispatch(getSelfNumberByLagerList({ rackNumberId: value, organizerId: auth?.decodeToken?.organizer }));
        }
    }

    const handleOnModalCancel = () => {
        setOpen(false);
        form.resetFields();
        setIsProjectOrLager('');
        setEditId('');
        setFileList([]);
    }

    const handleProjectOrLagerChange = (e) => {
        setIsProjectOrLager(e);
        setWarehouseDisabled(true);
        setSelfDisabled(true);
        setRackDisabled(true);
        form.setFieldsValue({ lager: undefined, city: undefined, project: undefined, rackNumber: undefined, rackSelfNumber: undefined });
    }

    const validateFileType = (file) => {
        const isValidSize = file.size / 1024 / 1024 <= 5;
        if (!isValidSize) {
            openNotification({ type: 'error', message: t('common_image_upload_error') });
            return Upload.LIST_IGNORE;
        }
        return true;
    }

    const downloadQRCode = (type) => {
        try {

            if (type === 'form') {
                // Use html2canvas to capture the QR code
                html2canvas(formqrRef.current).then((canvas) => {
                    const dataUrl = canvas.toDataURL(); // Get the data URL of the image
                    const link = document.createElement('a'); // Create a temporary link element
                    link.href = dataUrl; // Set the href to the data URL
                    link.download = 'Qrcode.png'; // Set the download filename
                    link.click(); // Trigger the download
                }).catch((error) => {
                    // Handle error if html2canvas fails to render the QR code
                    openNotification({ message: t('organizer_common_downloaderror'), type: 'error' });
                });
            }

            if (type === 'modal') {
                // Use html2canvas to capture the QR code
                html2canvas(qrRef.current).then((canvas) => {
                    const dataUrl = canvas.toDataURL(); // Get the data URL of the image
                    const link = document.createElement('a'); // Create a temporary link element
                    link.href = dataUrl; // Set the href to the data URL
                    link.download = 'Qrcode.png'; // Set the download filename
                    link.click(); // Trigger the download
                }).catch((error) => {
                    // Handle error if html2canvas fails to render the QR code
                    openNotification({ message: t('organizer_common_downloaderror'), type: 'error' });
                });
            }
        } catch (error) {
            openNotification({ message: t('organizer_common_downloaderror'), type: 'error' });
        }
    };

    return (
        <Flex vertical gap={24} className='change-tag'>
            <Row
                justify='space-between'
                align='middle'
                className='bg-white padding-1 rounded-1'
                gutter={[16, 16]}
            >
                <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Row align='middle' gutter={[16, 16]}>
                        <Col flex='none'>
                            <Title level={4} type='success'>{t('organizer_equipmentmanagement_equipment')} {t('organizer_common_list')}</Title>
                        </Col>
                        <Col flex='auto' xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Input
                                value={search}
                                placeholder={t('organizer_common_search')}
                                onChange={(e) => setSearch(e.target.value)}
                                prefix={<SearchIcon color='#000' size={20} />}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                    <Flex justify='end' gap={16}>
                        {
                            (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                            <Button onClick={() => { form.resetFields(); setOpen(true); }}>{t('organizer_common_add')} {t('organizer_equipmentmanagement_equipment')}</Button>
                        }
                        <Modal
                            width={700}
                            open={open}
                            closeIcon={<CloseIcon color={black} role='button' />}
                            title={<Fragment><Title level={4} type='success'>{editId ? t('organizer_usermanagement_usertype_edit') : t('organizer_common_add')} {t('organizer_common_new')} {t('organizer_equipmentmanagement_equipment')}</Title><Divider /></Fragment>}
                            footer={null}
                            onCancel={handleOnModalCancel}
                            modalRender={(dom) => (
                                <Form
                                    layout='vertical'
                                    form={form}
                                    name='lagar-form'
                                    requiredMark={(label, isRule) => {
                                        return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                                    }}
                                    onFinish={(values) => handleOnFinish(values)}
                                >
                                    {dom}
                                </Form>
                            )}
                        >
                            <Row gutter={16}>
                                {
                                    !editId &&
                                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className='padding-bottom-2'>
                                        <Text type='danger'>*Equipment QR Code will be generated only after saving the equipment.</Text>
                                    </Col>
                                }
                                {
                                    isProjectOrLager?.toLowerCase() === 'lager' &&
                                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className='padding-bottom-2'>
                                        <Flex justify='flex-end'>
                                            <BarcodeIcon onClick={handleOpenModal} size={30} />
                                        </Flex>
                                    </Col>
                                }
                                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='equipmentName'
                                        label={t('organizer_equipmentmanagement_equipmentname')}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('organizer_equipmentmanagement_equipmentname')} ${t('organizer_common_isrequired')}`,
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t('organizer_equipmentmanagement_equipmentname')} />
                                    </Form.Item>
                                </Col>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='lagerorproject'
                                        label={t('organizer_common_lagerproject')}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('organizer_common_lagerproject')} ${t('organizer_common_isrequired')}`,
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder={t('organizer_common_lagerproject')}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={handleProjectOrLagerChange}
                                            options={[
                                                {
                                                    label: 'Lager',
                                                    value: 'lager'
                                                },
                                                {
                                                    label: 'Project',
                                                    value: 'project'
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    isProjectOrLager?.toLowerCase() === 'project' &&
                                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='project'
                                            label={t('organizer_common_project')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('organizer_common_project') + ' ' + t('organizer_common_isrequired'),
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                className='w-100'
                                                placeholder={t('organizer_common_project')}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={stammProjects}
                                            />
                                        </Form.Item>
                                    </Col>
                                }

                                {
                                    isProjectOrLager?.toLowerCase() === 'lager' &&
                                    <Fragment>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='city'
                                                label={t('organizer_common_city')}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('organizer_common_city') + ' ' + t('organizer_common_isrequired'),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder={t('organizer_common_city')}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    onChange={(value) => handleFieldChange('city', value)}
                                                    options={cityLists}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='lager'
                                                label={t('organizer_common_lagername')}
                                                rules={[
                                                    {
                                                        required: !isWarehouseDisabled,
                                                        message: t('organizer_common_lagername') + ' ' + t('organizer_common_isrequired'),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder={t('organizer_common_lagername')}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    onChange={(value) => handleFieldChange('lager', value)}
                                                    options={equipment?.lagerLists}
                                                    disabled={isWarehouseDisabled}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='rackNumber'
                                                label={t('organizer_common_racknumber')}
                                                rules={[
                                                    {
                                                        required: !isRackDisabled,
                                                        message: t('organizer_common_racknumbererror'),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder={t('organizer_common_racknumber')}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={equipment?.rackeNumbersLists}
                                                    disabled={isRackDisabled}
                                                    onChange={(value) => handleFieldChange('rackNumber', value)}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='rackSelfNumber'
                                                label={t('organizer_common_selfnumber')}
                                                rules={[
                                                    {
                                                        required: !isSelfDisabled,
                                                        message: t('organizer_common_selfnumber') + ' ' + t('organizer_common_isrequired')
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder={t('organizer_common_selfnumber')}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    disabled={isSelfDisabled}
                                                    options={equipment?.selfNumberLists}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Fragment>
                                }
                                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='serialNumber'
                                        label={t('organizer_equipmentmanagement_equipmentserialno')}
                                    >
                                        <Input placeholder={t('organizer_equipmentmanagement_equipmentserialno')} />
                                    </Form.Item>
                                </Col>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='purchaseDate'
                                        label={t('organizer_equipmentmanagement_purchasedate')}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('organizer_equipmentmanagement_purchasedate')} ${t('organizer_common_isrequired')}`,
                                            },
                                        ]}
                                    >
                                        <DatePicker format={'DD/MM/YYYY'} className='w-100' />
                                    </Form.Item>
                                </Col>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Row>
                                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item
                                                name='status'
                                                label={t('organizer_common_status')}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `${t('organizer_common_status')} ${t('organizer_common_isrequired')}`,
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder={t('organizer_common_status')}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={[
                                                        {
                                                            label: t('organizer_common_active'),
                                                            value: 'active'
                                                        },
                                                        {
                                                            label: t('organizer_common_inactive'),
                                                            value: 'inactive'
                                                        }
                                                    ]}
                                                    tagRender={(props) => (
                                                        <span
                                                            style={{
                                                                display: 'inline-block',
                                                                padding: '5px 10px',
                                                                borderRadius: '4px',
                                                                backgroundColor: '#f0f0f0',
                                                                margin: '2px',
                                                                color: '#333',
                                                            }}
                                                        >
                                                            {props.label}
                                                            {props.closable && (
                                                                <span
                                                                    onClick={props.onClose}
                                                                    style={{
                                                                        marginLeft: '5px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    &times;
                                                                </span>
                                                            )}
                                                        </span>
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                {
                                    editId &&
                                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <Flex justify='flex-end' className='paddingX-3'>
                                            <div ref={formqrRef}>
                                                <QRCode value={editId} type='svg' size={150} bordered={false} />
                                            </div>
                                        </Flex>
                                    </Col>
                                }
                                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className='padding-top-2'>
                                    <Form.Item
                                        name='files'
                                        valuePropName='files'
                                        getValueFromEvent={(e) => e && e.fileList}
                                    >
                                        <Upload.Dragger multiple={true} name='files'
                                            beforeUpload={validateFileType}
                                            accept='.png,.jpg,.svg,.jpeg,.webp,.heic,.heif'
                                            showUploadList={false} fileList={fileList} onChange={onUploadChange} >
                                            <p className='ant-upload-drag-icon'>
                                                <UploadIcon size={30} />
                                            </p>
                                            <Text className='ant-upload-text' strong>{t('organizer_common_uploadedfilehere')}</Text>
                                        </Upload.Dragger>

                                        <Flex vertical gap={12} className='paddingY-1'>
                                            <Text type='secondary'>{t('organizer_common_uploadedphotodescription')}</Text>

                                            <Text type='secondary'>
                                                {t('organizer_common_uploaddocumentsize')}
                                            </Text>
                                        </Flex>
                                    </Form.Item>
                                </Col>
                                {fileList.length > 0 && (
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {fileList.map((file) => (
                                            <div key={file?.uid || file} style={{ position: 'relative', margin: '8px' }}>
                                                {file?.originFileObj ? (
                                                    <>
                                                        <Image
                                                            src={URL.createObjectURL(file.originFileObj)}
                                                            alt={file.name}
                                                            style={{
                                                                width: '100px',
                                                                height: '100px',
                                                                objectFit: 'cover',
                                                                borderRadius: '0.5rem',
                                                            }}
                                                        />
                                                        <Button
                                                            type='link'
                                                            icon={<BlackCloseIcon role='button' />}
                                                            onClick={() => handleDelete(file.uid)}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-14px',
                                                                right: '-9px',
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Image
                                                            src={file.url}
                                                            alt={file.name}
                                                            style={{
                                                                width: '100px',
                                                                height: '100px',
                                                                objectFit: 'cover',
                                                                borderRadius: '0.5rem',
                                                            }}
                                                        />
                                                        <Button
                                                            type='link'
                                                            icon={<BlackCloseIcon role='button' />}
                                                            onClick={() => handleDelete(file.uid)}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-14px',
                                                                right: '-9px',
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Row>
                            <Flex justify='flex-end' gap={8}>
                                {
                                    editId &&
                                    <Button
                                        type='primary'
                                        onClick={() => downloadQRCode('form')}
                                        style={{ background: black }}
                                    >
                                        Print QR
                                    </Button>
                                }
                                <Button type='primary' danger htmlType='button' onClick={handleClear}>{t('organizer_common_cancelButton')}</Button>
                                <Button type='primary' htmlType='submit' loading={equipment?.loading}>{t('organizer_common_submitButton')}</Button>
                            </Flex>
                        </Modal>
                        <Modal
                            open={qrOpenModal}
                            closeIcon={<CloseIcon color={black} role='button' />}
                            title={<Fragment><Title level={5} type='success'>{t('organizer_equipmentmanagement_scanqrcode')}</Title><Divider /></Fragment>}
                            footer={null}
                            onCancel={() => { setQROpenModal(false); setQrId('') }}
                        >
                            <Flex id='myqrcode' justify='center' align='center' className='padding-3'>
                                <div ref={qrRef}>
                                    <QRCode value={qrId} type='svg' size={150} bordered={false} />
                                </div>
                            </Flex>
                            <Flex justify='flex-end' gap={8} className='padding-top-3'>
                                <Button
                                    type='primary'
                                    onClick={() => downloadQRCode('modal')}
                                    style={{ background: black }}
                                >
                                    Print QR
                                </Button>
                                <Button type='primary' danger htmlType='button' onClick={() => { setQROpenModal(false); setQrId('') }}>{t('organizer_common_cancelButton')}</Button>
                            </Flex>
                        </Modal>
                    </Flex>
                </Col>
            </Row>
            <Flex className='filter-margin-top'>
                <Collapse
                    items={filterItems}
                    defaultActiveKey={['1']}
                    className='w-100'
                    bordered={false}
                    expandIcon={customExpandIcon}
                    expandIconPosition={'end'}
                />
            </Flex>

            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <DataTable
                        columns={equipmentListColumns}
                        pageSize={equipmentListPageSize}
                        currentPage={equipmentListPage}
                        total={equipmentListTotalRecords}
                        scrollX={1000}
                        showSorterTooltip={false}
                        isPagination={true}
                        handleOnChange={handleEquipmentListOnChange}
                        data={equipmentList}
                        rowKey={(record) => record?._id}
                    />
                </Col>
            </Row>

            {/* Barcode Scan */}
            <Modal
                width={650}
                title='Barcode Scanner'
                open={isModalOpen}
                onCancel={handleCloseModal}
                footer={null}
            >
                <div id='reader' style={{ width: '100%', height: 'auto' }}></div>
            </Modal>
        </Flex>
    );
};

export default EquipmentManagement;
