import React, { Fragment, useEffect, useState } from 'react';

import { Button, Col, Collapse, Flex, Row, Select, Form, Typography, DatePicker, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { pdf } from '@react-pdf/renderer';

import DataTable from '../../components/data-table';
import CommonPopconfirm from '../../components/custom-popconfirm';
import openNotification from '../../components/notification';
import GenerateVacationPDF from '../vacation-management/vactionPdf';

import { deleteVacation, getVacationLeaves, getVacationListData } from './redux/slice';

import ExpandIcon from '../../components/icon/ExpandIcon';
import EditIcon from '../../components/icon/EditIcon';
import CloseIcon from '../../components/icon/CloseIcon';
import DocumentIcon from '../../components/icon/DocumentIcon';

import { getByIdClient } from '../administrator/redux/slice';

import { lightPrimary, lightSecondary, lightYellow } from '../../utils/theme/config';

import '../../styles/pages/new-vacation.scss';
import { getApiData } from '../vacation-management/redux/slice';

const { Title, Text } = Typography;
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Berlin');

const AddNewVacation = ({ permission }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { profile, client, auth } = useSelector(state => state);
    const [vacations, setVacations] = useState([]);
    const [leaves, setLeaves] = useState({});
    const [filetrData, setFilterData] = useState({});
    const [holiDay, setHoliDay] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);

    useEffect(() => {
        getVacationList();
    }, [auth?.decodeToken?.organizer, filetrData]);

    // Get Vacation List
    const getVacationList = () => {
        if (auth?.decodeToken?.organizer) {
            dispatch(getVacationListData({
                filters: {
                    leaveType: filetrData?.leave ? filetrData?.leave : '',
                    startDate: filetrData?.startDate ? dayjs(filetrData?.startDate).format('DD.MM.YYYY') : '',
                    endDate: filetrData?.endDate ? dayjs(filetrData?.endDate).format('DD.MM.YYYY') : ''
                },
                user: auth?.decodeToken?._id,
                organizer: auth?.decodeToken?.organizer
            }))
        }
    };

    useEffect(() => {
        getLeaves();
    }, [auth?.decodeToken])

    useEffect(() => {
        auth?.decodeToken?._id && dispatch(getByIdClient(auth?.decodeToken?._id))
    }, [auth?.decodeToken?._id])

    useEffect(() => {
        if (client?.client?.holiday) {
            setHoliDay(client?.client?.holiday)
        }
    }, [client?.client])

    const getLeaves = () => {
        if (auth?.decodeToken?._id)
            dispatch(getVacationLeaves({ userId: auth?.decodeToken?._id, year: dayjs().format('YYYY') }));
    }

    useEffect(() => {
        setLeaves(profile?.leaves);
    }, [profile.leaves])

    // Set Vacation List
    useEffect(() => {
        setVacations(profile.vacatonLists);
    }, [profile.vacatonLists, filetrData]);

    // Leave Collapse Items
    const items = [
        {
            key: '1',
            label: <Title level={4} className='text-primary'>{t('organizer_common_total')} {t('organizer_common_leaves')}</Title>,
            children: <Fragment>
                <Row gutter={[24, 24]}>
                    <Col xxl={2} xl={2} lg={4} md={8} sm={12} xs={12} style={{ borderRight: '1px solid #EEEEEE' }}>
                        <Flex vertical gap={8} justify='center' align='center'>
                            <Title level={2} type='success'>{leaves?.totalLeaves ? (leaves?.totalLeaves >= 10 ? leaves?.totalLeaves : `0${leaves?.totalLeaves}`) : holiDay}</Title>
                            <Text>{t('organizer_common_total')} <br />{t('organizer_common_leaves')}</Text>
                        </Flex>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={12} style={{ borderRight: '1px solid #EEEEEE' }}>
                        <Flex vertical gap={8} justify='center' align='center'>
                            <Title level={2} type='success'>{leaves?.remainPaidLeaves ? (leaves?.remainPaidLeaves >= 10 ? leaves?.remainPaidLeaves : `0${leaves?.remainPaidLeaves}`) : '00'}</Title>
                            <Text>{t('organizer_common_total')} {t('organizer_common_remain')} <br />{t('organizer_common_paid')} {t('organizer_common_leaves')}</Text>
                        </Flex>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={12} style={{ borderRight: '1px solid #EEEEEE' }}>
                        <Flex vertical gap={8} justify='center' align='center'>
                            <Title level={2} className='blue-color'>{leaves?.formalLeaves ? (leaves?.formalLeaves >= 10 ? leaves?.formalLeaves : `0${leaves?.formalLeaves}`) : '00'}</Title>
                            <Text>{t('organizer_common_formal')} {t('organizer_common_leaves')}</Text>
                        </Flex>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={12} style={{ borderRight: '1px solid #EEEEEE' }}>
                        <Flex vertical gap={8} justify='center' align='center'>
                            <Title level={2} className='yellow-color'>{leaves?.sickLeaves ? (leaves?.sickLeaves >= 10 ? leaves?.sickLeaves : `0${leaves?.sickLeaves}`) : '00'}</Title>
                            <Text>{t('organizer_common_sick')} {t('organizer_common_leaves')}</Text>
                        </Flex>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={8} sm={12} xs={12} style={{ borderRight: '1px solid #EEEEEE' }}>
                        <Flex vertical gap={8} justify='center' align='center'>
                            <Title level={2} className='red-color'>{leaves?.usedLeaves ? (leaves?.usedLeaves >= 10 ? leaves?.usedLeaves : `0${leaves?.usedLeaves}`) : '00'}</Title>
                            <Text>{t('organizer_common_total')} {t('organizer_common_used')}<br />{t('organizer_common_leaves')}</Text>
                        </Flex>
                    </Col>
                </Row>
            </Fragment>,
        }
    ];

    const handleOnFinish = (values) => {
        setFilterData(values);
    }

    // Table columns
    const columns = [
        {
            title: t('organizer_profilesettings_vacations_leavedaterange'),
            dataIndex: 'leaveDateRange',
            key: 'leaveDateRange',
            width: '200px',
            render: (_, record) => {
                return (
                    <Flex gap={8} align='center'>
                        <span style={{ backgroundColor: record?.leaveType === 'sick' ? '#FFCFC5' : '#B7E7AF', height: '0.75rem', width: '0.75rem', borderRadius: '50%' }}></span>
                        {
                            record?.leaveDates?.length !== 0 ?
                                <Text>{record.leaveDates.map(date => dayjs(date).format('DD.MM.YY')).join(', ')}</Text>
                                :
                                <Text>{`${dayjs(record?.leaveDateRange?.startDate).format('DD.MM.YY')} to ${dayjs(record?.leaveDateRange?.endDate).format('DD.MM.YY')}`}</Text>
                        }
                    </Flex>
                )
            },
        },
        {
            title: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type'),
            dataIndex: 'leaveType',
            key: 'type',
            width: '100px',
            render: (text) => <Text>{text?.charAt(0)?.toUpperCase() + text?.slice(1) || '-'}</Text>,
        },
        {
            title: t('organizer_common_total') + ' ' + t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_days'),
            dataIndex: 'leaveDays',
            key: 'leaveDays',
            width: '100px',
            render: (text) => <Text>{text} {t('organizer_common_days')}</Text>,
        },
        {
            title: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason'),
            dataIndex: 'leaveReason',
            key: 'leaveReason',
            width: '200px',
            render: (text) => <Text>{text || '-'}</Text>,
        },
        {
            title: t('organizer_common_uploadedfile'),
            dataIndex: 'file',
            key: 'file',
            width: '200px',
            render: (_, record) => {
                return record?.uploadedFile ? <Flex align='center' gap={3}>
                    <DocumentIcon />
                    <Text>{record?.uploadedFile}</Text>
                </Flex>
                    :
                    '-'
            },
        },
        {
            title: t('organizer_common_status'),
            dataIndex: 'status',
            key: 'status',
            width: '100px',
            render: (_, record) => {
                return <Fragment>
                    {
                        record.status?.toLowerCase() === 'approved' ?
                            <Tag color={lightPrimary} className='text-center'>{record?.status?.charAt(0)?.toUpperCase() + record?.status?.slice(1)}</Tag> :
                            record.status?.toLowerCase() === 'pending' ?
                                <Tag color={lightYellow} className='text-center'>{record?.status?.charAt(0)?.toUpperCase() + record?.status?.slice(1)}</Tag> :
                                <Tag color={lightSecondary} className='text-center'>{record?.status?.charAt(0)?.toUpperCase() + record?.status?.slice(1)}</Tag>
                    }
                </Fragment>
            },
        },
    ];

    // Permission

    if (Object.keys(permission ?? {})?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: <Flex justify='center'><Text>{t('organizer_common_action')}</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => {
                let isFutureDate;
                const currentDate = dayjs();
                if (record?.leaveDateRange?.startDate) {
                    isFutureDate = dayjs(record?.leaveDateRange?.startDate).isAfter(currentDate) || dayjs(record?.leaveDateRange?.endDate).isAfter(currentDate);
                }
                else {
                    isFutureDate = record?.leaveDates?.some(date => dayjs(date).isAfter(currentDate));
                }

                return (
                    <Flex justify='center'>
                        {
                            (isFutureDate && permission?.canUpdate) &&
                            <Button
                                type='text'
                                className='padding-1'
                                onClick={() => navigate(`/settings/add-new-vacation/edit/${record?._id}`)}
                            >
                                <EditIcon role='button' />
                            </Button>
                        }
                        {
                            (isFutureDate && permission?.canDelete) &&
                            <CommonPopconfirm
                                icon={null}
                                title={t('organizer_profilesettings_vacations_deleteLeave')}
                                description={t('organizer_profilesettings_vacations_deleteLeave_confirmdescription')}
                                onConfirm={() => dispatch(deleteVacation({
                                    id: record?._id,
                                    filters: {
                                        leaveType: '',
                                        startDate: '',
                                        endDate: ''
                                    },
                                    user: auth?.decodeToken?._id,
                                    userId: auth?.decodeToken?._id,
                                    organizer: auth?.decodeToken?.organizer,
                                    year: dayjs().format('YYYY')
                                }))}
                                cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                            >
                                <Button
                                    type='text'
                                    className='padding-1'>
                                    <CloseIcon role='button' />
                                </Button>
                            </CommonPopconfirm>
                        }
                        {
                            !isFutureDate && '-'
                        }
                    </Flex >
                );
            },
        })
    }

    const handleGeneratePDF = async () => {
        const values = form.getFieldsValue();
        const errors = {};

        if (!values.startDate) {
            errors.startDate = t('organizer_profilesettings_vacations_leave_from_date') + " " + t("organizer_common_isrequired");
        }
        if (!values.endDate) {
            errors.endDate = t('organizer_profilesettings_vacations_leave_to_date') + " " + t("organizer_common_isrequired");
        }

        if (Object.keys(errors).length > 0) {
            form.setFields(
                Object.entries(errors).map(([name, error]) => ({
                    name,
                    errors: [error],
                }))
            );
            return;
        }

        form.setFields(
            [{
                name: "startDate",
                errors: false
            },
            {
                name: "endDate",
                errors: false
            }
            ]
        );

        const leaves = await dispatch(getApiData({
            filters: {
                leaveType: values?.leave || '',
                startDate: dayjs(values?.startDate).format('DD.MM.YYYY'),
                endDate: dayjs(values?.endDate).format('DD.MM.YYYY')
            },
            user: auth?.decodeToken?._id,
            organizer: auth?.decodeToken?.organizer
        }))

        if (leaves.payload) {
            try {
                setIsGenerating(true);
                const blob = await pdf(<GenerateVacationPDF data={leaves.payload} />).toBlob();
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = "vacation.pdf";
                link.click();

                URL.revokeObjectURL(blobUrl);
                setIsGenerating(false);
            } catch (error) {
                openNotification({ message: error?.message, type: 'error' });
            }
        }
    }

    const handleFilterClear = () => {
        form.resetFields();
        if (Object.keys(filetrData ?? {})?.length > 0) {
            setFilterData({});
        }
    }

    // Filter Collapse items
    const filterItems = [
        {
            key: '1',
            label: <Title level={4} className='text-primary'>{t('organizer_common_filter')}</Title>,
            children: <Fragment>
                <Form
                    form={form}
                    name='filters'
                    colon={false}
                    layout='vertical'
                    onFinish={handleOnFinish}
                >
                    <Row gutter={18}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24} >
                            <Form.Item
                                name='leave'
                                label={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                        {
                                            label: t('organizer_common_formal') + ' ' + t('organizer_vacationmanagement_leave'),
                                            value: 'formal'
                                        },
                                        {
                                            label: t('organizer_common_sick') + ' ' + t('organizer_vacationmanagement_leave'),
                                            value: 'sick'
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </Col>

                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='startDate'
                                label={t('organizer_profilesettings_vacations_leave_from_date')}
                            >
                                <DatePicker className='w-100' format={'DD-MM-YYYY'} placeholder={t('organizer_profilesettings_vacations_leave_from_date')} />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='endDate'
                                label={t('organizer_profilesettings_vacations_leave_to_date')}
                            >
                                <DatePicker className='w-100' format={'DD-MM-YYYY'} placeholder={t('organizer_profilesettings_vacations_leave_to_date')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button disabled={isGenerating} type="text" className="border-light-green" onClick={handleGeneratePDF} >{!isGenerating ? <Text>{t('organizer_common_exportPDF')}</Text> : <Text>{t('organizer_common_downloading')}</Text>}</Button>
                        <Button danger type='primary' htmlType='button' onClick={handleFilterClear}>{t('organizer_common_clearbutton')}</Button>
                        <Button type='primary' htmlType='submit'>{t('organizer_common_applybutton')}</Button>
                    </Flex>
                </Form>
            </Fragment >,
        }
    ];

    // Expand Icon
    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };;

    return (
        <Fragment>
            <Flex vertical className='new-vacation bg-gray padding-1 h-100 change-tag' gap={24}>
                {
                    (Object.keys(permission || {}).length > 0 && permission?.canCreate) &&
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Flex justify='flex-end' className='bg-white padding-1'>
                                <Button onClick={() => navigate('/settings/add-new-Vacation/add')}>{t('organizer_profilesettings_addnewvaction')}</Button>
                            </Flex>
                        </Col>
                    </Row>
                }
                <Flex>
                    <Collapse
                        items={items}
                        defaultActiveKey={['1']}
                        className='w-100'
                        bordered={false}
                        expandIcon={customExpandIcon}
                        expandIconPosition={'end'}
                    />
                </Flex>
                <Flex>
                    <Collapse
                        items={filterItems}
                        defaultActiveKey={['1']}
                        className='w-100'
                        bordered={false}
                        expandIcon={customExpandIcon}
                        expandIconPosition={'end'}
                    />
                </Flex>
                <Flex vertical className='bg-white rounded'>
                    <Flex justify='flex-end' gap={8} className='padding-2'>
                        <div style={{ paddingRight: '10px' }}>
                            <span style={{ backgroundColor: '#FFCFC5', padding: '0px 10px', borderRadius: '4px' }}></span>
                            <span style={{ marginLeft: '8px' }}><b>{t('organizer_common_sick')} {t('organizer_vacationmanagement_leave')}</b></span>
                        </div>
                        <div>
                            <span style={{ backgroundColor: '#B7E7AF', padding: '0px 10px', borderRadius: '4px' }}></span>
                            <span style={{ marginLeft: '8px' }}><b>{t('organizer_common_formal')} {t('organizer_vacationmanagement_leave')}</b></span>
                        </div>
                    </Flex>
                    <DataTable
                        columns={columns}
                        scrollX={1000}
                        showSorterTooltip={false}
                        isPagination={false}
                        data={vacations}
                        rowKey={(record) => record?._id}
                    />
                </Flex>
            </Flex>
        </Fragment >
    );
};

export default AddNewVacation;
