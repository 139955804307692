import React, { Fragment, useEffect, useRef, useState } from 'react';

import { Flex, Row, Col, Input, Tabs, Typography, Form, Button, DatePicker, Select, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import RequestLeave from './request-leave';
import AddLeave from './add-leave';
import AllLeaves from './all-leaves';
import AllLeavesAdmin from './all-leaves-admin/Index';

import SearchIcon from '../../components/icon/SearchIcon';

import { getAdminLeavesListData, getApiData, getRequestedLeavesListData, getUserLeavesListData } from './redux/slice';

import '../../styles/pages/vacation-management.scss';
import CloseIcon from '../../components/icon/CloseIcon';
import { black } from '../../utils/theme/config';
import { getAllWorkerListing } from '../../redux/slice/global';
import { getVacationListData } from '../settings/redux/slice';
import openNotification from '../../components/notification';
import GenerateVacationPDF from './vactionPdf';
import { pdf } from '@react-pdf/renderer';

const { Title, Text } = Typography;

const VacationManagement = () => {
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const { auth, vacation, listing } = useSelector((state) => state);
    const [allUserLeavesList, setAllUserLeavesList] = useState([]);
    const [userLeavas, setUserLeavas] = useState([]);
    const [requestLeaveList, setRequestLeaveList] = useState([]);
    const [month, setMonth] = useState(dayjs().format('MM'));
    const [year, setYear] = useState(dayjs().format('YYYY'));
    const [fileList, setFileList] = useState([]);
    const [document, setDocument] = useState({});

    const { t } = useTranslation();
    // for Add Leave Page
    const [form] = Form.useForm();
    const [modalform] = Form.useForm();
    const [leavesDetails, setLeavesDetails] = useState({});
    const [open, setOpen] = useState(false);
    const downloadLinkRef = useRef();

    useEffect(() => {
        getLeavesData();
    }, [auth?.decodeToken?.organizer, month, year])

    const getLeavesData = () => {
        if (auth?.decodeToken?.organizer || auth?.decodeToken?._id) {
            dispatch(getAdminLeavesListData({
                month: month,
                year: year,
                organizer: auth?.decodeToken?.organizer
            }))
            dispatch(getUserLeavesListData({
                month: month,
                year: year,
                user: auth?.decodeToken?._id
            }))
        }
    }

    useEffect(() => {
        setAllUserLeavesList(vacation?.vacationList);
        setUserLeavas(vacation?.userVacationList)
    }, [vacation?.vacationList, vacation?.userVacationList])

    useEffect(() => {
        getRequestedLeaveData();
        getWorkerData();
    }, [auth?.decodeToken])

    const getRequestedLeaveData = () => {
        if (Object.keys(auth?.decodeToken)?.length > 0) {
            dispatch(getRequestedLeavesListData({
                organizer: auth?.decodeToken?.organizer
            }));
        }
    }

    // Get Leaves Data
    useEffect(() => {
        getVacationList();
    }, [auth?.decodeToken?.organizer]);

    // Get Vacation List
    const getVacationList = () => {
        if (auth?.decodeToken?.organizer) {
            dispatch(getVacationListData({
                filters: {
                    leaveType: '',
                    startDate: '',
                    endDate: ''
                },
                user: null,
                organizer: auth?.decodeToken?.organizer
            }))
        }
    };

    useEffect(() => {
        setRequestLeaveList(vacation?.requestList);
    }, [vacation?.requestList])

    const getWorkerData = () => {
        if (Object.keys(auth?.decodeToken)?.length > 0) {
            dispatch(getAllWorkerListing(auth?.decodeToken?.organizer));
        }
    }

    const handleOnFinish = async (values) => {
        const leaves = await dispatch(getApiData({
            filters: {
                leaveType: values?.leaveType || '',
                startDate: dayjs(values?.startDate).format('DD.MM.YYYY'),
                endDate: dayjs(values?.endDate).format('DD.MM.YYYY')
            },
            user: values?.user,
            organizer: auth?.decodeToken?.organizer
        }))

        if (leaves.payload) {
            try {
                const blob = await pdf(<GenerateVacationPDF data={leaves.payload} />).toBlob();
                const blobUrl = URL.createObjectURL(blob);
                if (downloadLinkRef.current) {
                    downloadLinkRef.current.href = blobUrl;
                    downloadLinkRef.current.download = "vacation.pdf";
                    downloadLinkRef.current.click(); // Trigger the download using the ref
                }

                URL.revokeObjectURL(blobUrl);
                setOpen(false);
                modalform.resetFields();
            } catch (error) {
                openNotification({ message: error?.message, type: 'error' });
            }
        }
    }

    const handleClear = () => {
        modalform.resetFields();
    };

    const handleTabChange = () => {
        getLeavesData();
        getRequestedLeaveData();
        setLeavesDetails({});
        form.resetFields();
        setFileList([]);
        setDocument({});
        getVacationList();
    }

    return (
        <Fragment>
            <Flex vertical gap={24}>
                <Row
                    justify='space-between'
                    align='middle'
                    className='padding-1 rounded-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none'>
                                <Title level={4} type='success'>{t('organizer_vacationmanagement_main_title')}</Title>
                            </Col>
                            <Col flex='auto' xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Input
                                    value={search}
                                    placeholder={t('organizer_common_search')}
                                    onChange={(e) => setSearch(e.target.value)}
                                    prefix={<SearchIcon color='#000' size={20} />}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                        <Flex justify='end'>
                            <Button onClick={() => setOpen(true)}>{t('organizer_common_exportPDF')}</Button>

                            <Modal
                                open={open}
                                closeIcon={<CloseIcon color={black} role='button' />}
                                title={<Fragment><Title level={4} type='success'>{t('organizer_common_exportPDF')}</Title></Fragment>}
                                footer={null}
                                onCancel={() => { setOpen(false); modalform.resetFields() }}
                                modalRender={(dom) => (
                                    <Form
                                        layout='vertical'
                                        form={modalform}
                                        name='inventory-form'
                                        requiredMark={(label, isRule) => {
                                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                                        }}
                                        onFinish={(values) => handleOnFinish(values)}
                                    >
                                        {dom}
                                    </Form>
                                )}
                            >
                                <Row gutter={18}>
                                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <Form.Item
                                            name='user'
                                            label={t('organizer_common_user')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('organizer_common_user') + ' ' + t('organizer_common_isrequired'),
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder={t('organizer_common_user')}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={listing?.listing?.allWorkers}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <Form.Item
                                            name='leaveType'
                                            label={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder={t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_type')}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={[
                                                    {
                                                        label: t('organizer_common_formal') + ' ' + t('organizer_vacationmanagement_leave'),
                                                        value: 'formal'
                                                    },
                                                    {
                                                        label: t('organizer_common_sick') + ' ' + t('organizer_vacationmanagement_leave'),
                                                        value: 'sick'
                                                    }
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('organizer_common_startDate')}
                                            name="startDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('organizer_common_startDate') + ' ' + t('organizer_common_isrequired'),
                                                },
                                            ]}
                                        >
                                            <DatePicker className='w-100' format={'DD-MM-YYYY'} />
                                        </Form.Item>
                                    </Col>

                                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('organizer_common_endDate')}
                                            name="endDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('organizer_common_endDate') + ' ' + t('organizer_common_isrequired'),
                                                },
                                            ]}
                                        >
                                            <DatePicker className='w-100' format={'DD-MM-YYYY'} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item className='margin-0'>
                                    <Row gutter={16}>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Button type='primary' danger block htmlType='button' onClick={handleClear}>{t('organizer_common_cancelButton')}</Button>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Button type='primary' block htmlType='submit'>{t('organizer_common_exportPDF')}</Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Modal>
                        </Flex>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} className='bg-white rounded-2'>
                        <Tabs
                            type='card'
                            size='large'
                            onChange={handleTabChange}
                            defaultActiveKey='1'
                            items={[
                                {
                                    label: t('organizer_vacationmanagement_all_leaves'),
                                    key: '1',
                                    children: <AllLeaves userLeavas={userLeavas} month={month} year={year} setMonth={setMonth} setYear={setYear} />,
                                },
                                {
                                    label: t('organizer_vacationmanagement_all_leaves') + ' ' + 'Admin',
                                    key: '2',
                                    children: <AllLeavesAdmin allUserLeavesList={allUserLeavesList} month={month} year={year} setMonth={setMonth} setYear={setYear} />,
                                },
                                {
                                    label: t('organizer_common_add') + ' ' + t('organizer_vacationmanagement_leave'),
                                    key: '3',
                                    children: <AddLeave form={form} getVacationList={getVacationList} document={document} setDocument={setDocument} setLeavesDetails={setLeavesDetails} leavesDetails={leavesDetails} fileList={fileList} setFileList={setFileList} />,
                                },
                                {
                                    label: t('organizer_vacationmanagement_requested') + ' ' + t('organizer_vacationmanagement_leave'),
                                    key: '4',
                                    children: <RequestLeave requestLeaveList={requestLeaveList} />,
                                },
                            ]}
                            className='padding-2'
                        />
                    </Col>
                </Row>
                <a ref={downloadLinkRef} style={{ display: 'none' }}></a>
            </Flex>
        </Fragment>
    );
};

export default VacationManagement;
