import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { Flex, Row, Col, Collapse, Input, Button, Form, Typography, Divider, Select, Modal, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Html5Qrcode } from 'html5-qrcode';

import ExpandIcon from '../../components/icon/ExpandIcon';
import SearchIcon from '../../components/icon/SearchIcon';
import EditIcon from '../../components/icon/EditIcon';
import CloseIcon from '../../components/icon/CloseIcon';
import ConfirmationIcon from '../../components/icon/ConfirmationIcon';

import { black, danger } from '../../utils/theme/config';
import DataTable from '../../components/data-table/index.jsx';
import checkmark from '../../assets/images/checkmark.svg';

import { getAllStammdatenProjectListing, getCityListing, getLagarListing, getRackNumberListing, getRackSelfNumberListing } from '../../redux/slice/global.js';
import { getByIdEquipment, getByIdEquipmentHistory, getByIdHistoryEquipment, getEquipmentHistoryListsData, getHistoryEquipment, getLagerByCityList, getRackByLagerList, getSelfNumberByLagerList, setError, setIsHistorySuccess, updateEquipmentHistory } from './redux/slice.js';
import openNotification from '../../components/notification/index.jsx';

const { Title, Text } = Typography;

const EquipmentHistory = ({ permission }) => {
    const [equipmentHistoryList, setEquipmentHistoryList] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { listing, auth, equipment } = useSelector((state) => state);
    const [historyForm] = Form.useForm();
    const [filterForm] = Form.useForm();
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [equipmentHistoryPageSize, setEquipmentHistoryPageSize] = useState(10);
    const [equipmentHistoryPage, setEquipmentHistoryPage] = useState(1);
    const [equipmentHistoryTotalRecords, setEquipmentHistoryTotalRecords] = useState([]);
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [stammProjects, setStammProjects] = useState([]);
    const [rackNumberLists, setRackNumberLists] = useState([]);
    const [rackSelfNumberLists, setRackSelfNumberLists] = useState([]);
    const [filters, setFilters] = useState({});
    const [lagerOrProject, setLagerOrProject] = useState('');
    const [cityLists, setCityLists] = useState([]);
    const [isWarehouseDisabled, setWarehouseDisabled] = useState(true);
    const [isRackDisabled, setRackDisabled] = useState(true);
    const [isSelfDisabled, setSelfDisabled] = useState(true);
    const [apiPayload, setApiPayload] = useState({});
    const [successModal, setSuccessModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const qrCodeRef = useRef(null);

    const checkPermissionsAndStartScanning = async () => {
        try {
            // Request camera permissions
            await navigator.mediaDevices.getUserMedia({ video: true });
            startScanning(); // Start scanning if permissions are granted
        } catch (err) {
            openNotification({ message: t('organizer_common_camerapermission'), type: 'error' })
        }
    };

    const startScanning = () => {
        const qrCodeRegionId = 'reader';

        if (!qrCodeRef.current) {
            qrCodeRef.current = new Html5Qrcode(qrCodeRegionId);
        }

        qrCodeRef.current
            .start(
                { facingMode: 'environment' }, // Use the back camera
                {
                    fps: 10,
                    qrbox: { width: 250, height: 250 },
                },
                (decodedText) => {
                    if (decodedText) {
                        dispatch(getByIdEquipmentHistory(decodedText))
                    }
                    stopScanning();
                },
                (errorMessage) => {
                }
            )
            .catch((err) => { });
    };

    const stopScanning = () => {
        qrCodeRef.current?.stop().then(() => {
            qrCodeRef.current = null;
        });
        setIsModalOpen(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setTimeout(() => checkPermissionsAndStartScanning(), 500); // Delay to ensure modal is rendered
    };

    const handleCloseModal = () => {
        stopScanning();
    };

    // Get city lists
    useEffect(() => {
        dispatch(getCityListing());
    }, [])

    useEffect(() => {
        setCityLists(listing.listing?.city)
    }, [listing.listing?.city])

    useEffect(() => {
        getStammProjectListing();
    }, [auth?.decodeToken?.organizer])

    const getStammProjectListing = () => {
        if (auth?.decodeToken?.organizer) {
            dispatch(getAllStammdatenProjectListing(auth?.decodeToken?.organizer));
            dispatch(getRackNumberListing(auth?.decodeToken?.organizer));
            dispatch(getRackSelfNumberListing(auth?.decodeToken?.organizer));
            dispatch(getLagarListing(auth?.decodeToken?.organizer));
        }
    }

    // Get Eqipment lists
    const getEquipmentHistoryData = useCallback(() => {
        if (auth?.decodeToken?.organizer) {
            dispatch(getEquipmentHistoryListsData({
                sortBy,
                order,
                search,
                filter: {
                    uniqueIdentifier: filters?.uniqueIdentifier || '',
                    rackNumber: filters?.rackNumber || '',
                    rackSelfNumber: filters?.rackSelfNumber || ''
                },
                page: equipmentHistoryPage,
                limit: equipmentHistoryPageSize,
                organizer: auth?.decodeToken?.organizer
            }));
        }
    }, [
        sortBy,
        order,
        search,
        filters,
        equipmentHistoryPage,
        equipmentHistoryPageSize,
        auth?.decodeToken?.organizer
    ]);

    useEffect(() => {
        getEquipmentHistoryData();
    }, [getEquipmentHistoryData]);

    useEffect(() => {
        setEquipmentHistoryList(equipment?.equipmentHistoryLists);
        setEquipmentHistoryTotalRecords(equipment?.historyTotalRecords);
    }, [equipment?.equipmentHistoryLists, equipment?.historyTotalRecords, filters])

    useEffect(() => {
        setStammProjects(listing.listing?.stammProjects);
    }, [listing.listing?.stammProjects])

    useEffect(() => {
        setRackNumberLists(listing.listing?.rackNumbers);
    }, [listing.listing?.rackNumbers])

    useEffect(() => {
        setRackSelfNumberLists(listing.listing?.rackSelfNumbers);
    }, [listing.listing?.rackSelfNumbers])

    // Handle Filter finish
    const handleFilterFinish = (values) => {
        setFilters(values);
    }

    const handleFilterClear = () => {
        filterForm.resetFields();
        if (Object.keys(filters).length > 0) {
            setFilters({});
        }
    }

    // Set data on form 
    useEffect(() => {
        if (Object.keys(equipment?.historyEquipment).length > 0) {
            historyForm.setFieldsValue({ ...equipment?.historyEquipment, currentLocation: equipment?.historyEquipment?.currentLocation });
            equipment?.historyEquipment?.city && dispatch(getLagerByCityList({ cityId: equipment?.historyEquipment?.city, organizerId: auth?.decodeToken?.organizer }));
            equipment?.historyEquipment?.rackNumber && dispatch(getSelfNumberByLagerList({ rackNumberId: equipment?.historyEquipment?.rackNumber, organizerId: auth?.decodeToken?.organizer }));
            equipment?.historyEquipment?.lager && dispatch(getRackByLagerList({ lagerId: equipment?.historyEquipment?.lager, organizerId: auth?.decodeToken?.organizer }));
            equipment?.historyEquipment?.city ? setWarehouseDisabled(false) : setWarehouseDisabled(true);
            equipment?.historyEquipment?.rackNumber ? setSelfDisabled(false) : setSelfDisabled(true);
            equipment?.historyEquipment?.lager ? setRackDisabled(false) : setRackDisabled(true);
            setHistoryModalOpen(true);
        }
    }, [equipment?.historyEquipment])

    // Filter item form
    const filterItems = [
        {
            key: '1',
            label: <Title level={4} type='success' className='text-primary'>{t('organizer_common_filter')}</Title>,
            children: <Fragment>
                <Form
                    form={filterForm}
                    className='user-filter'
                    name='filters'
                    colon={false}
                    layout='vertical'
                    onFinish={handleFilterFinish}
                >
                    <Row gutter={18}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='uniqueIdentifier'
                                label={t('organizer_common_UUID')}
                            >
                                <Input placeholder={t('organizer_common_UUID')} />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='rackNumber'
                                label={t('organizer_common_racknumber')}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder={t('organizer_common_racknumber')}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={rackNumberLists}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='rackSelfNumber'
                                label={t('organizer_common_rackSelfNumber')}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder={t('organizer_common_racknumber')}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={rackSelfNumberLists}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' htmlType='button' onClick={handleFilterClear}>{t('organizer_common_clearbutton')}</Button>
                        <Button type='primary' htmlType='submit'>{t('organizer_common_applybutton')}</Button>
                    </Flex>
                </Form>
            </Fragment >,
        }
    ];

    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    // Table Column Sort Funcation
    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    const equipmentHistoryColumns = [
        {
            title: t('organizer_common_UUID'),
            dataIndex: 'uniqueIdentifier',
            key: 'uniqueIdentifier',
            width: '100px',
        },
        {
            title: t('organizer_equipmentmanagement_currantdate'),
            dataIndex: 'createdDate',
            key: 'createdDate',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'createdDate'),
        },
        {
            title: t('organizer_equipmentmanagement_equipment') + ' ' + t('organizer_common_name'),
            dataIndex: 'equipmentName',
            key: 'equipmentName',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'equipmentName'),
        },
        {
            title: t('organizer_equipmentmanagement_equipmentsn'),
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'serialNumber'),
            render: (_, record) => record?.serialNumber || '-'
        },
        {
            title: t('organizer_common_person'),
            dataIndex: 'person',
            key: 'person',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'person'),
        },
        {
            title: t('organizer_common_projectSKU'),
            dataIndex: 'equipmentSKU',
            key: 'equipmentSKU',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'equipmentSKU'),
            render: (_, record) => record?.equipmentSKU || '-'
        },
        {
            title: t('organizer_common_old'),
            dataIndex: 'old',
            key: 'old',
            width: '200px',
            render: (_, record) => record?.old?.lager || record?.old?.project,
            sorter: (_, __, order) => handleSortFunction(order, 'old'),
        },
        {
            title: t('organizer_common_new'),
            dataIndex: 'new',
            key: 'new',
            width: '200px',
            render: (_, record) => record?.new?.lager || record?.new?.project,
            sorter: (_, __, order) => handleSortFunction(order, 'new'),
        },
    ]

    const handleEquipmentHistoryOnChange = (page, pageSize) => {
        setEquipmentHistoryPageSize(pageSize);
        setEquipmentHistoryPage(page);
    };

    const handleOnHistoryFinish = (values) => {
        const payload = {
            equipmentId: equipment?.historyEquipment?._id,
            uniqueIdentifier: equipment?.historyEquipment?.uniqueIdentifier,
            equipmentName: values?.equipmentName,
            serialNumber: values?.serialNumber,
            project: !lagerOrProject ? equipment?.historyEquipment?.project : values?.project || null,
            lager: !lagerOrProject ? equipment?.historyEquipment?.lager : values?.lager || null,
            city: !lagerOrProject ? equipment?.historyEquipment?.city : values?.city || null,
            rackNumber: !lagerOrProject ? equipment?.historyEquipment?.rackNumber : values?.rackNumber || null,
            rackSelfNumber: !lagerOrProject ? equipment?.historyEquipment?.rackSelfNumber : values?.rackSelfNumber || null,
            oldTransfor: {
                project: equipment?.historyEquipment?.currentLocationId?.project || null,
                lager: equipment?.historyEquipment?.currentLocationId?.lager || null
            },
            newTransfor: {
                project: values?.project || null,
                lager: values?.lager || null
            },
            organizer: auth?.decodeToken?.organizer,
            user: auth?.decodeToken?._id,
            currentLocation: equipment?.historyEquipment?.currentLocation,
            person: equipment?.historyEquipment?.person,
        }
        setApiPayload(payload);
        setConfirmationModal(true);
        setHistoryModalOpen(false)
    };

    useEffect(() => {
        if (equipment?.isHistorySuccess) {
            dispatch(setIsHistorySuccess(false));
            setConfirmationModal(false);
            historyForm.resetFields();
            setSuccessModal(true);
            dispatch(getHistoryEquipment({}));
            setHistoryModalOpen(false);
        }

        if (equipment?.error) {
            setErrorModal(true);
            historyForm.resetFields();
            setConfirmationModal(false);
            setSuccessModal(false);
            dispatch(setError(false));
            setApiPayload({});
            setHistoryModalOpen(false);
        }
    }, [equipment?.isHistorySuccess, equipment?.error])

    const handleOnChange = (e) => {
        setLagerOrProject(e);
    }

    const handleFieldChange = (type, value) => {
        if (type === 'city') {
            dispatch(getLagerByCityList({ cityId: value, organizerId: auth?.decodeToken?.organizer }));
            historyForm.setFieldsValue({ lager: undefined, rackNumber: undefined, rackSelfNumber: undefined });
            setWarehouseDisabled(false);
        }

        if (type === 'lager') {
            setRackDisabled(false);
            historyForm.setFieldsValue({ rackNumber: undefined, rackSelfNumber: undefined });
            dispatch(getRackByLagerList({ lagerId: value, organizerId: auth?.decodeToken?.organizer }));
        }

        if (type === 'rackNumber') {
            setSelfDisabled(false);
            historyForm.setFieldsValue({ rackSelfNumber: undefined });
            dispatch(getSelfNumberByLagerList({ rackNumberId: value, organizerId: auth?.decodeToken?.organizer }));
        }
    }

    const hanldeOnConfirm = () => {
        dispatch(updateEquipmentHistory({ equipmentData: apiPayload, organizer: auth?.decodeToken?.organizer }));
    }

    const handleSuccess = () => {
        setSuccessModal(false);
        setApiPayload({});
        setLagerOrProject('');
    }

    const handleError = () => {
        setErrorModal(false);
        setApiPayload({});
        setLagerOrProject('');
    }

    return (
        <Flex vertical gap={24} className='change-tag'>
            <Row
                justify='space-between'
                align='middle'
                className='bg-white padding-1 rounded-1'
                gutter={[16, 16]}
            >
                <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Row align='middle' gutter={[16, 16]}>
                        <Col flex='none'>
                            <Title level={4} type='success'>{t('organizer_common_equipmentmanagement')}</Title>
                        </Col>
                        <Col flex='auto' xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Input
                                value={search}
                                placeholder={t('organizer_common_search')}
                                onChange={(e) => setSearch(e.target.value)}
                                prefix={<SearchIcon color='#000' size={20} />}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                    <Flex justify='end' gap={16}>
                        <Button onClick={handleOpenModal}>{t('organizer_equipmentmanagement_scanqrcode')}</Button>
                        <Modal
                            title='Qr Code Scanner'
                            open={isModalOpen}
                            onCancel={handleCloseModal}
                            footer={null}
                        >
                            <div id='reader' style={{ width: '100%', height: 'auto' }}></div>
                        </Modal>
                    </Flex>
                </Col>

            </Row>
            <Modal
                width={700}
                open={historyModalOpen}
                closeIcon={<CloseIcon color={black} role='button' />}
                title={<Fragment><Title level={4} type='success'>{t('organizer_equipmentmanagement_equipment')} {t('organizer_common_history')}</Title><Divider /></Fragment>}
                footer={null}
                onCancel={() => { setHistoryModalOpen(false); historyForm.resetFields(); setLagerOrProject(''); dispatch(getHistoryEquipment({})); }}
                modalRender={(dom) => (
                    <Form
                        layout='vertical'
                        form={historyForm}
                        name='lagar-form'
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        onFinish={(values) => handleOnHistoryFinish(values)}
                    >
                        {dom}
                    </Form>
                )}
            >
                <Row gutter={16}>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='uniqueIdentifier'
                            label={t('organizer_common_UUID')}
                        >
                            <Input placeholder={t('organizer_common_UUID')} disabled />
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='equipmentName'
                            label={t('organizer_equipmentmanagement_equipmentname')}
                        >
                            <Input placeholder={t('organizer_equipmentmanagement_equipmentname')} disabled />
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='serialNumber'
                            label={t('organizer_equipmentmanagement_equipmentserialno')}
                        >
                            <Input placeholder={t('organizer_equipmentmanagement_equipmentserialno')} disabled />
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='person'
                            label={t('organizer_common_person')}
                        >
                            <Input placeholder={t('organizer_common_person')} disabled />
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='currentLocation'
                            label={t('organizer_equipmentmanagement_currentlocation')}
                        >
                            <Input placeholder={t('organizer_equipmentmanagement_currentlocation')} disabled />
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='moveTo'
                            label={t('organizer_common_moveto')}
                        >
                            <Select
                                className='w-100'
                                showSearch
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                placeholder={t('organizer_common_moveto')}
                                onChange={handleOnChange}
                                options={[
                                    {
                                        label: 'Lager',
                                        value: 'lager'
                                    },
                                    {
                                        label: 'Project',
                                        value: 'project'
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    {
                        lagerOrProject?.toLowerCase() === 'project' &&
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='project'
                                label={t('organizer_common_project')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_project') + ' ' + t('organizer_common_isrequired'),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    className='w-100'
                                    placeholder={t('organizer_common_project')}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={stammProjects}
                                />
                            </Form.Item>
                        </Col>
                    }

                    {
                        lagerOrProject?.toLowerCase() === 'lager' &&
                        <Fragment>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='city'
                                    label={t('organizer_common_city')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('organizer_common_city') + ' ' + t('organizer_common_isrequired'),
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={t('organizer_common_city')}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        onChange={(value) => handleFieldChange('city', value)}
                                        options={cityLists}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='lager'
                                    label={t('organizer_common_lagername')}
                                    rules={[
                                        {
                                            required: !isWarehouseDisabled,
                                            message: t('organizer_common_lagername') + ' ' + t('organizer_common_isrequired'),
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={t('organizer_common_lagername')}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        disabled={isWarehouseDisabled}
                                        onChange={(value) => handleFieldChange('lager', value)}
                                        options={equipment?.lagerLists}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='rackNumber'
                                    label={t('organizer_common_racknumber')}
                                    rules={[
                                        {
                                            required: !isRackDisabled,
                                            message: t('organizer_common_racknumbererror'),
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={t('organizer_common_racknumber')}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        disabled={isRackDisabled}
                                        options={equipment?.rackeNumbersLists}
                                        onChange={(value) => handleFieldChange('rackNumber', value)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='rackSelfNumber'
                                    label={t('organizer_common_selfnumber')}
                                    rules={[
                                        {
                                            required: !isSelfDisabled,
                                            message: t('organizer_common_selfnumber') + ' ' + t('organizer_common_isrequired')
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={t('organizer_common_selfnumber')}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        disabled={isSelfDisabled}
                                        options={equipment?.selfNumberLists}
                                    />
                                </Form.Item>
                            </Col>
                        </Fragment>
                    }
                </Row>
                <Flex justify='flex-end' gap={8} className='padding-top-3'>
                    <Button type='primary' htmlType='submit'>{t('organizer_common_confirmed')}</Button>
                </Flex>
            </Modal>
            <Modal
                open={confirmationModal}
                closeIcon={<CloseIcon color={black} role='button' />}
                title={null}
                footer={null}
                onCancel={() => { setConfirmationModal(false); setHistoryModalOpen(true) }}
            >
                <Flex vertical className='padding-top-2' gap={10}>
                    {/* Confirm Modal */}
                    <Flex gap={16}>
                        <ConfirmationIcon />
                        <Title level={5} type='success'>{t('organizer_common_confirmationdescription')}</Title>
                    </Flex>
                    <Flex className='paddingX-3'>
                        <Text className='paddingX-2'>{t('organizer_equipmentmanagement_confirmmovetest')} {Object.keys(apiPayload ?? {}).length > 0 && apiPayload?.uniqueIdentifier} <br />
                            {apiPayload?.equipmentName} {t('organizer_common_from')?.toLowerCase()} {apiPayload?.currentLocation}
                            {' ' + t('organizer_common_to').toLowerCase() + ' '}
                            {lagerOrProject?.toLowerCase() === 'project' ? stammProjects?.find((item) => item.value === apiPayload?.project)?.label : listing?.listing?.lagar?.find((item) => item.value === apiPayload?.lager)?.label}
                        </Text>
                    </Flex>
                    <Flex justify='flex-end' gap={16}>
                        <Button type='primary' className='paddingX-3' onClick={() => { setHistoryModalOpen(true); setConfirmationModal(false) }} danger style={{ width: '5.25rem' }}>{t('organizer_common_noButton')}</Button>
                        <Button type='primary' style={{ width: '5.25rem' }} loading={equipment?.loadingHistory} onClick={hanldeOnConfirm}>{t('organizer_common_yesButton')}</Button>
                    </Flex>
                </Flex>
            </Modal>
            <Modal
                open={successModal}
                closeIcon={<CloseIcon color={black} role='button' />}
                title={null}
                footer={null}
                onCancel={() => { setSuccessModal(false); }}
            >
                <Flex vertical className='padding-top-2' gap={10}>
                    {/* Success Modal */}
                    <Flex justify='center' align='center'>
                        <Image src={checkmark} preview={false} />
                    </Flex>
                    <Flex justify='center' align='center'>
                        <Text type='success' className='text-center'>{t('organizer_equipmentmanagement_equipment')} {Object.keys(apiPayload ?? {}).length > 0 && apiPayload?.uniqueIdentifier} {apiPayload?.equipmentName} {t('organizer_common_from')?.toLowerCase()} {apiPayload?.currentLocation} <br />
                            {t('organizer_common_to').toLowerCase() + ' '}
                            {lagerOrProject?.toLowerCase() === 'project' ? stammProjects?.find((item) => item.value === apiPayload?.project)?.label : listing?.listing?.lagar?.find((item) => item.value === apiPayload?.lager)?.label + ' '}
                            {' ' + t('organizer_equipmentmanagement_successfullymoved')}.</Text>
                    </Flex>
                    <Flex justify='center' align='center' gap={16}>
                        <Button type='primary' style={{ width: '5.25rem' }} onClick={handleSuccess}>{t('organizer_common_okButton')}</Button>
                    </Flex>

                </Flex>
            </Modal >
            <Modal
                open={errorModal}
                closeIcon={<CloseIcon color={black} role='button' />}
                title={null}
                footer={null}
                onCancel={() => { setErrorModal(false); }}
            >
                <Flex vertical className='padding-top-2' gap={10}>
                    {/* Error Modal */}
                    <Flex justify='center' align='center'>
                        <ConfirmationIcon color={danger} />
                    </Flex>
                    <Flex justify='center' align='center'>
                        <Text type='danger' className='text-center'>{t('organizer_equipmentmanagement_somethingwentwrongmessage')} <br />
                            {t('organizer_equipmentmanagement_somethingwentwrongmessagesecond')}.</Text>
                    </Flex>
                    <Flex justify='center' align='center' gap={16}>
                        <Button type='primary' onClick={handleError}>{t('organizer_common_gotohome')}</Button>
                    </Flex>
                </Flex>
            </Modal >
            <Flex className='filter-margin-top'>
                <Collapse
                    items={filterItems}
                    defaultActiveKey={['1']}
                    className='w-100'
                    bordered={false}
                    expandIcon={customExpandIcon}
                    expandIconPosition={'end'}
                />
            </Flex>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <DataTable
                        columns={equipmentHistoryColumns}
                        pageSize={equipmentHistoryPageSize}
                        currentPage={equipmentHistoryPage}
                        total={equipmentHistoryTotalRecords}
                        scrollX={1000}
                        showSorterTooltip={false}
                        isPagination={true}
                        handleOnChange={handleEquipmentHistoryOnChange}
                        data={equipmentHistoryList}
                        rowKey={(record) => record?._id}
                    />
                </Col>
            </Row>
        </Flex >
    );
};

export default EquipmentHistory;
