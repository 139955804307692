import React, { Fragment, useEffect, useState } from 'react';

import { Layout } from 'antd';
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SidebarComponent from './sidebar';
import HeaderComponent from './header';
import ContenetComponent from './content';
import Designation from '../pages/designation/Designation';

import { privateRoutes } from '../navigations/navigations';

import { setLoginUserId, setLoginUserPackage, setLoginUserRole } from '../pages/user/redux/slice';
import { getDecodeToken, onOrganizerBoardring, setIsAuthorize } from '../pages/auth/redux/slice';
import { getLanguage, setMenuPermission } from '../redux/slice/global';
import { getUserTypeMenuListing } from '../pages/user-type/redux/slice';

const Wrapper = ({ children }) => {
    const dispatch = useDispatch()
    const { auth } = useSelector(state => state)
    const [collapsed, setCollapsed] = useState(false);
    const [loginUser, setLoginUser] = useState({});
    const [filteredRoutes, setFilteredRoutes] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (token) {
            const user = jwtDecode(token);
            if (user._id && auth.isAuthorize !== '') {
                user.organizer && dispatch(onOrganizerBoardring(user._id))
            }

            dispatch(getDecodeToken(user))
            setLoginUser(user);
            dispatch(setIsAuthorize(user.isInitialSignIn))
            dispatch(setLoginUserId(user._id))
            dispatch(setLoginUserRole(user.role))
            dispatch(setLoginUserPackage(user.package));
            dispatch(getLanguage(user._id));
            if (user?.package) {
                dispatch(getUserTypeMenuListing(user?.package))
            }else{
                dispatch(getUserTypeMenuListing())
            }
        }
    }, [])

    useEffect(() => {
        if (loginUser) {
            const token = localStorage.getItem("token");
            const user = jwtDecode(token);

            if (user._id && auth.isAuthorize !== '') {
                user?.organizer && dispatch(onOrganizerBoardring(user._id));
            }

            const filterRoutesByPermission = (routes, permissions) => {
                return routes.map(route => {
                    const matchingPermission = permissions.find(permission => route?.label && (permission.menu === route.menu && permission?.permissions?.hasAccess));

                    if (matchingPermission) {
                        if (route.children && route.children.length > 0) {
                            const filteredChildren = route.children.filter(child =>
                                matchingPermission.children &&
                                matchingPermission.children.some(permissionChild => {
                                    return (permissionChild.menu === child.menu && child.label) && permissionChild?.permissions?.hasAccess
                                })
                            );

                            return {
                                ...route,
                                children: filteredChildren
                            };
                        }
                        return route;
                    }
                    return null;
                }).filter(route => route !== null);
            };

            const filteredRoutes = filterRoutesByPermission(privateRoutes, user?.permissions[0]?.menu);

            if (user?.organizer && auth?.isAuthorize) {
                setFilteredRoutes(filteredRoutes?.map((item) => {
                    if (item.label === 'Settings') {
                        const x = item.children.map((children) => {
                            if (children.label.props.children === 'General Setting') {
                                return children
                            }
                            else {
                                return {
                                    ...children,
                                    disabled: auth?.isAuthorize,
                                    label: auth?.isAuthorize ? children.label?.props?.children ? children.label?.props?.children : children.label : children.label
                                }
                            }
                        })
                        return {
                            ...item,
                            children: x
                        };
                    }
                    else {
                        return {
                            ...item,
                            disabled: auth?.isAuthorize,
                            label: auth?.isAuthorize ? item.label?.props?.children ? item.label?.props?.children : item.label : item.label
                        }
                    }
                })?.map(i => {
                    const { children, ...route } = i
                    return {
                        ...route,
                        ...(children?.length > 0) && { children: children }
                    }
                })
                );
            } else {
                setFilteredRoutes(filteredRoutes?.map(i => {
                    const { children, ...route } = i
                    return {
                        ...route,
                        ...(children?.length > 0) && { children: children }
                    }
                })
                );
            }
        }

    }, [loginUser, auth?.isAuthorize]);

    return (
        <Fragment>
            <Layout className='vh-100 flex gap-1 padding-2'>
                <SidebarComponent collapsed={collapsed} setCollapsed={setCollapsed} filteredRoutes={filteredRoutes} />
                <Layout>
                    <HeaderComponent collapsed={collapsed} setCollapsed={setCollapsed} />
                    <ContenetComponent children={children} />
                </Layout>
            </Layout>
        </Fragment>
    )
}

export default Wrapper;
