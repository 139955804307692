import { Link } from 'react-router-dom';

import { Image } from 'antd';

import User from '../pages/user/User';
import AddUpdateUser from '../pages/user/AddUpdateUser';
import CheckMail from '../pages/auth/CheckMail';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';
import SignIn from '../pages/auth/SignIn';
import VerifyOTP from '../pages/auth/VerifyOTP';
import SetPassword from '../pages/auth/SetPassword';
import WelcomePage from '../pages/auth/WelcomePage';
import UserType from '../pages/user-type/UserType';
import UpdateUserType from '../pages/user-type/UpdateUserType';
import Packages from '../pages/packages/Packages';
import AddUpdatePackages from '../pages/packages/AddUpdatePackages';
import VerifyEmail from '../pages/auth/VerifyEmail';
import Administrator from '../pages/administrator/Administrator';
import AddUpdateAdministrator from '../pages/administrator/AddUpdateAdministrator';
import Designation from '../pages/designation/Designation';
import ProfileSettings from '../pages/settings/ProfileSettings';
import Stammdaten from '../pages/stammdaten/Stammdaten';
import WarehouseManagement from '../pages/warehouse/WarehouseManagement';
import AddUdateWarehouse from '../pages/warehouse/AddUdateWarehouse';
import Lagar from '../pages/lagar-management/Lagar';
import TimeTracking from '../pages/time-tracking';
import City from '../pages/city/City';

import dashboardIcon from '../assets/images/dashboard.svg';
import userManagementIcon from '../assets/images/user-management.svg';
import administratorManagementIcon from '../assets/images/administrator-management.svg';
import packageIcon from '../assets/images/packages.svg';
import stammdatenIcon from '../assets/images/stammdaten.svg';
import whIcon from '../assets/images/WhIcon.svg';
import timeTrackingIcon from '../assets/images/timeTrackingIcon.svg'
import profileSettingsIcon from '../assets/images/profile-settings.svg';
import constructionDiaryIcon from '../assets/images/constructionDiaryIcon.svg';
import vacationManagementIcon from '../assets/images/vacationManagementIcon.svg';
import utilityIcon from '../assets/images/utilityIcon.svg';
import ConstructionDiary from '../pages/construction-diary/ConstructionDiary';
import AddConstructionDiary from '../pages/construction-diary/AddConstructionDiary';
import AddUpdateNewVacation from '../pages/settings/AddUpdateNewVacation';
import VacationManagement from '../pages/vacation-management/Index';
import WorkHistory from '../pages/settings/WorkHistory';
import AddNewVacation from '../pages/settings/AddNewVacation';
import GeneralInformation from '../pages/stammdaten/GeneralInformation';
import Project from '../pages/stammdaten/Project';
import Documents from '../pages/stammdaten/Documents';
import CompanyDetails from '../pages/settings/CompanyDetails';
import InventoryManagement from '../pages/inventory-management/InventoryManagement';
import EquipmentManagement from '../pages/equipment-management/EquipmentManagement';
import EquipmentHistory from '../pages/equipment-management/EquipmentHistory';

export const privateRoutes = [
    {
        to: '/dashboard',
        element: <p>Dashboard</p>,
        children: [],
        label: <Link to='/dashboard'>Dashboard</Link>,
        germanLabel: <Link to='/dashboard'>Armaturenbrett</Link>,
        key: '1',
        menu: 'Dashboard',
        icon: <Image src={dashboardIcon} preview={false} />,
    },
    {
        to: '/user',
        label: 'User Mgnt.',
        germanLabel: 'Benutzerverwaltung',
        key: '2',
        menu: 'User Mgnt.',
        icon: <Image src={userManagementIcon} preview={false} />,
        children: [
            {
                to: '',
                element: <User />,
                label: <Link to='/user'>Users</Link>,
                germanLabel: <Link to='/user'>Benutzer</Link>,
                menu: 'Users',
                key: '21'
            },
            {
                to: 'add',
                element: <AddUpdateUser />,
                label: false,
                menu: 'Users',
            },
            {
                to: 'edit/:id',
                element: <AddUpdateUser />,
                label: false,
                menu: 'Users',
            },
            {
                to: 'type',
                label: <Link to='/user/type'>User Type</Link>,
                germanLabel: <Link to='/user/type'>Benutzertyp</Link>,
                menu: 'User Types',
                key: '22',
                element: <UserType />
            },
            {
                to: 'type/add',
                label: false,
                menu: 'User Types',
                element: <UpdateUserType />
            },
            {
                to: 'type/edit/:id',
                label: false,
                menu: 'User Types',
                element: <UpdateUserType />
            },
            {
                to: 'designation',
                label: <Link to='/user/designation'>Designation</Link>,
                germanLabel: <Link to='/user/designation'>Bezeichnung</Link>,
                menu: 'Designations',
                key: '23',
                element: <Designation />,
            },
        ],
    },
    {
        to: '/client',
        element: <Administrator />,
        children: [],
        label: <Link to='/client'>Client</Link>,
        germanLabel: <Link to='/client'>Kunde</Link>,
        key: '3',
        menu: 'Client',
        icon: <Image src={administratorManagementIcon} preview={false} />
    },
    {
        to: '/client/add',
        element: <AddUpdateAdministrator />,
        label: false,
        menu: 'Client',
        children: [],
    },
    {
        to: '/client/edit/:id',
        element: <AddUpdateAdministrator />,
        label: false,
        menu: 'Client',
        children: [],
    },
    {
        to: '/packages',
        element: <Packages />,
        children: [],
        label: <Link to='/packages'>Packages</Link>,
        germanLabel: <Link to='/packages'>Pakete</Link>,
        key: '4',
        menu: 'Packages',
        icon: <Image src={packageIcon} preview={false} />
    },
    {
        to: '/packages/add',
        element: <AddUpdatePackages />,
        label: false,
        menu: 'Packages',
        children: [],
    },
    {
        to: '/packages/edit/:id',
        label: false,
        menu: 'Packages',
        element: <AddUpdatePackages />,
        children: [],
    },
    {
        to: '/stammdaten',
        label: 'Stammdaten',
        germanLabel: 'Stammdaten',
        key: '5',
        menu: 'Stammdaten',
        icon: <Image src={stammdatenIcon} preview={false} />,
        children: [
            {
                to: '',
                element: <Stammdaten />,
                label: <Link to='/stammdaten'>Customer</Link>,
                germanLabel: <Link to='/stammdaten'>Kunde</Link>,
                menu: 'User',
                key: '51'
            },
            {
                to: 'add',
                element: <GeneralInformation />,
                label: false,
                menu: 'User',
            },
            {
                to: 'edit/:id',
                element: <GeneralInformation />,
                label: false,
                menu: 'User',
            },
            {
                to: 'project',
                element: <Project />,
                label: <Link to='/stammdaten/project'>Project</Link>,
                germanLabel: <Link to='/stammdaten/project'>Projekt</Link>,
                menu: 'Projects',
                key: '52'
            },
            {
                to: 'document',
                element: <Documents />,
                label: <Link to='/stammdaten/document'>Document</Link>,
                germanLabel: <Link to='/stammdaten/document'>Dokumentieren</Link>,
                menu: 'Documents',
                key: '53'
            },
        ],
    }, ,
    {
        to: 'warehouse-management',
        label: 'Warehouse Mgmt.',
        germanLabel: 'Lagerverwaltung',
        key: '6',
        menu: 'Warehouse Mgnt.',
        icon: <Image src={whIcon} preview={false} />,
        children: [
            {
                to: 'lager',
                element: <Lagar />,
                label: <Link to='/warehouse-management/lager'>Lager</Link>,
                germanLabel: <Link to='/warehouse-management/lager'>Lagerbier</Link>,
                menu: 'Lager',
                key: '61'
            },
            {
                to: 'wh-place',
                element: <WarehouseManagement />,
                label: <Link to='/warehouse-management/wh-place'>WH Place Mgmt.</Link>,
                germanLabel: <Link to='/warehouse-management/wh-place'>Platzverwaltung</Link>,
                menu: 'WH Place Management',
                key: '62'
            },
            {
                to: 'wh-place/add',
                element: <AddUdateWarehouse />,
                label: false,
                menu: 'WH Place Management',
            },
            {
                to: 'wh-place/edit/:id',
                element: <AddUdateWarehouse />,
                label: false,
                menu: 'WH Place Management',
            },
            {
                to: "inventory-management",
                element: <InventoryManagement />,
                label: <Link to='/warehouse-management/inventory-management'>Inventory Mgmt.</Link>,
                germanLabel: <Link to='/warehouse-management/inventory-management'>Bestandsverwaltung</Link>,
                menu: "Inventory Mgmt.",
                key: '63'
            },
            {
                to: 'equipment-list',
                element: <EquipmentManagement />,
                label: <Link to='/warehouse-management/equipment-list'>Equipment List</Link>,
                germanLabel: <Link to='/warehouse-management/equipment-list'>Ausrüstungsliste</Link>,
                menu: 'Equipment list',
                key: '64'
            },
            {
                to: 'equipment-history',
                element: <EquipmentHistory />,
                label: <Link to='/warehouse-management/equipment-history'>Equipment History</Link>,
                germanLabel: <Link to='/warehouse-management/equipment-history'>Ausrüstungsgeschichte</Link>,
                menu: 'Equipment History',
                key: '65'
            },
        ]
    },
    {
        to: '/time-tracking',
        element: <TimeTracking />,
        children: [],
        label: <Link to='/time-tracking'>Time Tracking Mgmt.</Link>,
        germanLabel: <Link to='/time-tracking'>Zeiterfassung</Link>,
        key: '7',
        menu: 'Time Tracking',
        icon: <Image src={timeTrackingIcon} preview={false} />
    },
    {
        to: '/construction-diary',
        element: <ConstructionDiary />,
        children: [],
        label: <Link to='/construction-diary'>Construction Diary</Link>,
        germanLabel: <Link to='/construction-diary'>Bautagebuch</Link>,
        key: '9',
        menu: 'Construction Diary',
        icon: <Image src={constructionDiaryIcon} preview={false} />
    },
    {
        to: '/construction-diary/add',
        element: <AddConstructionDiary />,
        children: [],
        label: false,
        menu: 'Construction Diary',
    },
    {
        to: '/vacation-management',
        element: <VacationManagement />,
        children: [],
        label: <Link to='/vacation-management'>Vacation Mgmt.</Link>,
        germanLabel: <Link to='/vacation-management'>Urlaubsmanagement</Link>,
        key: '10',
        menu: 'Vacation Mgnt.',
        icon: <Image src={vacationManagementIcon} preview={false} />
    },
    {
        to: '/utility',
        label: 'Utility',
        germanLabel: 'Dienstprogramm',
        key: '11',
        menu: 'Utility',
        icon: <Image src={utilityIcon} preview={false} />,
        children: [
            {
                to: 'city',
                element: <City />,
                label: <Link to='/utility/city'>City</Link>,
                germanLabel: <Link to='/utility/city'>Stadt</Link>,
                menu: 'City',
                key: '91'
            },
        ],
    },
    {
        to: '/settings',
        label: "Settings",
        germanLabel: "Einstellungen",
        key: '12',
        menu: "Settings",
        icon: <Image src={profileSettingsIcon} preview={false} />,
        children: [
            {
                to: 'general',
                element: <ProfileSettings />,
                label: <Link to='/settings/general'>General Setting</Link>,
                germanLabel: <Link to='/settings/general'>Allgemeine Einstellung</Link>,
                menu: 'General Setting',
                key: '92'
            },
            {
                to: 'add-new-Vacation',
                element: <AddNewVacation />,
                label: <Link to='/settings/add-new-vacation'>Add New Vacation</Link>,
                germanLabel: <Link to='/settings/add-new-vacation'>Neuen Urlaub hinzufügen</Link>,
                menu: 'Add Vacation',
                key: '93',
            },
            {
                to: 'add-new-Vacation/add',
                element: <AddUpdateNewVacation />,
                label: false,
                menu: 'Add Vacation',
            },
            {
                to: 'add-new-Vacation/edit/:id',
                element: <AddUpdateNewVacation />,
                label: false,
                menu: 'Add Vacation',
            },
            {
                to: 'work-history',
                element: <WorkHistory />,
                label: <Link to='/settings/work-history'>Work History</Link>,
                germanLabel: <Link to='/settings/work-history'>Arbeitsgeschichte</Link>,
                menu: 'Work History',
                key: '94'
            },
            {
                to: 'company-details',
                element: <CompanyDetails />,
                label: <Link to={'/settings/company-details'}>Company Details</Link>,
                germanLabel: <Link to={'/settings/company-details'}>Firmendetails</Link>,
                menu: 'Company Detail',
                key: '95'
            }
        ],
    },
];

export const publicRoutes = [
    {
        to: 'user/authentication/sign-in',
        element: <SignIn />
    },
    {
        to: 'user/authentication/forgot-password',
        element: <ForgotPassword />
    },
    {
        to: 'user/authentication/verify-otp/:id',
        element: <VerifyOTP />
    },
    {
        to: 'user/authentication/reset-password/:id/:token',
        element: <ResetPassword />
    },
    {
        to: 'user/authentication/check-email',
        element: <CheckMail />
    },
    {
        to: 'user/authentication/set-password',
        element: <SetPassword />
    },
    {
        to: 'user/welcome',
        element: <WelcomePage />
    },
    {
        to: 'user/authentication/verify-email/:id/:token',
        element: <VerifyEmail />
    }
];