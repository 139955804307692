import { Document, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import NunitoRegular from '../../assets/fonts/Nunito/Nunito-Regular.ttf';
import dayjs from "dayjs";

Font.register({
    family: 'Nunito',
    fonts: [
        {
            src: NunitoRegular,
            fontWeight: 'normal',
        },
    ],
});

const GenerateVacationPDF = ({ data }) => {

    const styles = StyleSheet.create({
        page: {
            fontFamily: "Nunito",
            padding: 20,
            fontSize: 10,
        },
        header: {
            fontSize: 14,
            fontWeight: "bold",
            marginBottom: 10,
        },
        row: {
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#E0E0E0",
            padding: 4,
        },
        headerRow: {
            backgroundColor: "#E5F1EC",
            borderBottomWidth: 1,
            borderColor: "#E0E0E0",
            fontWeight: "bold",
        },
        column: {
            flex: 1,
            textAlign: "center",
            padding: "8px 0"
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Text style={styles.header}>Leave Report</Text>
                    <View style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
                        <Text style={styles.header}>User: {data[0]?.user}</Text>
                        <Text style={styles.header}>Total Leaves: {data.length}</Text>
                    </View>
                </View>

                {/* Table Header */}
                <View style={[styles.row, styles.headerRow]} minPresenceAhead={25}>
                    <Text style={styles.column}>Leave Date Range</Text>
                    <Text style={styles.column}>Leave Type</Text>
                    <Text style={styles.column}>Total Leave Days</Text>
                    <Text style={styles.column}>Leave Reason</Text>
                    <Text style={styles.column}>Status</Text>
                </View>

                {/* Table Rows */}
                {
                    data?.map((item, index) => (
                        <View key={index} style={styles.row} minPresenceAhead={25}>
                            <Text style={styles.column}>
                                {
                                    item?.leaveDates?.length !== 0 ?
                                        item?.leaveDates?.map(date => dayjs(date).format('DD.MM.YY')).join(', ')
                                        :
                                        `${dayjs(item?.leaveDateRange?.startDate).format('DD.MM.YY')} to ${dayjs(item?.leaveDateRange?.endDate).format('DD.MM.YY')}`
                                }
                            </Text>
                            <Text style={styles.column}>{item.leaveType?.charAt(0)?.toUpperCase() + item?.leaveType?.slice(1) || '-'}</Text>
                            <Text style={styles.column}>{item.leaveDays || '-'}</Text>
                            <Text style={styles.column}>{item.leaveReason || "-"}</Text>
                            <Text style={styles.column}>{item.status?.charAt(0)?.toUpperCase() + item?.status?.slice(1) || '-'}</Text>
                        </View>
                    ))
                }
            </Page >
        </Document >
    );
};

export default GenerateVacationPDF;
