import React, { Fragment, useEffect, useRef, useState } from 'react';

import { Button, Divider, Flex, Typography, Row, Col, Form, Input, Select, Card, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';

import { createWareHouse, getByIdWareHouse, getWareHouse, setIsSuccess, setIsUpdating, updateWareHouse } from './redux/slice';
import { getCityListing, getLagarListing } from '../../redux/slice/global';

import BackIcon from '../../components/icon/BackIcon';
import PlusIcon from '../../components/icon/PlusIcon';
import EditIcon from '../../components/icon/EditIcon';
import CloseIcon from '../../components/icon/CloseIcon';

import { black } from '../../utils/theme/config';
import Barcode from 'react-barcode';
import InfoIcon from '../../components/icon/InfoIcon';
import UploadIcon from '../../components/icon/UploadIcon';
import html2canvas from 'html2canvas';
import openNotification from '../../components/notification';

const { Title, Text } = Typography;

const AddUdateWarehouse = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { wareHouse, auth, listing } = useSelector(state => state);
    const [isRackUpdate, setIsRackUpdate] = useState(false);
    const [modalType, setModalType] = useState(false);
    const [loginUser, setLoginUser] = useState({});
    const [lagarOptions, setLagarOptions] = useState([]);
    const [reckNumber, setReckNumber] = useState('')
    const [self, setSelf] = useState('')
    const [selfNumbers, setSelfNumbers] = useState([])
    const [editSelfIndex, setEditSelfIndex] = useState(null)
    const [cityListing, setCityListing] = useState([]);
    const [barcodeData, setBarcodeData] = useState({});
    const [isDownload, setIsDownload] = useState(true);
    const { t } = useTranslation();
    const barcodeRef = useRef({});

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
        }

        dispatch(getCityListing())
    }, [])

    const handleOnFinish = (values) => {
        delete values?.self;
        if (id) {
            dispatch(setIsUpdating(false));
            dispatch(getWareHouse({
                ...values,
                rackSelfNumbers: selfNumbers?.map((item) => ({
                    rackNumber: values?.rackNumber,
                    selfNumber: item?.selfNumber ? item?.selfNumber : item,
                    rackSelfString: id ? `${values?.rackNumber} - ${item?.selfNumber ? item?.selfNumber : item}` : `${values?.rackNumber} - ${item}`,
                    ...(item?._id) && { _id: item?._id }
                })),
                organizer: auth?.decodeToken?.organizer
            }));
        }
        else {
            dispatch(getWareHouse({
                ...values,
                rackSelfNumbers: selfNumbers?.map((item) => ({
                    rackNumber: values?.rackNumber,
                    selfNumber: item?.selfNumber ? item?.selfNumber : item,
                    rackSelfString: id ? `${values?.rackNumber} - ${item?.selfNumber ? item?.selfNumber : item}` : `${values?.rackNumber} - ${item}`
                })),
                organizer: auth?.decodeToken?.organizer
            }));
        }

    };

    // Get Lagar Lists
    useEffect(() => {
        if (loginUser?.organizer) {
            dispatch(getLagarListing(loginUser?.organizer));
        }
    }, [loginUser])

    useEffect(() => {
        if (listing.listing?.lagar?.length > 0) {
            setLagarOptions(listing.listing?.lagar);
        }
        setCityListing(listing?.listing?.city)
    }, [listing.listing?.lagar, listing?.listing?.city, loginUser])

    // Get Edit WareHouse Data
    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(setIsUpdating(true));
            dispatch(getByIdWareHouse(id));
        }
    }, [id]);

    // Create & Update Warehouse
    useEffect(() => {
        if (Object.keys(wareHouse.wareHouse).length > 0) {
            if (!modalType) {
                dispatch(createWareHouse({ wareHouse: wareHouse.wareHouse, organizer: auth?.decodeToken?.organizer }));
            } else {
                if (wareHouse.isUpdating) {
                    setSelfNumbers(wareHouse?.wareHouse?.rackSelfNumbers);
                    setReckNumber(wareHouse?.wareHouse?.rackNumber);
                    setBarcodeData({ lager: wareHouse?.wareHouse?.lager, city: wareHouse?.wareHouse?.city });
                    form.setFieldsValue({ ...wareHouse.wareHouse, rackNumber: wareHouse?.wareHouse?.rackNumber, self: '' });
                } else {
                    dispatch(updateWareHouse({ wareHouse: { ...wareHouse?.wareHouse, id: id }, organizer: auth?.decodeToken?.organizer }));
                }
            }
        }
        if (wareHouse.isSuccess) {
            handleClear();
            setSelf('');
            setReckNumber('');
            setSelfNumbers([]);
            setEditSelfIndex(null);
            setModalType(false);
            navigate('/warehouse-management/wh-place');
            dispatch(setIsSuccess(false));
        }
    }, [wareHouse.isUpdating, wareHouse.wareHouse, wareHouse.isSuccess]);

    const handleClear = () => {
        navigate(-1);
        form.resetFields();
    }

    // Toggle Add & Update Self number 
    const handleEditRackNumber = (item, index) => {
        setIsRackUpdate(true);
        setEditSelfIndex(index);
        setSelf((typeof item === 'object' && Object.keys(item)?.length > 0) ? item?.selfNumber : item)
        form.setFieldsValue({
            'self': (typeof item === 'object' && Object.keys(item)?.length > 0) ? item?.selfNumber : item,
        });
    }

    const handleAddSelfNumber = () => {
        if (self === '') {
            return;
        }
        else if (self !== '' && selfNumbers?.find((item, index) => (item?.selfNumber ? item?.selfNumber === self : item === self) && index !== editSelfIndex)) {
            form.setFields([
                {
                    name: 'self',
                    errors: [t('organizer_common_selferror')],
                },
            ])
        } else {
            if (selfNumbers?.find((item, index) => index === editSelfIndex)) {
                setSelfNumbers(selfNumbers?.map((i, key) => {
                    if (typeof i === 'object') {
                        return {
                            ...i,
                            selfNumber: key === editSelfIndex ? self : i?.selfNumber,
                            rackSelfString: key === editSelfIndex ? `${reckNumber} - ${self}` : i?.rackSelfString,
                        }
                    }
                    else {
                        return key === editSelfIndex ? self : i
                    }
                }))
            } else {
                setSelfNumbers([...selfNumbers, self]);
            }
            setSelf('');
            setEditSelfIndex(null)
            setIsRackUpdate(false);
            form.resetFields(['self']);
        }
    }

    const downloadQRCode = (barcodeId) => {
        try {
            const ref = barcodeRef.current[barcodeId];
            // Use html2canvas to capture the QR code
            html2canvas(ref).then((canvas) => {
                const dataUrl = canvas.toDataURL(); // Get the data URL of the image
                const link = document.createElement('a'); // Create a temporary link element
                link.href = dataUrl; // Set the href to the data URL
                link.download = 'Barcode.png'; // Set the download filename
                link.click(); // Trigger the download
            }).catch((error) => {
                // Handle error if html2canvas fails to render the QR code
                openNotification({ message: t('organizer_common_downloaderror'), type: 'error' });
            });
        } catch (error) {
            openNotification({ message: t('organizer_common_downloaderror'), type: 'error' });
        }
    };

    return (
        <Fragment>
            <Flex vertical gap={24}>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => navigate(-1)}
                    />
                </Flex>
                <Flex vertical gap={8} className='bg-white padding-2'>
                    <Flex>
                        <Title level={4} type='success'>{id ? t('organizer_warehousemanagement_lager_editwarehouse') : t('organizer_warehousemanagement_lager_addwarehouse')}</Title>
                    </Flex>
                    <Divider />
                    <Form
                        form={form}
                        name='warehouse'
                        colon={true}
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        layout='vertical'
                        onFinish={handleOnFinish}
                    >
                        <Row gutter={18}>
                            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='city'
                                    label={t('organizer_common_city')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('organizer_common_cityerror'),
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={isRackUpdate}
                                        showSearch
                                        allowClear
                                        placeholder={t('organizer_common_city')}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        onChange={(e) => setBarcodeData({ ...barcodeData, city: e })}
                                        options={cityListing}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='lager'
                                    label={t('organizer_common_lagername')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('organizer_common_lagernameerror'),
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={isRackUpdate}
                                        showSearch
                                        allowClear
                                        onChange={(e) => setBarcodeData({ ...barcodeData, lager: e })}
                                        placeholder={t('organizer_common_lagername')}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={lagarOptions}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='rackNumber'
                                    label={t('organizer_common_racknumber')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('organizer_common_racknumbererror'),
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            setReckNumber(e.target.value)
                                        }}
                                        disabled={isRackUpdate}
                                        placeholder={t('organizer_common_racknumber')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='self'
                                    label={t('organizer_common_selfnumber')}
                                >
                                    <Input
                                        onChange={(e) => {
                                            setSelf(e.target.value);
                                        }}
                                        placeholder={t('organizer_common_selfnumber')}
                                    />
                                </Form.Item>
                            </Col>
                            {
                                isRackUpdate ?
                                    <Col xxl={4} xl={5} lg={24} md={24} sm={24} xs={24} >
                                        <Flex align='center' justify='end' gap={10} wrap={true} className='h-100'>
                                            <Button danger type='primary' htmlType='button' onClick={() => {
                                                setIsRackUpdate(false)
                                                setSelf('');
                                                setEditSelfIndex(null)
                                                setIsRackUpdate(false);
                                                form.resetFields(['self']);
                                            }}>
                                                {t('organizer_common_cancelButton')}
                                            </Button>
                                            <Button type='primary' loading={wareHouse?.loading} onClick={() => handleAddSelfNumber()}>{t('organizer_common_updateButton')}</Button>
                                        </Flex>
                                    </Col>
                                    :
                                    <Col xxl={1} xl={1} lg={24} md={24} sm={24} xs={24} >
                                        <Flex justify='end' align='center' className='h-100'>
                                            <PlusIcon
                                                role='button'
                                                color={black}
                                                size={24}
                                                onClick={() => handleAddSelfNumber()}
                                            />
                                        </Flex>
                                    </Col>
                            }
                        </Row>
                        <Row gutter={[24, 24]} className='padding-top-2'>
                            {
                                id && selfNumbers?.map((item, index) => {
                                    return <Col xxl={6} xl={8} lg={12} md={24} sm={24} xs={24} key={index}>
                                        <Card
                                            title={null}
                                            extra={<Flex>
                                                {
                                                    !item?._id &&
                                                    <Tooltip placement='top' title={t('organizer_common_barcodeerror')}><Button type='text' className='padding-1'><InfoIcon size={26} /></Button></Tooltip>
                                                }

                                                <Button
                                                    type='text'
                                                    className='padding-1'
                                                    onClick={() => downloadQRCode(item?._id)}
                                                >
                                                    <UploadIcon size={24} style={{
                                                        transform: 'rotate(180deg)',
                                                    }}
                                                        role='button'
                                                    />
                                                </Button>
                                                <Button
                                                    type='text'
                                                    className='padding-1'
                                                    onClick={() => handleEditRackNumber(item, index)}
                                                >
                                                    <EditIcon role='button' />
                                                </Button>
                                                <Button
                                                    type='text'
                                                    className='padding-1'
                                                    onClick={() => { setSelfNumbers(selfNumbers?.filter((_, i) => i !== index)); form.resetFields(['self']) }}
                                                ><CloseIcon role='button' color={black} /></Button>
                                            </Flex>
                                            }
                                            className='bg-primary-light '
                                        >
                                            <Flex justify='center' align='center' className='padding-bottom-3' vertical>
                                                <Title level={4} className='padding-bottom-3'>
                                                    {(id && typeof item === 'object' && Object.keys(item)?.length > 0)
                                                        ? item?.rackSelfString : `${reckNumber} - ${item}`}
                                                </Title>
                                                {
                                                    (id && item?._id) && <div>
                                                        <Barcode
                                                            width={1}
                                                            background='none'
                                                            fontSize={16}
                                                            height={40}
                                                            value={item?._id}
                                                            displayValue={true}
                                                            textMargin={10}
                                                        />
                                                    </div>
                                                }
                                                {/* For Barcode Printing */}
                                                {
                                                    (id && item?._id) && <div ref={(el) => (barcodeRef.current[item?._id] = el)} style={{ position: 'absolute', top: '-9999px' }}>
                                                        <Barcode
                                                            width={1}
                                                            background='none'
                                                            fontSize={16}
                                                            height={40}
                                                            value={item?._id}
                                                            displayValue={false}
                                                            textMargin={10}
                                                        />
                                                    </div>
                                                }
                                            </Flex>
                                        </Card>
                                    </Col>
                                })
                            }
                            {
                                !id &&
                                selfNumbers?.map((item, index) => {
                                    return <Col xxl={6} xl={8} lg={12} md={24} sm={24} xs={24} key={item}>
                                        <Card
                                            title={null}
                                            extra={<Flex>
                                                {
                                                    !item?._id &&
                                                    <Tooltip placement='top' title={t('organizer_common_barcodeerror')}><Button type='text' className='padding-1'><InfoIcon size={26} /></Button></Tooltip>
                                                }
                                                <Button
                                                    type='text'
                                                    className='padding-1'
                                                    onClick={() => handleEditRackNumber(item, index)}
                                                >
                                                    <EditIcon role='button' />
                                                </Button>
                                                <Button
                                                    type='text'
                                                    className='padding-1'
                                                    onClick={() => { setSelfNumbers(selfNumbers?.filter((_, i) => i !== index)); form.resetFields(['self']) }}
                                                ><CloseIcon role='button' color={black} /></Button>
                                            </Flex>
                                            }
                                            className='bg-primary-light '
                                        >
                                            <Flex justify='center' align='center' className='padding-bottom-3' vertical>
                                                <Title level={4} className='padding-bottom-1'>
                                                    {
                                                        typeof item === 'object' ? item?.rackSelfString : `${reckNumber} - ${item}`
                                                    }
                                                </Title>
                                            </Flex>
                                        </Card>
                                    </Col>
                                })
                            }
                        </Row>
                        <Flex justify='flex-end' gap={10} wrap={true} className='padding-top-3'>
                            <Button danger type='primary' htmlType='button' onClick={handleClear} style={{ width: '10.125rem' }}> {t('organizer_common_cancelButton')}</Button>
                            <Button type='primary' htmlType='submit' loading={wareHouse?.loading} style={{ width: '10.125rem' }}> {t('organizer_common_submitButton')}</Button>
                        </Flex>
                    </Form>
                </Flex>
            </Flex >
        </Fragment >
    );
};

export default AddUdateWarehouse;
