import React, { Fragment, useEffect, useState } from 'react';

import { Button, Flex, Form, Row, Col, Input, Typography, Select, Divider, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getPackagesListing } from '../../redux/slice/global';
import { createClient, getByIdClient, getClient, updateClient, setIsSuccess, setIsUpdating } from './redux/slice';

import BackIcon from '../../components/icon/BackIcon';

import '../../styles/pages/profile-settings.scss';

const { Title, Text } = Typography;

const AddUpdateClient = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { id } = useParams();
    const { client, listing, auth } = useSelector(state => state);
    const [modalType, setModalType] = useState(false);
    const [packagesList, setPackagesList] = useState([]);
    const { t } = useTranslation();

    // Get User Types
    useEffect(() => {
        getPackageLists();
    }, [])

    const getPackageLists = () => {
        dispatch(getPackagesListing());
    };

    useEffect(() => {
        if (listing.listing?.packages?.length > 0) {
            setPackagesList(listing.listing.packages);
        }
    }, [listing.listing.packages]);

    const handleOnFinish = (values) => {
        if (id) {
            dispatch(setIsUpdating(false));
        }
        dispatch(getClient({
            ...values,
            isImmutable: Object.keys(client?.client)?.length > 0 ? client?.client?.isImmutable : false,
            organizer: Object.keys(client?.client)?.length > 0 ? client?.client?.organizer : null,
            designation: Object.keys(client?.client)?.length > 0 ? client?.client?.designationId : null,
            employeeType: Object.keys(client?.client)?.length > 0 ? client?.client?.employeeType : '',
            workType: Object.keys(client?.client)?.length > 0 ? client?.client?.workType : '',
            holiday: Object.keys(client?.client)?.length > 0 ? client?.client?.holiday : 0,
            weeklyWorkHours: Object.keys(client?.client)?.length > 0 ? client?.client?.weeklyWorkHours : 0,
            userLimit: Number(values?.userLimit),
            address: { streetNo: values?.streetNo, city: values?.city, zip: values?.zip }
        }));
    };

    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(setIsUpdating(true));
            dispatch(getByIdClient(id));
        }
    }, [id]);

    useEffect(() => {
        if (Object.keys(client.client).length > 0) {
            if (!modalType) {
                dispatch(createClient(client.client));
            } else {
                if (client.isUpdating) {
                    form.setFieldsValue({ ...client.client, streetNo: client?.client?.address?.streetNo, city: client?.client?.address?.city, zip: client?.client?.address?.zip });
                } else {
                    dispatch(updateClient({ ...client.client, id: id }));
                }
            }
        }
        if (client.isSuccess) {
            handleClear();
            navigate('/client');
            setModalType(false);
            dispatch(setIsSuccess(false));
        }
    }, [client.isUpdating, client.client, client.isSuccess]);

    // Clear from 
    const handleClear = () => {
        form.resetFields();
        dispatch(getClient({}));
    };

    return (
        <Fragment>
            <Flex vertical gap={24} className='general-settings'>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => navigate('/client')}
                    />
                </Flex>
                <Flex vertical gap={8} className='bg-white padding-2 rounded-1'>
                    <Flex>
                        <Title level={4} type='success'>{id ? t('superadmin_clientmanagement_editClient') : t('superadmin_clientmanagement_addClient')}</Title>
                    </Flex>
                    <Divider />
                    <Form
                        form={form}
                        name='client'
                        colon={true}
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        layout='vertical'
                        onFinish={handleOnFinish}
                    >
                        <Row gutter={16}>
                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Row gutter={24}>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24} >
                                        <Form.Item
                                            name='company'
                                            label={t('superadmin_common_companyname')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('superadmin_common_companynameerror')
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder={t('superadmin_common_companyname')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='firstName'
                                            label={t('superadmin_common_firstname')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('superadmin_common_firstnameerror')
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder={t('superadmin_common_firstname')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='lastName'
                                            label={t('superadmin_common_lastname')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('superadmin_common_lastnameerror')
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder={t('superadmin_common_lastname')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='phone'
                                            label={t('superadmin_common_phoneNo')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('superadmin_common_phoneNoerror')
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder={t('superadmin_common_phoneNo')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='email'
                                            label={t('superadmin_common_email')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('superadmin_common_emailerror')
                                                },
                                                {
                                                    type: 'email',
                                                    message: t('superadmin_common_emailinvaliderror')
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder={t('superadmin_common_email')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='package'
                                            label={t('superadmin_common_package')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('superadmin_common_packageerror'),
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder={t('superadmin_common_package')}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={packagesList?.length > 0 && packagesList}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='userLimit'
                                            label={t('superadmin_common_userlimit')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('superadmin_common_userlimiterror')
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                className='w-100'
                                                min={1}
                                                placeholder={t('superadmin_common_userlimit')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {
                                        auth?.decodeToken?.organizer
                                        && <Fragment>
                                            <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    name='userDesignation'
                                                    label={t('superadmin_common_designation')}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('superadmin_common_designationerror')
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        placeholder={t('superadmin_common_designation')}
                                                        options={[]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    name='userType'
                                                    label={t('superadmin_common_usertype')}
                                                >
                                                    <Select
                                                        disabled
                                                        showSearch
                                                        allowClear
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        placeholder={t('superadmin_common_usertype')}
                                                        options={[]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    name='employeeType'
                                                    label={t('superadmin_common_employeetype')}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('superadmin_common_employesstypeerror')
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        placeholder={t('superadmin_common_employeetype')}
                                                        options={[]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item
                                                    name='holidatAllowance'
                                                    label={t('superadmin_common_holliday')}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('superadmin_common_holidayerror')
                                                        }
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder={t('superadmin_common_holliday')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Fragment>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Title level={5} className='padding-bottom-2'>{t('superadmin_common_address')}</Title>
                        <Row gutter={24}>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='streetNo'
                                    label={t('superadmin_common_streetName')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('superadmin_common_streetnameerror')
                                        }
                                    ]}
                                >
                                    <Input
                                        showCount={false}
                                        placeholder={t('superadmin_common_streetName')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='city'
                                    label={t('superadmin_common_city')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('superadmin_common_cityerror')
                                        }
                                    ]}
                                >
                                    <Input
                                        placeholder={t('superadmin_common_city')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='zip'
                                    label={t('superadmin_common_zip')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('superadmin_common_ziperror')
                                        }
                                    ]}
                                >
                                    <Input
                                        placeholder={t('superadmin_common_zip')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            auth?.decodeToken?.organizer
                            && <Fragment>
                                <Title level={5} className='padding-bottom-2'>{t('superadmin_common_shiftmanagement')}</Title>
                                <Row gutter={24}>
                                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='workType'
                                            label={t('superadmin_common_worktype')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('superadmin_common_worktypeerror')
                                                }
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                }
                                                placeholder={t('superadmin_common_worktype')}
                                                options={[]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='totalWeeklyHours'
                                            label={t('superadmin_common_weeklyhours')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('superadmin_common_weeklyhourserror')
                                                }
                                            ]}
                                        >
                                            <Input
                                                placeholder={t('superadmin_common_weeklyhours')}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Fragment>
                        }
                        <Flex justify='flex-end' gap={10} wrap={true}>
                            <Button danger type='primary' style={{ width: '100%', maxWidth: '7.5rem' }} htmlType='button' onClick={() => navigate(-1)}>{t('superadmin_common_cancelButton')}</Button>
                            <Button type='primary' htmlType='submit' loading={client?.loading}>{id ? t('organizer_common_updateButton') : t('superadmin_clientmanagement_sendinvite')}</Button>
                        </Flex>
                    </Form>
                </Flex>
            </Flex>
        </Fragment>
    );
};

export default AddUpdateClient;
