import React, { Fragment } from 'react';

import { Flex, Typography, Table, Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { approveLeave, rejectLeave } from '../redux/slice';

const { Title, Text } = Typography;

const RequestLeave = ({ requestLeaveList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auth, vacation } = useSelector((state) => state);

  const columns = [
    {
      title: t('organizer_common_user'),
      dataIndex: 'user',
      key: 'user',
      width: '100px',
      render: (_, record) => <Flex align='center' gap={10}>
        <div>
          <span style={{ backgroundColor: `${record.leaveType === 'sick' ? '#FFCFC5' : '#B7E7AF'}`, padding: '0px 10px', borderRadius: '10px' }}></span>
        </div>
        {`${record?.firstName} ${record?.lastName}`}
      </Flex>,
    },
    {
      title: t('organizer_vacationmanagement_leave_from_date'),
      dataIndex: 'leaveDateRange',
      key: 'leaveDateRange',
      width: '200px',
      render: (_, record) => {
        return (
          <Flex gap={8} align='center'>
            {
              record?.leaveDates?.length !== 0 ?
                <Text>{record.leaveDates?.map(date => dayjs(date).format('DD.MM.YY')).join(', ')}</Text>
                :
                <Text>{`${dayjs(record?.leaveDateRange?.startDate).format('DD.MM.YY')} to ${dayjs(record?.leaveDateRange?.endDate).format('DD.MM.YY')}`}</Text>
            }
          </Flex>
        )
      },
    },
    {
      title: t('organizer_vacationmanagement_leave') + t('organizer_common_type'),
      dataIndex: 'leaveType',
      width: '100px',
      key: 'leaveType',
      render: (text) => <Text>{text?.charAt(0)?.toUpperCase() + text?.slice(1)}</Text>,
    },
    {
      title: t('organizer_common_total') + ' ' + t('organizer_vacationmanagement_leave') + t('organizer_common_days'),
      dataIndex: 'totalDays',
      key: 'totalDays',
      width: '200px',
      render: (text) => <Text>{text} {t('organizer_common_days')}</Text>,
    },
    {
      title: t('organizer_vacationmanagement_leave') + ' ' + t('organizer_common_reason'),
      dataIndex: 'leaveReason',
      key: 'leaveReason',
      width: '200px',
      render: (text) => <Text>{text || '-'}</Text>,

    },
  ];

  const hanldeDeclineLeave = (record) => {
    dispatch(rejectLeave({
      id: record?._id,
      organizer: auth?.decodeToken?.organizer
    }))
  }

  const handleApproveLeave = (record) => {
    dispatch(approveLeave({
      id: record?._id,
      organizer: auth?.decodeToken?.organizer
    }))
  }

  return (
    <Fragment>
      <Flex vertical gap={16}>
        <Flex vertical>
          <Title level={4} type='success' className='paddingX-2'>{t('organizer_common_all')} {t('organizer_vacationmanagement_leave')} {t('organizer_common_requests')}</Title>
          <Divider />
        </Flex>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <Fragment>
                <Flex vertical gap={16}>
                  {
                    record?.yearlyLeaves?.map((item, index) => {
                      return <Flex gap={15} justify='end' align='center'>
                        <Text className='rounded-1 padding-1 paddingX-3 bg-gray'>
                          {t('organizer_common_year')} - <Text type='success'>{item?.year}</Text>
                        </Text>
                        <Text className='rounded-1 padding-1 paddingX-3 bg-gray'>
                          {t('organizer_common_total') + ' ' + t('organizer_vacationmanagement_leave')} - <Text type='success'>{item?.totalLeaves}</Text>
                        </Text>
                        <Text className='rounded-1 padding-1 paddingX-3 bg-gray'>
                          {t('organizer_vacationmanagement_remainingleaves')} - <Text type='success'>{item?.remainingLeaves}</Text>
                        </Text>
                        {
                          index === record?.yearlyLeaves?.length - 1 ? (
                            <Fragment>
                              <Button danger type='primary' loading={vacation?.rejectLoading} onClick={() => hanldeDeclineLeave(record)}>{t('organizer_common_decline') + ' ' + t('organizer_vacationmanagement_leave')}</Button>
                              <Button type='primary' loading={vacation?.approveLoading} onClick={() => handleApproveLeave(record)}>{t('organizer_common_approve') + ' ' + t('organizer_vacationmanagement_leave')}</Button>
                            </Fragment>
                          )
                            :
                            (
                              <Fragment>
                                <Button style={{ visibility: 'hidden' }} danger type='primary' >{t('organizer_common_decline') + ' ' + t('organizer_vacationmanagement_leave')}</Button>
                                <Button style={{ visibility: 'hidden' }} type='primary' >{t('organizer_common_approve') + ' ' + t('organizer_vacationmanagement_leave')}</Button>
                              </Fragment>
                            )
                        }
                      </Flex>
                    })
                  }
                </Flex>
              </Fragment>
            ),
            rowExpandable: () => true,
            expandIcon: () => null,
          }}
          pagination={false}
          dataSource={requestLeaveList}
          scroll={{ x: 1000, y: null }}
          rowKey={'_id'}
          expandedRowKeys={requestLeaveList?.map((item) => item._id)}
          expandIconColumnIndex={-1}
        />
      </Flex>
    </Fragment>
  );
};

export default RequestLeave;
