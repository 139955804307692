import React, { Fragment, useEffect, useState } from 'react';

import { Col, Flex, Form, Row, Typography, Input, Upload, Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';

import openNotification from '../../components/notification';

import { getByIdClient, updateClient, setIsSuccess as clientIsSuccess } from '../administrator/redux/slice';
import { getDecodeToken, onOrganizerBoardring } from '../auth/redux/slice';
import { getDesignationListing } from '../../redux/slice/global';

import profileImage from '../../assets/images/avatar.jpg';

import '../../styles/pages/profile-settings.scss';

const { Title, Text } = Typography;

const GeneralSettings = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const { auth, client, listing } = useSelector(state => state);
    const [profile, setProfile] = useState({})
    const [loginUser, setLoginUser] = useState({});
    const [designationListing, setDesignationListing] = useState([]);
    const { t } = useTranslation();

    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = jwtDecode(token);
            dispatch(getDecodeToken(user))
            setLoginUser(user);
        }
    }, [])

    useEffect(() => {
        if (listing.listing?.designation?.length > 0) {
            setDesignationListing(listing.listing?.designation)
        }
    }, [listing.listing.designation]);

    useEffect(() => {
        loginUser?.organizer && dispatch(getDesignationListing(loginUser?.organizer));
        auth?.isAuthorize && (loginUser?.organizer && loginUser?.organizer !== loginUser?._id) &&
            openNotification({ message: t('organizer_setting_generalsettings_profileimageuploadmessage'), type: 'success' });
    }, [loginUser, auth])

    useEffect(() => {
        if (Object?.keys(auth.decodeToken)?.length > 0) {
            dispatch(getByIdClient(auth.decodeToken._id))
        }
    }, [auth.decodeToken])

    useEffect(() => {
        if (Object?.keys(auth.decodeToken)?.length > 0 && client.isSuccess) {
            dispatch(getByIdClient(auth.decodeToken._id))
            dispatch(onOrganizerBoardring(auth?.decodeToken._id))
            dispatch(clientIsSuccess(false))
            navigate('/dashboard');
        }
    }, [auth.decodeToken, client?.isSuccess])

    useEffect(() => {
        form.setFieldsValue({ ...client?.client, designation: client?.client?.designationId, streetNo: client?.client?.address?.streetNo, city: client?.client?.address?.city, zip: client?.client?.address?.zip });
        setFileList([{ url: client?.client?.profilePicture ? `${process.env.REACT_APP_API_URI}/profiles/${client.client?.profilePicture}` : profileImage }])
    }, [client.client])

    const handleOnFinish = (values) => {
        values.address = { streetNo: values?.streetNo, city: values?.city, zip: values?.zip }
        delete values?.city
        delete values?.streetNumber
        delete values?.zip
        delete values?.image
        const formData = new FormData();

        Object.keys(profile)?.length > 0 && formData.append('profilePicture', profile)
        formData.append('id', auth.decodeToken._id)
        Object.entries(values).forEach(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
                Object.entries(value).forEach(([subKey, subValue]) => {
                    formData.append(`${key}[${subKey}]`, subValue);
                });
            } else {
                formData.append(key, value);
            }
        });
        formData.append('accessToken', client.client?.accessToken);
        formData.append('isActive', client.client?.isActive);
        formData.append('isImmutable', client.client?.isImmutable);
        formData.append('isInitialSignIn', client.client?.isInitialSignIn);
        formData.append('isOrganizer', client.client?.isOrganizer);
        formData.append('language', client.client?.language);
        formData.append('organizer', client.client?.organizer);
        formData.append('package', client.client?.package);
        formData.append('userLimit', client.client?.userLimit);
        formData.append('userType', client.client?.userType);
        dispatch(updateClient(formData));
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList.slice(-1));
        setProfile(newFileList.slice(-1)[0]?.originFileObj);
    };

    return (
        <Fragment>
            <Flex className='paddingY-1 paddingX-2 general-settings bg-white' vertical gap={12}>
                <Title level={4}>{Object?.keys(auth?.decodeToken)?.length > 0 && auth?.decodeToken?.firstName} {t('organizer_common_profile')}</Title>
                <Form
                    form={form}
                    name='user'
                    colon={true}
                    requiredMark={(label, isRule) => {
                        return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                    }}
                    layout='vertical'
                    disabled={auth?.decodeToken?._id !== auth?.decodeToken?.organizer}
                    onFinish={handleOnFinish}
                >
                    <Row gutter={16}>
                        <Col xxl={18} xl={18} lg={{ span: 18, order: 1 }} md={{
                            span: 24,
                            order: 2
                        }} sm={24} xs={24} order={2}>
                            <Row gutter={24}>
                                {
                                    (auth?.decodeToken?._id === auth?.decodeToken?.organizer) &&
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24} >
                                        <Form.Item
                                            name='company'
                                            label={t('organizer_common_companyname')}
                                        >
                                            <Input
                                                placeholder={t('organizer_common_companyname')}
                                            />
                                        </Form.Item>
                                    </Col>
                                }
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='firstName'
                                        label={t('organizer_common_firstname')}
                                        rules={[
                                            {
                                                required: auth?.decodeToken?._id === auth?.decodeToken?.organizer,
                                                message: t('organizer_common_firstnameerror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_firstname')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='lastName'
                                        label={t('organizer_common_lastname')}
                                        rules={[
                                            {
                                                required: auth?.decodeToken?._id === auth?.decodeToken?.organizer,
                                                message: t('organizer_common_lastnameerror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_lastname')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='phone'
                                        label={t('organizer_common_phoneNo')}
                                        rules={[
                                            {
                                                required: auth?.decodeToken?._id === auth?.decodeToken?.organizer,
                                                message: t('organizer_common_phoneNoerror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_phoneNo')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='email'
                                        label={t('organizer_common_email')}
                                        rules={[
                                            {
                                                required: auth?.decodeToken?._id === auth?.decodeToken?.organizer,
                                                message: t('organizer_common_emailerror')
                                            },
                                            {
                                                type: 'email',
                                                message: t('organizer_common_emailinvaliderror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_email')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='designation'
                                        label={t('organizer_common_designation')}
                                        rules={[
                                            {
                                                required: auth?.decodeToken?._id === auth?.decodeToken?.organizer,
                                                message: t('organizer_common_designationerror')
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            placeholder={t('organizer_common_designation')}
                                            options={designationListing}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='holiday'
                                        label={t('organizer_common_holliday')}
                                        rules={[
                                            {
                                                required: auth?.decodeToken?._id === auth?.decodeToken?.organizer,
                                                message: t('organizer_common_holidayerror')
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('organizer_common_holliday')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='employeeType'
                                        label={t('organizer_common_employeetype')}
                                        rules={[
                                            {
                                                required: auth?.decodeToken?._id === auth?.decodeToken?.organizer,
                                                message: t('organizer_common_employeetypeerror')
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            placeholder={t('organizer_common_employeetype')}
                                            options={[
                                                {
                                                    value: 'internal',
                                                    label: t('organizer_common_internal')
                                                },
                                                {
                                                    value: 'external',
                                                    label: t('organizer_common_external')
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={6} xl={6} lg={{ span: 6, order: 2 }} md={{
                            span: 24,
                            order: 1
                        }} sm={24} xs={24}
                        >
                            <Flex justify='center' align='center' className='h-100'>
                                <Form.Item name='image'
                                >
                                    <Upload
                                        beforeUpload={() => false}
                                        listType='picture-circle'
                                        fileList={fileList}
                                        onChange={handleChange}
                                        multiple={false}
                                        disabled={false}
                                        showUploadList={{ showRemoveIcon: true, showPreviewIcon: false }}
                                    >
                                        {fileList.length === 0 && <Button type='link' disabled={false} >+ {t('organizer_common_upload')}</Button>}
                                    </Upload>
                                </Form.Item>
                            </Flex>
                        </Col>
                    </Row>
                    <Title level={5} className='padding-bottom-1'>{t('organizer_common_address')}</Title>
                    <Row gutter={18}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='streetNo'
                                label={t('organizer_common_streetName')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_streetnameerror'),
                                    },
                                ]}
                            >
                                <Input
                                    showCount={false}
                                    placeholder={t('organizer_common_streetName')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='city'
                                label={t('organizer_common_city')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_cityerror'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t('organizer_common_city')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='zip'
                                label={t('organizer_common_zip')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('organizer_common_ziperror'),
                                    },
                                    {
                                        pattern: new RegExp(/^[0-9]+$/),
                                        message: t('organizer_common_zipinvaliderror')
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={t('organizer_common_zip')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Title level={5} className='padding-bottom-2'>{t('organizer_common_shiftmanagement')}</Title>
                    <Row gutter={24}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='workType'
                                label={t('organizer_common_worktype')}
                                rules={[
                                    {
                                        required: auth?.decodeToken?._id === auth?.decodeToken?.organizer,
                                        message: t('organizer_common_worktypeerror')
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    placeholder={t('organizer_common_worktype')}
                                    options={[
                                        {
                                            value: 'full time',
                                            label: t('organizer_common_fulltime')
                                        },
                                        {
                                            value: 'part time',
                                            label: t('organizer_common_parttime')
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                            <Form.Item
                                name='weeklyWorkHours'
                                label={t('organizer_common_weeklyhours')}
                                rules={[
                                    {
                                        required: auth?.decodeToken?._id === auth?.decodeToken?.organizer,
                                        message: t('organizer_common_weeklyhourserror')
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={t('organizer_common_weeklyhours')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' htmlType='button' disabled={false} onClick={() => {
                            form.resetFields();
                            setProfile({})
                            setFileList([])
                            navigate(-1)
                        }}>{t('organizer_common_cancelButton')}</Button>
                        <Button type='primary' htmlType='submit' disabled={false} loading={client?.loading}>{t('organizer_common_submitButton')}</Button>
                    </Flex>
                </Form>
            </Flex >
        </Fragment >
    );
};

export default GeneralSettings;
