import * as React from 'react';

const InfoIcon = ({
    size = 24,
    color = '#333333',
    role = 'default',
    name = 'icon',
    style = {},
    iconStr = '',
    ...Rest
}) => {

    const styles = {
        cursor: 'pointer',
        ...style,
    }

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styles}
            {...Rest}
        >
            <path
                d="M10.0001 18.3327C14.5834 18.3327 18.3334 14.5827 18.3334 9.99935C18.3334 5.41602 14.5834 1.66602 10.0001 1.66602C5.41675 1.66602 1.66675 5.41602 1.66675 9.99935C1.66675 14.5827 5.41675 18.3327 10.0001 18.3327Z"
                stroke={color}
                strokeWidth={1.125}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 6.66602V10.8327"
                stroke={color}
                strokeWidth={1.125}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.99561 13.334H10.0023"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default InfoIcon;
