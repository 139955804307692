import React, { Fragment, useEffect, useState } from 'react';

import { Table, Button, Row, Col, DatePicker, Tooltip, Flex, Modal, Form, Select, Divider, Typography, TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import EditIcon from '../../../components/icon/EditIcon';
import DeleteIcon from '../../../components/icon/DeleteIcon';
import NextIcon from '../../../components/icon/NextIcon';
import CloseIcon from '../../../components/icon/CloseIcon';
import BackIcon from '../../../components/icon/BackIcon';
import CalendarIcon from '../../../components/icon/CalendarIcon';
import PlusIcon from '../../../components/icon/PlusIcon';

import { black, primary, secondary } from '../../../utils/theme/config';
import { createTimeTracking, deleteTimeTracking, getByIdTimeTracking, getTimeTrackings, getTimeTrackingsListData, setIsSuccess, setIsUpdating, updateTimeTracking } from '../redux/slice';
import { useDispatch, useSelector } from 'react-redux';

const { Title, Text } = Typography;

const CustomDatePicker = ({ selectedDate, onDateChange }) => {
    const [calendarVisible, setCalendarVisible] = useState(false);
    const datePickerRef = React.useRef(null);
    const { t } = useTranslation();

    const handlePrevious = () => {
        onDateChange(selectedDate.subtract(1, 'day'));
    };

    const handleNext = () => {
        onDateChange(selectedDate.add(1, 'day'));
    };

    const handleOpenCalendar = () => {
        setCalendarVisible(true);
    };

    const handleDateChange = (date) => {
        setCalendarVisible(false);
        onDateChange(date || dayjs());
    };

    const handleClickOutside = (event) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setCalendarVisible(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <Row align='middle' gutter={8} style={{ position: 'relative' }}>
            <Col>
                <span style={{ paddingLeft: '40px', fontSize: '12px' }}>{t('organizer_common_day')}</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button icon={<BackIcon size={24} color={primary} role='button' />} type='text' onClick={handlePrevious} style={{ margin: '0' }} />
                    <span style={{ margin: '0 8px', fontSize: '18px', fontWeight: '600' }}>{selectedDate.format('DD-MM-YYYY')}</span>
                    <Button icon={<NextIcon size={30} color={primary} role='button' />} type='text' onClick={handleNext} style={{ margin: '0', marginTop: '8px' }} />
                    <Button type='text' icon={<CalendarIcon color={secondary} role='button' />} onClick={handleOpenCalendar} style={{ margin: '0', marginLeft: '10px' }} />
                    {calendarVisible && (
                        <DatePicker
                            open={calendarVisible}
                            value={selectedDate}
                            onChange={handleDateChange}
                            variant='borderless'
                            allowClear={false}
                            suffixIcon={() => null}
                            onClick={() => setCalendarVisible(true)}
                            onBlur={() => setCalendarVisible(false)}
                            style={{
                                position: 'absolute',
                                zIndex: 1000,
                                marginTop: '8px',
                                width: '0',
                                color: 'transparent'
                            }}
                        />
                    )}
                </div>
            </Col>
        </Row>
    );
};

const AllUserTimeTracking = ({ search, organizerLists, permission }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { auth, timeTracking } = useSelector(state => state);

    const [allUserTimeTrackingList, setAllUserTimeTrackingList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editId, setEditId] = useState(null);
    const [isIndividualUser, setIsIndividualUser] = useState(false);
    const [timeSelections, setTimeSelections] = useState([{ start: null, end: null }]);
    const { t } = useTranslation();
    // const [minStartTime, setMinStartTime] = useState('08:00');

    useEffect(() => {
        if (Object.keys(auth?.decodeToken).length > 0) {
            getTimeTrackingDataList()
        }
    }, [date, auth, isIndividualUser, search])

    const getTimeTrackingDataList = () => {
        dispatch(getTimeTrackingsListData({
            organizer: auth?.decodeToken?.organizer,
            date: date
        }))
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date || dayjs());
        setDate(dayjs(date).format('MM/DD/YYYY'));
    };

    const handleOnFinish = (values) => {
        if (editId) {
            dispatch(setIsUpdating(false));
        }
        setIsIndividualUser(true)
        dispatch(getTimeTrackings({
            user: values.user,
            organizer: auth?.decodeToken?.organizer,
            date: dayjs(values.date).format('MM/DD/YYYY'),
            timeEntries: values?.timeEntries?.map((item) => {
                return {
                    start: dayjs(item.start).format('HH:mm'),
                    end: dayjs(item.end).format('HH:mm'),
                    type: item?.type
                }
            })
        }));
    }

    // useEffect(() => {
    //     if (allUserTimeTrackingList?.length > 0) {
    //         // Extract all start times from the timeEntries
    //         const allStartTimes = allUserTimeTrackingList?.flatMap(entry => entry?.timeEntries?.map(te => te.start));

    //         // Find the minimum start time
    //         const getMinStartTime = allStartTimes?.reduce((min, current) => current < min ? current : min, allStartTimes[0]);
    //         setMinStartTime(getMinStartTime)
    //     }
    // }, [allUserTimeTrackingList])


    useEffect(() => {
        setAllUserTimeTrackingList(timeTracking?.timeTrackingsList);
        if (Object.keys(timeTracking?.timeTracking).length > 0) {
            if (!editId && isIndividualUser) {
                dispatch(createTimeTracking({ timeTracking: timeTracking.timeTracking, organizer: auth?.decodeToken?.organizer, date: date }));
            } else {
                if (timeTracking.isUpdating) {
                    setTimeSelections([...timeTracking?.timeTracking?.timeEntries?.map(item => {
                        return {
                            start: dayjs(item?.start, 'HH:mm'),
                            end: dayjs(item?.end, 'HH:mm'),
                        }
                    }), { start: null, end: null }]);

                    form.setFieldsValue({
                        user: timeTracking?.timeTracking.user, date: dayjs(timeTracking?.timeTracking?.date),
                        timeEntries: timeTracking?.timeTracking?.timeEntries?.map(item => {
                            return {
                                start: item?.start === '' ? null : dayjs(item?.start, 'HH:mm'),
                                end: item?.end === '' ? null : dayjs(item?.end, 'HH:mm'),
                                type: item?.type
                            }
                        })
                    })
                } else if (isIndividualUser) {
                    dispatch(updateTimeTracking({ timeTracking: { ...timeTracking.timeTracking, id: editId }, organizer: auth?.decodeToken?.organizer, date: date }));
                }
            }
        }
        if (timeTracking.isSuccess) {
            setIsModalOpen(false);
            setIsIndividualUser(false)
            setEditId(null);
            handleClear();
            dispatch(setIsSuccess(false));
        }
    }, [timeTracking?.timeTrackingsList, timeTracking.isUpdating, timeTracking.timeTracking, timeTracking.isSuccess]);

    const renderTimeEntries = (timeEntries) => {
        return (
            <div style={{ display: 'flex', width: '100%' }}>
                {timeEntries.map((entry, index) => {
                    const isWork = entry.type === 'work';
                    const color = isWork ? '#B7E7AF' : '#FFCFC5';
                    const startTime = dayjs(entry.start, 'HH:mm');
                    const endTime = dayjs(entry.end, 'HH:mm');
                    const entryDurationMinutes = endTime.diff(startTime, 'minute');
                    // const startOffsetMinutes = startTime.diff(dayjs(minStartTime && minStartTime, 'HH:mm'), 'minute');
                    const startOffsetMinutes = startTime.diff(dayjs('04:00', 'HH:mm'), 'minute');
                    const widthPercent = (entryDurationMinutes * 100) / (20 * 60);
                    const startOffsetPercent = (startOffsetMinutes * 100) / (20 * 60);

                    return (
                        <Tooltip title={entry.duration} key={index}>
                            <div
                                style={{
                                    backgroundColor: color,
                                    width: `${widthPercent}%`,
                                    marginLeft: index === 0 ? `${startOffsetPercent}%` : '0%',
                                    height: '30px',
                                    textAlign: 'center',
                                    lineHeight: '30px',
                                    fontSize: '12px'
                                }}
                            >
                                {entry.duration >= '01:00' ? entry.duration : ''}
                            </div>
                        </Tooltip>
                    );
                })}
            </div>
        );
    };

    const handleClear = () => {
        form.resetFields();
        setEditId(null);
        setTimeSelections([{ start: null, end: null }]);
    };

    const renderTimeLabels = () => {
        const timeLabels = [];
        // const startTime = minStartTime && minStartTime.split(':')[0];

        for (let i = 4; i <= 23; i++) {
            timeLabels.push(`${i.toString().padStart(2, '0')}:00`);
        }

        // for (let i = 0; i <= 0; i++) {
        //     timeLabels.push(`${i.toString().padStart(2, '0')}:00`);
        // }

        return (
            <Row align='start' className='w-100' justify='space-between'>
                {timeLabels.map((label, index) => (
                    <div key={index} style={{ fontSize: '12px', width: '5%', padding: '6px 0' }}>
                        {label}
                    </div>
                ))}
            </Row>
        );
    };

    const columns = [
        {
            title: t('organizer_common_username'),
            dataIndex: 'user',
            key: 'username',
            fixed: 'left',
            width: '100px',
            onCell: () => ({
                style: {
                    maxWidth: '300px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                },
            }),
        },
        {
            title: t('organizer_common_totalhours'),
            dataIndex: 'totalHours',
            fixed: 'left',
            key: 'totalHours',
            width: '80px',
        },
        {
            title: t('organizer_common_breakhours'),
            dataIndex: 'breakHours',
            fixed: 'left',
            key: 'breakHours',
            width: '80px',
        },
        {
            title: t('organizer_common_overtime'),
            dataIndex: 'ourTime',
            fixed: 'left',
            key: 'ourTime',
            width: '100px',
        },
        {
            title: (
                <Flex align='center' justify='space-between'>
                    <span style={{ padding: '5px 0' }}>{t('organizer_common_time')}</span>
                </Flex>
            ),
            key: 'time',
            children: [
                {
                    title: renderTimeLabels(),
                    dataIndex: 'building',
                    key: 'building',
                    render: (text, record) => (
                        <div>
                            {renderTimeEntries(record.timeEntries)}
                        </div>
                    ),
                },
            ],
        },
    ];

    if (Object.keys(permission)?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: t('organizer_common_action'),
            key: 'action',
            fixed: 'right',
            width: '100px',
            render: (text, record) => (
                <Flex justify='center'>
                    {
                        permission?.canUpdate &&
                        <Button
                            type='text'
                            className='padding-1'
                            onClick={() => {
                                setIsModalOpen(true)
                                dispatch(setIsUpdating(true))
                                setEditId(record?._id)
                                dispatch(getByIdTimeTracking(record?._id))
                            }}>
                            <EditIcon role='button' />
                        </Button>
                    }
                    {
                        permission?.canDelete &&
                        <Button
                            type='text'
                            className='padding-1'>
                            <DeleteIcon
                                role='button'
                                onClick={() => dispatch(deleteTimeTracking({
                                    id: record?._id,
                                    organizer: auth?.decodeToken?.organizer,
                                    date: date
                                }))}
                            />
                        </Button>
                    }
                </Flex >
            )
        })
    }

    const handleModalCancel = () => {
        form.resetFields()
        setEditId(null)
        setIsModalOpen(false);
        setTimeSelections([{ start: null, end: null }]);
    }

    // Handle Start Time Change
    const handleStartTimeChange = (index, time) => {
        const newTimeSelections = [...timeSelections];
        // Ensure the object at the given index exists
        if (!newTimeSelections[index]) {
            newTimeSelections[index] = {}; // Initialize an empty object for the index
        }
        newTimeSelections[index].start = time;
        setTimeSelections(newTimeSelections);
    };

    // Handle End Time Change
    const handleEndTimeChange = (index, time) => {
        const newTimeSelections = [...timeSelections];
        newTimeSelections[index].end = time;

        // Automatically add a new row after selecting the end time
        if (index === timeSelections.length - 1) {
            setTimeSelections([...newTimeSelections, { start: null, end: null }]);
        } else {
            setTimeSelections(newTimeSelections);
            const newTimes = form.getFieldValue('timeEntries');

            form.setFieldsValue({
                timeEntries: newTimes?.map((i, key) => {
                    return {
                        ...i,
                        start: key === index + 1 ? time.clone().add(1, 'minute') : i.start,
                    }
                }),
            });

            handleStartTimeChange(index + 1, time.clone().add(1, 'minute'))
        }
    };

    // Disable times based on the previous row's end time for the next row's start time
    const disabledStartTime = (index) => {
        const previousEndTime = timeSelections[index - 1]?.end;
        if (!previousEndTime) return {}; // No previous end time to restrict

        return {
            disabledHours: () => Array.from({ length: 24 }, (_, i) => i < previousEndTime.hour() ? i : null).filter(i => i !== null),
            disabledMinutes: (selectedHour) =>
                selectedHour === previousEndTime.hour()
                    ? Array.from({ length: 60 }, (_, i) => i <= previousEndTime.minute() ? i : null).filter(i => i !== null)
                    : []
        };
    };

    // Disable times for the end time based on the selected start time
    const disabledEndTime = (index) => {
        const startTime = timeSelections[index]?.start;
        if (!startTime) return {}; // If no start time selected, no need to disable times

        return {
            disabledHours: () => Array.from({ length: 24 }, (_, i) => i < startTime.hour() ? i : null).filter(i => i !== null),
            disabledMinutes: (selectedHour) =>
                selectedHour === startTime.hour()
                    ? Array.from({ length: 60 }, (_, i) => i <= startTime.minute() ? i : null).filter(i => i !== null)
                    : []
        };
    };

    // Reset the time selections after removing a row
    const resetTimeSelectionsAfterRemove = (removedIndex) => {
        const newTimeSelections = [...timeSelections];

        // Remove the time entry at the removed index
        newTimeSelections.splice(removedIndex, 1);

        // Reset the form values for time entries
        setTimeSelections(newTimeSelections);
    };

    // Handle add input fields
    const handleAdd = (add) => {
        const fieldsValue = form.getFieldsValue();
        const times = fieldsValue.timeEntries || [];

        const lastTimeEntries = times[times.length - 1];

        // Validate the last row values before adding a new one
        if (!lastTimeEntries || !lastTimeEntries.type || !lastTimeEntries.start || !lastTimeEntries.end) {
            form.setFields(
                [
                    {
                        name: ['timeEntries', times.length - 1, 'type'],
                        errors: !lastTimeEntries?.type ? [t('organizer_common_typeerror')] : [],
                    },
                    {
                        name: ['timeEntries', times.length - 1, 'start'],
                        errors: !lastTimeEntries?.start ? [t('organizer_common_starttime') + ' ' + t('organizer_common_isrequired')] : [],
                    },
                    {
                        name: ['timeEntries', times.length - 1, 'end'],
                        errors: !lastTimeEntries?.end ? [t('organizer_common_endtime') + ' ' + t('organizer_common_isrequired')] : [],
                    },
                ]
            );
        } else {
            add();  // Add a new row

            const newTimes = form.getFieldValue('timeEntries');
            if (newTimes.length > 1) {
                const previousEndTime = newTimes[newTimes.length - 2]?.end;

                if (previousEndTime) {
                    // Increment the previous end time by 1 minute to get the new start time
                    const newStartTime = previousEndTime.clone().add(1, 'minute'); // Increment by 1 minute using Moment.js

                    // Update the start time for the new row
                    form.setFieldsValue({
                        timeEntries: [
                            ...newTimes.slice(0, newTimes.length - 1),
                            {
                                ...newTimes[newTimes.length - 1],
                                start: newStartTime,  // Set the start time 1 minute after the previous row's end time
                            },
                        ],
                    });

                    // Manually trigger onChange for the new start time (for the latest row)
                    handleStartTimeChange(newTimes.length - 1, newStartTime); // Trigger the onChange handler
                }
            }

            // Clear any errors
            form.setFields(
                [
                    {
                        name: ['timeEntries', times.length - 1, 'type'],
                        errors: false,
                    },
                    {
                        name: ['timeEntries', times.length - 1, 'start'],
                        errors: false,
                    },
                    {
                        name: ['timeEntries', times.length - 1, 'end'],
                        errors: false,
                    },
                ]
            );
        }
    };

    return (
        <Fragment>
            <div>
                <Flex align='center' justify='space-between' wrap>
                    <CustomDatePicker selectedDate={selectedDate} onDateChange={handleDateChange} />
                    <Flex align='center' gap={8}>
                        <div style={{ paddingRight: '10px' }}>
                            <span style={{ backgroundColor: '#B7E7AF', padding: '0px 10px', borderRadius: '4px' }}></span>
                            <span style={{ marginLeft: '8px' }}><b>{t('organizer_common_workinghours')}</b></span>
                        </div>
                        <div>
                            <span style={{ backgroundColor: '#FFCFC5', padding: '0px 10px', borderRadius: '4px' }}></span>
                            <span style={{ marginLeft: '8px' }}><b>{t('organizer_common_breakhours')}</b></span>
                        </div>
                        <Flex>
                            {
                                (Object.keys(permission)?.length > 0 && permission?.canCreate) &&
                                <Button onClick={() => showModal()}>
                                    {t('organizer_common_add')} {t('organizer_common_time')}
                                </Button>
                            }
                        </Flex>
                    </Flex>
                </Flex>
                <Table
                    columns={columns}
                    dataSource={allUserTimeTrackingList}
                    pagination={false}
                    scroll={{ x: 2200 }}
                    bordered
                    rowKey={(record) => record?._id}
                />
            </div>

            <Modal
                centered
                width={650}
                open={isModalOpen}
                closeIcon={<CloseIcon color={black} role='button' />}
                title={<Fragment><Title level={4} type='success'>{editId ? t('organizer_usermanagement_usertype_edit') : t('organizer_common_add')} {t('organizer_common_project')} {t('organizer_common_time')}</Title><Divider /></Fragment>}
                footer={null}
                onCancel={handleModalCancel}
                destroyOnClose
                modalRender={(dom) => (
                    <Form
                        layout='vertical'
                        form={form}
                        name='lagar-form'
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        initialValues={{ allTime: [''] }}
                        onFinish={(values) => handleOnFinish(values)}
                    >
                        {dom}
                    </Form>
                )}
            >
                <Row>
                    <Col xs={24} sm={12} style={{ paddingRight: '10px' }}>
                        <Form.Item
                            name='date'
                            label={t('organizer_common_date')}
                            rules={[
                                {
                                    required: true,
                                    message: t('organizer_common_dateerror'),
                                },
                            ]}
                        >
                            <DatePicker format={'DD-MM-YYYY'} style={{ padding: '6px', width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name='user'
                            label={t('organizer_common_user')}
                            rules={[
                                {
                                    required: true,
                                    message: t('organizer_common_usererror'),
                                },
                            ]}
                        >
                            <Select
                                placeholder={t('organizer_common_user')}
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={organizerLists}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24}>
                        <Form.List
                            name='timeEntries'
                            initialValue={[{ type: undefined, start: '', end: '' }]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }, index) => (
                                        <Row className='w-100' gutter={10} align='center' justify='center'>
                                            <Col xs={7}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'type']}
                                                    label={t('organizer_common_work') + '/' + t('organizer_common_break')}
                                                    rules={[{ required: true, message: t('organizer_common_typeerror') }]}
                                                >
                                                    <Select
                                                        placeholder={t('organizer_common_type')}
                                                        className='w-100'
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={[
                                                            { value: 'work', label: t('organizer_common_work') },
                                                            { value: 'break', label: t('organizer_common_break') },
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={7}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'start']}
                                                    label={t('organizer_common_starttime')}
                                                    rules={[{ required: true, message: t('organizer_common_starttime') + ' ' + t('organizer_common_isrequired') }]}
                                                >
                                                    <TimePicker
                                                        format='HH:mm'
                                                        className='w-100'
                                                        disabled={index > 0}
                                                        disabledTime={() => index > 0 ? disabledStartTime(index) : {
                                                            disabledHours: () => {
                                                                return [0, 1, 2, 3];
                                                            },
                                                            disabledMinutes: (hour) => {
                                                                if (hour === 3) {
                                                                    return Array.from({ length: 60 }, (_, i) => i);
                                                                }
                                                                return [];
                                                            },
                                                        }}
                                                        onChange={(time) => handleStartTimeChange(index, time)}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={7}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'end']}
                                                    label={t('organizer_common_endtime')}
                                                    rules={[{ required: true, message: t('organizer_common_endtime') + ' ' + t('organizer_common_isrequired') }]}
                                                >
                                                    <TimePicker
                                                        format='HH:mm'
                                                        className='w-100'
                                                        disabledTime={() => timeSelections[index]?.disabledEndTime || disabledEndTime(index)}
                                                        onChange={(time) => handleEndTimeChange(index, time)}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={3}>
                                                <Flex align='end' justify='end' className='h-100'>
                                                    <Form.Item>
                                                        {
                                                            index === fields.length - 1
                                                                ?
                                                                <Flex gap={10}>
                                                                    <PlusIcon
                                                                        size={24}
                                                                        color={black}
                                                                        role='button'
                                                                        onClick={() => handleAdd(add)}
                                                                    />
                                                                    {index > 0 && index !== 0 && fields.length > 1 && (
                                                                        <DeleteIcon
                                                                            role='button'
                                                                            onClick={() => {
                                                                                const newTimes = form.getFieldValue('timeEntries') || [];

                                                                                remove(name);

                                                                                // Update start time of the next field if applicable
                                                                                if (index < newTimes.length - 1) {
                                                                                    const nextStartTime = newTimes[index]?.end?.clone().add(1, 'minute');
                                                                                    if (nextStartTime) {
                                                                                        form.setFieldsValue({
                                                                                            timeEntries: [
                                                                                                ...newTimes.slice(0, index),
                                                                                                {
                                                                                                    ...newTimes[index],
                                                                                                    start: nextStartTime,
                                                                                                },
                                                                                                ...newTimes.slice(index + 1),
                                                                                            ],
                                                                                        });
                                                                                        handleStartTimeChange(index, nextStartTime);
                                                                                    }
                                                                                }
                                                                                resetTimeSelectionsAfterRemove(index);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Flex>
                                                                :
                                                                <DeleteIcon role='button' onClick={() => {
                                                                    const newTimes = form.getFieldValue('timeEntries');
                                                                    if (index < newTimes.length - 1 && index !== 0) {
                                                                        const firstRowEndTime = newTimes[newTimes?.findIndex((e, ind) => ind === index - 1)]?.end.clone().add(1, 'minute');;

                                                                        form.setFieldsValue({
                                                                            timeEntries: [
                                                                                ...newTimes.slice(0, index + 1),
                                                                                {
                                                                                    ...newTimes[index + 1],
                                                                                    start: firstRowEndTime
                                                                                },
                                                                                ...newTimes.slice(index + 2)
                                                                            ],
                                                                        });
                                                                        handleStartTimeChange(fields?.length - 1, firstRowEndTime)
                                                                    }

                                                                    remove(name);
                                                                    resetTimeSelectionsAfterRemove(index);
                                                                }} />
                                                        }
                                                    </Form.Item>
                                                </Flex>
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Col>

                    <Col xs={24}>
                        <Form.Item className='margin-0'>
                            <Flex align='center' gap={4} justify='flex-end'>
                                <Button type='primary' danger htmlType='button' onClick={handleModalCancel}>{t('organizer_common_cancelButton')}</Button>
                                <Button type='primary' htmlType='submit' loading={timeTracking?.loading}>{t('organizer_common_submitButton')}</Button>
                            </Flex>
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>
        </Fragment >
    );
};

export default AllUserTimeTracking;
