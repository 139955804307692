export const primary = '#007144';
export const secondary = '#a20417';
export const lightPrimary = '#e5f1ec';
export const lightSecondary = '#ffdfe3';
export const danger = '#D71313';
export const success = '#27C93F';
export const warning = '#FFBD2E';
export const info = '#0dcaf0';
export const dark = '#1C2455';
export const muted = '#6C757D';
export const white = '#FFFFFF';
export const black = '#000000';
export const gray = '#F2F2F2';
export const lightGreen = '#99C6B4';
export const lightYellow = '#F1EEE6';
export const darkWhite = '#737791';
export const lightDarkPrimary = '#99C6B4';

export const background = 'linear-gradient(270deg, rgba(196, 229, 229, 0.56) 0 %, rgba(0, 0, 0, 0.56) 100 %)';

export const themeConfig = {
    token: {
        fontFamily: 'Nunito, sans-serif',
        fontFamilyCode: 'Nunito, sans-serif',
        colorError: secondary,
        colorPrimary: primary,
        colorLink: primary
    },
    components: {
        Layout: {
            bodyBg: background,
            headerBg: white,
            headerColor: black,
            headerHeight: 64,
            headerPadding: 8,
            lightSiderBg: white,
            siderBg: white
        },
        Menu: {
            dropdownWidth: 192,
            fontSize: 16,
            iconMarginInlineEnd: 8,
            iconSize: 32,
            itemActiveBg: lightPrimary,
            itemBorderRadius: 8,
            itemHeight: 38,
            itemHoverBg: lightPrimary,
            itemHoverColor: primary,
            itemMarginInline: 4,
            itemPaddingInline: 8,
            itemSelectedBg: lightPrimary,
            itemSelectedColor: primary,
            popupBg: white,
            subMenuItemBg: white,
            subMenuItemBorderRadius: 4,
            zIndexPopup: 999
        },
        Typography: {
            titleMarginBottom: 0,
            colorSuccess: primary,
            colorTextDescription: darkWhite
        },
        Button: {
            defaultBorderColor: primary,
            defaultColor: primary,
            defaultHoverBg: primary,
            defaultHoverColor: white,
            defaultHoverBorderColor: primary,
            defaultActiveBg: primary,
            defaultActiveBorderColor: primary,
            defaultActiveColor: white,
            borderRadius: 8,
            paddingInline: 24,
            paddingBlock: 18,
            colorText: white,
            colorError: secondary,
            colorErrorActive: secondary,
            colorErrorHover: secondary,
            colorPrimary: primary,
            colorPrimaryActive: primary,
            colorPrimaryHover: primary,
            colorPrimaryBorder: primary,
            colorBgTextActive: white,
            textHoverBg: white
        },
        Collapse: {
            headerBg: white,
        },
        Input: {
            activeBorderColor: lightGreen,
            hoverBorderColor: lightGreen,
            colorBorder: lightGreen,
            borderRadius: 8,
            paddingInline: 16,
            paddingBlock: 6,
            fontWeightStrong: 600
        },
        Select: {
            colorPrimary: lightGreen,
            colorBorder: lightGreen,
            hoverBorderColor: lightGreen,
            activeBorderColor: lightGreen,
            optionSelectedBg: lightPrimary,
            borderRadius: 8,
            controlHeight: 36,
            algorithm: true,
            optionLineHeight: 2.5
        },
        Pagination: {
            colorPrimary: primary,
            colorBgTextHover: lightPrimary,
            colorBgTextActive: lightPrimary,
            colorBgContainerDisabled: primary,
            algorithm: true,
        },
        Table: {
            colorPrimary: primary,
            algorithm: true
        },
        Card: {
            paddingLG: 16,
        },
        Checkbox: {
            colorPrimary: primary,
            colorPrimaryBorder: primary,
            colorPrimaryHover: primary,
            borderRadiusSM: 8
        },
        Divider: {
            marginLG: 12,
        },
        Form: {
            labelColor: black,
            lineHeight: 1.5
        },
        Tag: {
            borderRadiusSM: 6,
            defaultBg: lightPrimary,
            colorBorder: false
        },
        Notification: {
            paddingContentHorizontalLG: 16,
            paddingMD: 12,
            marginXS: 0,
            fontSizeLG: 14
        },
        Dropdown: {
            controlItemBgActiveHover: white,
            controlItemBgActive: white,
        },
        Tabs: {
            itemSelectedColor: primary,
            itemHoverColor: primary,
            inkBarColor: primary,
            itemActiveColor: primary,
        },
        Popconfirm: {
            colorTextHeading: danger
        },
        Calendar: {
            itemActiveBg: primary,
            colorPrimary: primary,
            colorText: primary,
            colorLink: primary
        },
        DatePicker: {
            borderRadius: 8,
            cellWidth: 36,
            controlHeight: 36,
            colorBorder: lightGreen,
            hoverBorderColor: lightGreen,
            activeBorderColor: lightPrimary,
            algorithm: true,
            multipleItemHeight: 36,
            paddingBlock: 8,
        },
        InputNumber: {
            borderRadius: 8,
            controlHeight: 36,
            colorBorder: lightGreen,
            hoverBorderColor: lightGreen,
        },
        Upload: {
            colorPrimary: primary,
            colorBorder: primary,
            colorPrimaryHover: lightPrimary,
            colorFillAlter: lightPrimary,
            algorithm: true,
            padding: 28,
        },
    }
};
