import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    equipmentList: [],
    equipmentHistoryLists: [],
    equipment: {},
    historyEquipment: {},
    totalRecords: 0,
    historyTotalRecords: 0,
    loading: false,
    loadingHistory: false,
    error: null,
    isUpdating: false,
    isHistoryUpdating: false,
    isSuccess: false,
    isHistorySuccess: false,
    lagerLists: [],
    rackeNumbersLists: [],
    selfNumberLists: [],
    barcodeScanData: []
};

export const slice = createSlice({
    name: 'equipment',
    initialState,
    reducers: {
        getEquipmentList: (state, action) => {
            state.equipment = {};
            state.isUpdating = false;
            state.equipmentList = action.payload;
        },
        getEquipmentHistoryList: (state, action) => {
            state.isHistoryUpdating = false;
            state.equipmentHistoryLists = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setLoadingHistory: (state, action) => {
            state.loadingHistory = action.payload
        },
        setIsSuccess: (state, action) => {
            state.equipment = {};
            state.isSuccess = action.payload;
        },
        setIsHistorySuccess: (state, action) => {
            state.isHistorySuccess = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        setTotalHistoryrecords: (state, action) => {
            state.historyTotalRecords = action.payload;
        },
        getEquipment: (state, action) => {
            state.equipment = action.payload;
        },
        getHistoryEquipment: (state, action) => {
            state.historyEquipment = action.payload;
        },
        getLagerList: (state, action) => {
            state.lagerLists = action.payload;
        },
        getRackNumberList: (state, action) => {
            state.rackeNumbersLists = action.payload;
        },
        getSelfNumberList: (state, action) => {
            state.selfNumberLists = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
        setIsHistoryUpdating: (state, action) => {
            state.isHistoryUpdating = action.payload;
        },
        setBarcodeData: (state, action) => {
            state.barcodeScanData = action.payload;
        },
    },
});

export const getEquipmentListsData = createAsyncThunk('getEquipmentListsData', (filterData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/equipment/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getEquipmentList(response.data.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            dispatch(getEquipmentList([]));
            dispatch(setTotalrecords(0))
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createEquipment = createAsyncThunk('createEquipment', (equipmentData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/equipment/insert`, equipmentData?.formData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getEquipmentListsData({ search: '', order: '', sortBy: '', page: 1, limit: 10, filters: { uniqueIdentifier: '', rackSelfNumber: '', rackNumber: '' }, organizer: equipmentData?.organizer }))
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdEquipment = createAsyncThunk('getByIdEquipment', (id, { dispatch }) => {
    axios.get(`/equipment?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getEquipment(response.data.record));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const updateEquipment = createAsyncThunk('updateEquipment', (equipmentData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/equipment/update`, equipmentData?.formData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getEquipmentListsData({ search: '', order: '', sortBy: '', page: 1, limit: 10, filters: { uniqueIdentifier: '', rackSelfNumber: '', rackNumber: '' }, organizer: equipmentData?.organizer }));
            dispatch(setIsSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteEquipment = createAsyncThunk('deleteEquipment', (object, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/equipment/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getEquipmentListsData(object));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getLagerByCityList = createAsyncThunk('getLagerByCityList', (ids, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/whplace/lager-by-city/options?city=${ids?.cityId}&organizer=${ids?.organizerId}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getLagerList(response.data.records))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getRackByLagerList = createAsyncThunk('getRackByLagerList', (ids, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/whplace/rack-numbers-by-lager/options?lager=${ids?.lagerId}&organizer=${ids?.organizerId}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getRackNumberList(response.data.records))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getSelfNumberByLagerList = createAsyncThunk('getSelfNumberByLagerList', (ids, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/whplace/self-numbers-by-lager/options?rackNumber=${ids?.rackNumberId}&organizer=${ids?.organizerId}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getSelfNumberList(response.data.records))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdHistoryEquipment = createAsyncThunk('getByIdHistoryEquipment', (id, { dispatch }) => {
    axios.get(`/equipment/history?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getHistoryEquipment(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdGetLagerCityRackNumber = createAsyncThunk('getByIdGetLagerCityRackNumber', (lagers, { dispatch }) => {
    axios.get(`/whplace/details/by-self-number?id=${lagers?.id}&organizer=${lagers?.organizer}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(setBarcodeData(response.data.records))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Equipment History 

export const getEquipmentHistoryListsData = createAsyncThunk('getEquipmentHistoryListsData', (filterData, { dispatch }) => {
    dispatch(setLoadingHistory(true));
    axios.post(`/equipment/history/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getEquipmentHistoryList(response.data.records));
            dispatch(setTotalHistoryrecords(response.data.totalRecords));
        } else {
            dispatch(getEquipmentHistoryList([]));
            dispatch(setTotalHistoryrecords(0));
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoadingHistory(false));
    })
});

export const updateEquipmentHistory = createAsyncThunk('updateEquipmentHistory', (equipmentData, { dispatch }) => {
    dispatch(setLoadingHistory(true));
    axios.post(`/equipment/history/insert`, equipmentData.equipmentData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getEquipmentHistoryListsData({ search: '', order: '', sortBy: '', page: 1, limit: 10, filters: { uniqueIdentifier: '', rackSelfNumber: '', rackNumber: '' }, organizer: equipmentData?.organizer }));
            dispatch(setIsHistorySuccess(true));
        } else {
            dispatch(setError(true));
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        dispatch(setError(true));
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoadingHistory(false))
    })
});

export const getByIdEquipmentHistory = createAsyncThunk('getByIdEquipmentHistory', (id, { dispatch }) => {
    axios.get(`/equipment?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getHistoryEquipment(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getEquipmentHistoryList, setBarcodeData, setIsHistorySuccess, getHistoryEquipment, setLoadingHistory, setIsHistoryUpdating, setTotalHistoryrecords, getRackNumberList, getEquipment, getSelfNumberList, getLagerList, setIsUpdating, setIsSuccess, getEquipmentList, setTotalrecords, } = slice.actions;

export default slice.reducer;
