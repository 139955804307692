import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    inventoryList: [],
    inventory: {},
    warehouseName: [],
    rackNumbersLists: [],
    selfNumbersLists: [],
    totalRecords: 0,
    loading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
    barcodeScanData: []
};

export const slice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        getInventory: (state, action) => {
            state.inventory = action.payload;
        },
        getWareHouseName: (state, action) => {
            state.warehouseName = action.payload;
        },
        getRackNumber: (state, action) => {
            state.rackNumbersLists = action.payload;
        },
        getSelfNumber: (state, action) => {
            state.selfNumbersLists = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setTotalRecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
        getInventoryList: (state, action) => {
            state.inventory = {};
            state.isUpdating = false;
            state.inventoryList = action.payload;
        },
        setBarcodeScanData: (state, action) => {
            state.barcodeScanData = action.payload;
        }
    }
});


export const getInventoryListData = createAsyncThunk('getInventoryListData', (filterData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/inventory/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getInventoryList(response.data.records));
            dispatch(setTotalRecords(response.data.totalRecords));
        } else {
            dispatch(getInventoryList([]));
            dispatch(setTotalRecords(0))
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createInventory = createAsyncThunk('createInventory', (inventoryData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/inventory/insert`, inventoryData?.inventoryData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getInventoryListData({ search: '', sortBy: '', order: '', page: 1, limit: 10, filters: { city: [] }, organizer: inventoryData?.organizer }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const updateInventory = createAsyncThunk('updateInventory', (inventoryData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/inventory/update`, inventoryData?.inventoryData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getInventoryListData({ search: '', sortBy: '', order: '', page: 1, limit: 10, filters: { city: [] }, organizer: inventoryData?.organizer }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdInventory = createAsyncThunk('getByIdInventory', (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/inventory?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getInventory(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getLagerByCityList = createAsyncThunk('getLagarByCity', (data, { dispatch }) => {
    dispatch(setLoading(true));
    axios.get(`/whplace/lager-by-city/options?city=${data.city}&organizer=${data.organizer}`).then((response) => {
        dispatch(getWareHouseName(response.data.records));
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getRackByLagerList = createAsyncThunk('getRackByLagerList', (data, { dispatch }) => {
    dispatch(setLoading(true));
    axios.get(`/whplace/rack-numbers-by-lager/options?lager=${data.lagar}&organizer=${data.organizer}`).then((response) => {
        dispatch(getRackNumber(response.data.records));
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getSelfNumberByLagerList = createAsyncThunk('getSelfNumberByLagerList', (data, { dispatch }) => {
    dispatch(setLoading(true));
    axios.get(`/whplace/self-numbers-by-lager/options?rackNumber=${data.rackNumber}&organizer=${data.organizer}`).then((response) => {
        dispatch(getSelfNumber(response.data.records));
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteInventory = createAsyncThunk('deleteInventory', (object, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/inventory/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getInventoryListData(object));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIDBarcodeScanData = createAsyncThunk('getByIDBarcodeScanData', (lagers, { dispatch }) => {
    axios.get(`/whplace/details/by-self-number?id=${lagers?.id}&organizer=${lagers?.organizer}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(setBarcodeScanData(response.data.records))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const { getInventory, setLoading, setBarcodeScanData, getInventoryList, setTotalRecords, getWareHouseName, getRackNumber, getSelfNumber } = slice.actions;

export default slice.reducer;