import React, { Fragment, useEffect, useState } from 'react'

import { Button, Col, Flex, Form, Input, Row, Select, Tag, Typography, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';

import { createStammDocument, deleteDocument, getStammdatenDocumentData, getStammDocument, getStammProjectListing, setDocumentsData, setIsDocumentSuccess } from './redux/slice';

import { lightPrimary, lightSecondary } from '../../utils/theme/config';
import { getStammdatenUserListing } from '../../redux/slice/global';

import DataTable from '../../components/data-table';
import CommonPopconfirm from '../../components/custom-popconfirm';

import UploadIcon from '../../components/icon/UploadIcon';
import DocumentIcon from '../../components/icon/DocumentIcon';
import CloseIcon from '../../components/icon/CloseIcon';
import openNotification from '../../components/notification';

const { Title, Text } = Typography;

const Documents = ({ permission }) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { stammdaten, listing } = useSelector(state => state)

    const [form] = Form.useForm();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [documentLists, setDocumentList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [document, setDocument] = useState({});
    const [modalType, setModalType] = useState(false);
    const [documentType, setDocumentType] = useState('');
    const [projectListing, setProjectListing] = useState([]);
    const [stammUserListing, setStammUserListing] = useState([]);
    const [loginUser, setLoginUser] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
        }
    }, [])

    useEffect(() => {
        getStammUserLists();
    }, [loginUser?.organizer])

    const getStammUserLists = () => {
        loginUser?.organizer && dispatch(getStammdatenUserListing(loginUser?.organizer));
    };

    useEffect(() => {
        if (listing.listing?.stammUser?.length > 0) {
            setStammUserListing(listing.listing?.stammUser)
        }
    }, [listing.listing.stammUser]);


    // Get Stammdaten Document Data
    useEffect(() => {
        getStammDocumentListData()
    }, [pageSize, page, sortBy, order, loginUser])

    const getStammDocumentListData = () => {
        if (loginUser?.organizer) {
            dispatch(getStammdatenDocumentData({
                sortBy: sortBy,
                order: order,
                search: '',
                page: page,
                limit: pageSize,
                organizer: loginUser?.organizer
            }))
        }
    }

    useEffect(() => {
        setDocumentList(stammdaten?.stammdatenDocumentLists)
        setTotalRecords(stammdaten?.documentTotalRecords)
        setProjectListing(stammdaten?.stammProjectListing)
    }, [stammdaten?.stammdatenDocumentLists, stammdaten?.documentTotalRecords, stammdaten?.stammProjectListing])

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    const columns = [
        {
            title: t('organizer_common_createddate'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '80px',
            sorter: (_, __, order) => handleSortFunction(order, 'createdAt'),
            render: (_, record) => dayjs(record?.createdAt).format('DD-MM-YYYY'),
        },
        {
            title: t('organizer_common_customer'),
            dataIndex: 'stammUser',
            key: 'stammUser',
            width: '150px',
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: t('organizer_common_documentname'),
            dataIndex: 'name',
            key: 'documentName',
            width: '100px',
            sorter: (_, __, order) => handleSortFunction(order, 'name'),
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: t('organizer_common_documenttype'),
            dataIndex: 'type',
            key: 'documentType',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'type'),
            render: (text) => <Text>{text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase()}</Text>,
        },
        {
            title: t('organizer_common_projectname'),
            dataIndex: 'project',
            key: 'project',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'project'),
            render: (text) => <Text>{text}</Text>,
        },
        {
            title: t('organizer_common_uploadedfile'),
            dataIndex: 'file',
            key: 'file',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'file'),
            render: (_, record) => {
                return <Flex align='center' gap={3}>
                    <DocumentIcon />
                    <Text>{record?.name}</Text>
                </Flex>
            },
        },
        {
            title: t('organizer_common_status'),
            dataIndex: 'isActive',
            key: 'status',
            width: '100px',
            sorter: (_, __, order) => handleSortFunction(order, 'status'),
            render: (_, record) => {
                return (
                    <Fragment>
                        {

                            record?.isActive ?
                                <Tag color={lightPrimary} className='text-center'>{t('organizer_common_active')}</Tag> :
                                <Tag color={lightSecondary} className='text-center'>{t('organizer_common_deactivate')}</Tag>
                        }
                    </Fragment>
                );
            }
        },
    ];

    if (Object.keys(permission ?? {})?.length > 0 && (permission?.canUpdate || permission?.canDelete)) {
        columns.push({
            title: <Flex justify='center'><Text>{t('superadmin_common_action')}</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => (
                <Fragment>
                    <Flex gap={8} justify='center' align='center'>
                        <a href={`${process.env.REACT_APP_API_URI}/documents/${record?.document}`} download target='_blank'>
                            <UploadIcon size={24} style={{
                                transform: 'rotate(180deg)',
                            }}
                                role='button'
                            />
                        </a>
                        {
                            record?.isActive ?
                                <CommonPopconfirm
                                    title={t('organizer_stammdaten_stammuser_deactivatedocument')}
                                    description={t('organizer_stammdaten_stammuser_confirmdocumentdescription')}
                                    icon={null}
                                    onConfirm={() => dispatch(deleteDocument({
                                        id: record?._id,
                                        sortBy: '',
                                        order: '',
                                        search: '',
                                        page: page,
                                        limit: pageSize,
                                        organizer: loginUser?.organizer
                                    }))}
                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                >
                                    <Button type='text' className='padding-1'><CloseIcon role='button' /></Button>
                                </CommonPopconfirm>
                                :
                                <CommonPopconfirm
                                    title={t('organizer_stammdaten_stammuser_deactivatedocumentactivestammuser')}
                                    description={t('organizer_stammdaten_stammuser_confirmdocumentactivedescription')}
                                    icon={null}
                                    onConfirm={() => dispatch(deleteDocument({
                                        id: record?._id,
                                        sortBy: '',
                                        order: '',
                                        search: '',
                                        page: page,
                                        limit: pageSize,
                                        organizer: loginUser?.organizer
                                    }))}
                                    cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                >
                                    <Button type='text' className='padding-1'><CloseIcon role='button' /></Button>
                                </CommonPopconfirm>
                        }
                    </Flex>
                </Fragment>
            ),
        })
    }

    const onFinish = (values) => {
        if (fileList?.length < 0) {
            form.setFields([
                {
                    name: 'files',
                    errors: [t('organizer_common_uploadedfileerror')], // Clear any previous errors
                },
            ]);
        }
        dispatch(getStammDocument({
            ...values,
            organizer: loginUser?.organizer
        }));
        setModalType(true);
    };

    useEffect(() => {
        if (Object.keys(stammdaten?.stammDocument).length > 0) {
            if (modalType) {
                const formData = new FormData();
                Object.keys(document)?.length > 0 && formData.append('document', document)
                Object.entries(stammdaten.stammDocument).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                dispatch(createStammDocument({ stammUserData: formData, organizerId: loginUser?.organizer }));
            }
        }
        if (stammdaten.isDocumentSuccess) {
            form.resetFields();
            setFileList([]);
            setDocument({});
            setModalType(false);
            setDocumentType('');
            dispatch(getStammdatenDocumentData({}));
            dispatch(setIsDocumentSuccess(false));
        }
    }, [stammdaten.stammDocument, stammdaten.isDocumentSuccess, modalType, loginUser])

    const handleDocumentTypeChange = (value) => {
        setDocumentType(value);
    };

    const handleOnStammUserChange = (e) => {
        dispatch(getStammProjectListing(e));
        form.resetFields(['project']);
    }

    const onUploadChange = ({ file, fileList }) => {

        // Keep only the last uploaded file in the list
        if (fileList.length > 1) {
            fileList.splice(0, fileList.length - 1);
        }

        // Update the file list state
        setFileList(fileList);
        setDocument(file?.originFileObj)

        // Manually trigger validation to clear the error if the file is valid
        form.setFields([
            {
                name: 'files',
                errors: [], // Clear any previous errors
            },
        ]);
    };

    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const onFormValuesChange = (_, allValues) => {
        dispatch(setDocumentsData(allValues));
    };

    const handleClear = () =>{
        form.resetFields();
        dispatch(getStammdatenDocumentData({}));
        navigate(-1)
    }

    const validateFileType = (file) => {
        const allowedExtensions = [
            'pdf', 'jpg', 'jpeg', 'png', 'gif', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'rar', 'mp4', 'wmv', 'msg'
        ];

        // Extract the file extension
        let fileExtension = file?.name?.includes('.') ? file?.name.split('.').pop() : file?.type.split('/')[1];

        // Ensure no '+xml' is included in the extension (for SVG files)
        fileExtension = fileExtension?.replace('+xml', '');

        // Check if the file extension is allowed
        const isValidExtension = allowedExtensions?.includes(fileExtension?.toLowerCase());

        if (!isValidExtension) {
            openNotification({
                type: 'error',
                message: t('common_image_upload_invalid_error'), 
            });
            return Upload.LIST_IGNORE; // Ignore the file upload if invalid
        }
        const isValidSize = file.size / 1024 / 1024 <= 5;
        if (!isValidSize) {
            openNotification({ type: 'error', message: t('common_image_upload_error') });
            return Upload.LIST_IGNORE;
        }
        return true;
    }

    return (
        <Fragment>
            <Flex vertical gap={24} className='change-tag'>
                <Row
                    justify='space-between'
                    align='middle'
                    className='bg-white padding-1 rounded-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none'>
                                <Title level={4} type='success'>{t('organizer_common_uploaddocument')}</Title>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    (Object.keys(permission ?? {})?.length > 0 && permission?.canCreate) &&
                    <Form
                        form={form}
                        name='user'
                        layout='vertical'
                        onFinish={onFinish}
                        className='w-100 bg-white padding-2 rounded-1'
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        onValuesChange={onFormValuesChange}
                    >
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                                <Row justify={'space-between'} gutter={24}>
                                    <Col xxl={12} xl={11} md={12} lg={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='stammUser'
                                            label={t('organizer_common_customer')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `${t('organizer_common_please_select')} ${t('organizer_common_customer')?.toLowerCase()}`
                                                }
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                }
                                                onChange={(e) => handleOnStammUserChange(e)}
                                                placeholder={t('organizer_common_customer')}
                                                options={stammUserListing}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name='name'
                                            label={t('organizer_common_documentname')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('organizer_common_documentnameerror'),
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={t('organizer_common_documentname')}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name='type'
                                            label={t('organizer_common_documenttype')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('organizer_common_documenttypeerror'),
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder={t('organizer_common_documenttype')}
                                                onChange={handleDocumentTypeChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={[
                                                    { value: 'general', label: t('organizer_common_general') },
                                                    { value: 'project', label: t('organizer_common_project') },
                                                ]}
                                            />
                                        </Form.Item>

                                        {
                                            documentType === 'project'
                                            && (
                                                <Form.Item
                                                    name='project'
                                                    label={t('organizer_common_project')}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('organizer_common_projecterror'),
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder={t('organizer_common_project')}
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        options={projectListing}
                                                    />
                                                </Form.Item>
                                            )
                                        }
                                    </Col>
                                    <Col xxl={12} xl={12} md={12} lg={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='files'
                                            rules={[{ required: fileList?.length <= 0, message: t('organizer_common_uploadedfileerror') }]}
                                            valuePropName='files'
                                            getValueFromEvent={(e) => e && e.fileList}
                                        >
                                            <Upload.Dragger beforeUpload={validateFileType} multiple={false} name='files' fileList={fileList} onChange={onUploadChange}  >
                                                <p className='ant-upload-drag-icon'>
                                                    <UploadIcon size={38} />
                                                </p>
                                                <Text className='ant-upload-text' strong>{t('organizer_common_uploadedfilehere')}</Text>
                                            </Upload.Dragger>

                                            <Flex vertical gap={12} className='paddingY-1'>
                                                <Text type='secondary'>{t('organizer_common_uploadedfileheredescription')}</Text>

                                                <Text type='secondary'>
                                                    {t('organizer_common_uploaddocumentsize')}
                                                </Text>
                                            </Flex>
                                        </Form.Item>

                                        <Flex justify='flex-end' gap={10} wrap className='paddingY-2'>
                                            <Button danger type='primary' htmlType='button' onClick={handleClear}>{t('organizer_common_cancelButton')}</Button>
                                            <Button type='primary' htmlType='submit' loading={stammdaten?.loading}>{t('organizer_common_submitButton')}</Button>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                }
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <DataTable
                            columns={columns}
                            pageSize={pageSize}
                            currentPage={page}
                            total={totalRecords}
                            scrollX={900}
                            showSorterTooltip={false}
                            isPagination={true}
                            handleOnChange={handleOnChange}
                            data={documentLists}
                            rowKey={(record) => record?._id}
                        />
                    </Col>
                </Row>
            </Flex>
        </Fragment>
    )
};

export default Documents;
