export const NumberRegex = /^\d{10}$/;
export const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
export const PasswordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}:'<>?])[A-Za-z\d!@#$%^&*()_+{}:'<>?]{8,}$/;

export const menuName = {
    timeTracking: 'Time Tracking',
    settings: 'Settings',
    workHistory: 'Work History',
    addVacation: 'Add Vacation',
    vacationManagement: 'Vacation Mgnt.'
}

export const menuLanguageKeyMapping = {
    'Dashboard': 'menu_dashboard',
    'Client': 'menu_client_mgnt',
    'Packages': 'menu_packages',
    'User Mgnt.': 'menu_user_mgnt',
    'Users': 'menu_user_mgnt_users',
    'User Types': 'menu_user_mgnt_user_type',
    'Designations': 'menu_user_mgnt_designations',
    'Stammdaten': 'menu_stammdaten',
    'Customer': 'menu_stammdaten_customer',
    'Projects': 'menu_stammdaten_projects',
    'Documents': 'menu_stammdaten_documents',
    'Warehouse Mgnt.': 'menu_warehouse_mgnt',
    'Lager': 'menu_warehouse_mgnt_lager',
    'WH Place Management': 'menu_warehouse_mgnt_wh_place_management',
    'Inventory Mgmt.': 'menu_warehouse_mgnt_inventory_mgmt',
    'Equipment list': 'menu_warehouse_mgnt_equipment_list',
    'Equipment History': 'menu_warehouse_mgnt_equipment_history',
    'Time Tracking': 'menu_time_tracking',
    'Construction Diary': 'menu_construction_diary',
    'Vacation Mgnt.': 'menu_vacation_mgnt',
    'Utility': 'menu_utility',
    'City': 'menu_utility_city',
    'Settings': 'menu_settings',
    'General Setting': 'menu_settings_general_setting',
    'Add Vacation': 'menu_settings_add_vacation',
    'Work History': 'menu_settings_work_history',
    'Company Detail': 'menu_settings_company_detail',
    'Shift Mgnt.': 'menu_shift_mgnt',
    'Plan Mgnt.': 'menu_shift_mgnt_plan_mgnt',
    'Employee Shift': 'menu_shift_mgnt_employee_shift',
};