import React, { Fragment, useEffect, useState } from 'react'

import { Flex, Button, Typography, DatePicker, Row, Col, Tooltip, Avatar, Table, Modal, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import BackIcon from '../../../components/icon/BackIcon';
import NextIcon from '../../../components/icon/NextIcon';
import CloseIcon from '../../../components/icon/CloseIcon';

import { black, primary } from '../../../utils/theme/config';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLeaveListData } from '../redux/slice';

const { Title, Text } = Typography;

const CustomDatePicker = ({ selectedDate, onDateChange }) => {
  const [calendarVisible, setCalendarVisible] = useState(false);
  const datePickerRef = React.useRef(null);

  const handlePrevious = () => {
    onDateChange(selectedDate.subtract(1, 'month'));
  };

  const handleNext = () => {
    onDateChange(selectedDate.add(1, 'month'));
  };

  const handleDateChange = (date) => {
    setCalendarVisible(false);
    onDateChange(date || dayjs());
  };

  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setCalendarVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <Row align='middle' gutter={8} style={{ position: 'relative' }}>
      <Col>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button icon={<BackIcon size={24} color={primary} role='button' />} type='text' onClick={handlePrevious} style={{ margin: '0' }} />
          <span style={{ margin: '0 8px', fontSize: '18px', fontWeight: '600' }}>{selectedDate.format('MMM YYYY')}</span>
          <Button icon={<NextIcon size={30} color={primary} role='button' />} type='text' onClick={handleNext} style={{ margin: '0', marginTop: '8px' }} />
          {calendarVisible && (
            <DatePicker
              picker='month'
              open={calendarVisible}
              value={selectedDate}
              onChange={handleDateChange}
              variant='borderless'
              allowClear={false}
              suffixIcon={() => null}
              onClick={() => setCalendarVisible(true)}
              onBlur={() => setCalendarVisible(false)}
              style={{
                position: 'absolute',
                zIndex: 1000,
                marginTop: '8px',
                width: '0',
                color: 'transparent'
              }}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

const AllLeavesAdmin = ({ allUserLeavesList, month, setMonth, setYear, year }) => {
  const dispatch = useDispatch();
  const { auth, vacation } = useSelector((state) => state);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalUserData, setModalUserData] = useState({});
  const { t } = useTranslation();

  const handleDateChange = (date) => {
    setSelectedDate(date || dayjs());
    setDate(dayjs(date).format('MM/DD/YYYY'));
    setYear(dayjs(date).format('YYYY'))
    setMonth(dayjs(date).format('MM'))
  };

  const renderTimeEntries = (leaveDatesByMonthYear) => {
    return (
      <div style={{ display: 'flex', width: '100%', position: 'relative', height: '30px' }}>
        {leaveDatesByMonthYear?.map((entry, index) => {
          const isWork = entry.type === 'formal';
          const color = isWork ? '#B7E7AF' : '#FFCFC5';

          const startDate = dayjs(entry.start, 'DD-MM-YYYY');
          const endDate = dayjs(entry.end, 'DD-MM-YYYY');

          // Calculate the days in the entry
          const entryDurationDays = endDate.diff(startDate, 'day') + 1;
          const startOffsetDays = startDate.date() - 1;

          const daysInMonth = dayjs().daysInMonth();
          const widthPercent = (entryDurationDays * 100) / daysInMonth;
          const startOffsetPercent = (startOffsetDays * 100) / daysInMonth;

          return (
            <Tooltip title={`${entry.days} days`} key={index}>
              <div
                style={{
                  backgroundColor: color,
                  width: `${widthPercent}%`,
                  marginLeft: `${startOffsetPercent}%`,
                  height: '30px',
                  lineHeight: '20px',
                  fontSize: '12px',
                  textAlign: 'center',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  left: 0,
                  borderRadius: '3px'
                }}
              >
                {/* days */}
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const renderTimeLabels = () => {
    const dateLabels = [];
    const daysInMonth = dayjs(date).daysInMonth();
    const currentYear = dayjs(date).year();
    const currentMonth = dayjs(date).month();

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDateTime = dayjs(`${currentYear}-${currentMonth + 1}-${day}`);
      const formattedDate = currentDateTime.format('DD');
      const dayName = currentDateTime.format('dddd').slice(0, 2);

      // Check if it's Saturday (Sa) or Sunday (Su)
      const isWeekend = dayName === 'Sa' || dayName === 'Su';

      dateLabels.push({ date: formattedDate, dayname: dayName, isWeekend });
    }

    return (
      <Row align='start' className='w-100' justify='space-between'>
        {dateLabels.map((label, index) => (
          <div
            key={index}
            style={{
              fontSize: '12px',
              width: `${100 / daysInMonth}%`,
              textAlign: 'center',
              padding: '5px 0',
              boxSizing: 'border-box',
              color: label.isWeekend ? '#ccc' : 'inherit',
              pointerEvents: label.isWeekend ? 'none' : 'auto',
            }}
          >
            {label.date} <br />
            {label.dayname}.
          </div>
        ))}
      </Row>
    );
  };

  useEffect(() => {
    setModalUserData(vacation?.userLeaveList);
  }, [vacation?.userLeaveList])

  const getUserLeave = (record) => {
    dispatch(getUserLeaveListData({
      user: record._id,
      year: year
    }))
    showModal();
  }

  const columns = [
    {
      title: t('organizer_common_name'),
      dataIndex: 'user',
      key: 'user',
      fixed: 'left',
      width: '250px',
      onCell: () => ({
        style: {
          minWidth: '190px',
          maxWidth: '250px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
        },
      }),
      render: (text, record) => {
        return <>
          <Flex align='center' gap={15} onClick={() => getUserLeave(record)}>
            <Avatar
              src={`${process.env.REACT_APP_API_URI}/profiles/${record?.profilePicture}`}
              size={'large'}
            />
            <Flex vertical>
              <Text type='success' strong>{text}</Text>
              <Text>{record.phone}</Text>
            </Flex>
          </Flex >
        </>
      }
    },
    {
      title: t('organizer_common_total') + ' ' + t('organizer_vacationmanagement_leave'),
      dataIndex: 'totalLeaves',
      fixed: 'left',
      key: 'totalLeaves',
      width: '80px',
      render: (_, record) => <Text type='success'>{record?.totalLeaves}</Text>
    },
    {
      title: t('organizer_common_used') + ' ' + t('organizer_vacationmanagement_leave'),
      dataIndex: 'usedLeaves',
      fixed: 'left',
      key: 'usedLeaves',
      width: '80px',
      render: (_, record) => <Text className='red-color'>{record?.usedLeaves}</Text>
    },
    {
      title: t('organizer_common_remain') + ' ' + t('organizer_vacationmanagement_leave'),
      dataIndex: 'remainPaidLeaves',
      fixed: 'left',
      key: 'remainPaidLeaves',
      width: '90px',
    },
    {
      title: t('organizer_common_sick') + ' ' + t('organizer_vacationmanagement_leave'),
      dataIndex: 'sickLeaves',
      fixed: 'left',
      key: 'sickLeaves',
      width: '70px',
      render: (_, record) => <Text className='yellow-color'>{record?.sickLeaves}</Text>
    },
    {
      title: '',
      onHeaderCell: () => ({
        style: { padding: 0, display: 'none' },
      }),
      key: 'time',
      children: [
        {
          title: renderTimeLabels(),
          dataIndex: 'building',
          key: 'building',
          render: (text, record) => (
            <div>
              {renderTimeEntries(record?.leaveDatesByMonthYear)}
            </div>
          ),
        },
      ],
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalUserData({});
  };

  return (
    <Fragment>
      <Flex vertical gap={16} className='vacation-table'>
        <Flex align='center' justify='space-between' wrap>
          <Title level={4}>{t('organizer_common_all')} {t('organizer_usermanagement_user_userListTitle')}</Title>
          <CustomDatePicker selectedDate={selectedDate} onDateChange={handleDateChange} />
          <Flex align='center' gap={8}>
            <div style={{ paddingRight: '10px' }}>
              <span style={{ backgroundColor: '#FFCFC5', padding: '0px 10px', borderRadius: '4px' }}></span>
              <span style={{ marginLeft: '8px' }}><b>{t('organizer_common_sick')} {t('organizer_vacationmanagement_leave')}</b></span>
            </div>
            <div>
              <span style={{ backgroundColor: '#B7E7AF', padding: '0px 10px', borderRadius: '4px' }}></span>
              <span style={{ marginLeft: '8px' }}><b>{t('organizer_common_formal')} {t('organizer_vacationmanagement_leave')}</b></span>
            </div>
          </Flex>
        </Flex>
        <Table
          columns={columns}
          dataSource={allUserLeavesList}
          pagination={false}
          scroll={{ x: 2200 }}
          bordered
          className='new-vacation'
          rowKey={(record) => record?._id}
        />
        <Modal
          width={350}
          className='new-vacation'
          closeIcon={<CloseIcon role='button' color={black} />}
          title={
            <Flex align='center' gap={15} onClick={() => showModal()}>
              <Avatar
                src={`${process.env.REACT_APP_API_URI}/profiles/${modalUserData[0]?.profilePicture}`}
                size={'large'}
              />
              <Flex vertical>
                <Text type='success' strong>{modalUserData[0]?.user}</Text>
                <Text>{modalUserData[0]?.phone}</Text>
              </Flex>
            </Flex >
          }
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Row className='padding-top-2'>
            <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
              <Title level={3} type='success'>{modalUserData[0]?.totalLeaves}</Title>
              <Text>{t('organizer_common_total')} <br /> {t('organizer_common_leaves')}</Text>
            </Col>
            <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
              <Title level={3} type='success' >{modalUserData[0]?.remainPaidLeaves}</Title>
              <Text className='text-center'>{t('organizer_common_total')} {t('organizer_common_remain')} <br /> {t('organizer_common_leaves')}</Text>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
              <Title level={3} className='red-color'>{modalUserData[0]?.usedLeaves}</Title>
              <Text className='text-center'>{t('organizer_common_total')} {t('organizer_common_used')} <br /> {t('organizer_common_leaves')}</Text>
            </Col>
          </Row>
          {
            modalUserData[0]?.sickLeavesByYear?.length > 0 &&
            <Fragment>
              <Title level={5} className='padding-top-2'>{t('organizer_vacationmanagement_leave')} {t('organizer_common_type')} - {t('organizer_common_sick')} {t('organizer_vacationmanagement_leave')}</Title>
              <Divider />
            </Fragment>
          }
          {
            modalUserData[0]?.sickLeavesByYear?.map((sick, index) => {
              return <Fragment key={index}>
                <Row>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Text>{`${dayjs(sick?.start, 'DD-MM-YYYY').format('D MMM')} - ${dayjs(sick?.end, 'DD-MM-YYYY').format('D MMM')}` || '-'}</Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Flex justify='flex-end'>
                      <Text className='red-color'>{sick?.days || '-'} {t('organizer_common_days')} {t('organizer_vacationmanagement_leave')}</Text>
                    </Flex>
                  </Col>
                </Row>
              </Fragment>
            })
          }
          {
            modalUserData[0]?.sickLeavesByYear?.length > 0 &&
            <Fragment>
              <Divider />
              <Flex justify='flex-end'>
                <Text>{t('organizer_common_total')} - {modalUserData[0]?.sickLeavesByYear?.reduce((sum, item) => sum + item.days, 0)} {t('organizer_common_days')} {t('organizer_vacationmanagement_leave')}</Text>
              </Flex>
            </Fragment>
          }
          {
            modalUserData[0]?.formalLeavesByYear?.length > 0 &&
            <Fragment>
              <Title level={5} className='padding-top-2'>{t('organizer_vacationmanagement_leave')} {t('organizer_common_type')} - {t('organizer_common_formal')} {t('organizer_vacationmanagement_leave')}</Title>
              <Divider />
            </Fragment>
          }
          {
            modalUserData[0]?.formalLeavesByYear?.map((formal, index) => {
              return <Fragment key={index}>
                <Row>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Text>{`${dayjs(formal?.start, 'DD-MM-YYYY').format('D MMM')} - ${dayjs(formal?.end, 'DD-MM-YYYY').format('D MMM')}` || '-'}</Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Flex justify='flex-end'>
                      <Text className='red-color'>{formal?.days || '-'} {t('organizer_common_days')} {t('organizer_vacationmanagement_leave')}</Text>
                    </Flex>
                  </Col>
                </Row>
              </Fragment>
            })
          }
          {
            modalUserData[0]?.formalLeavesByYear?.length > 0 &&
            <Fragment>
              <Divider />
              <Flex justify='flex-end'>
                <Text>{t('organizer_common_total')} - {modalUserData[0]?.formalLeavesByYear?.reduce((sum, item) => sum + item.days, 0) || '0'} {t('organizer_common_days')} {t('organizer_vacationmanagement_leave')}</Text>
              </Flex>
            </Fragment>
          }
        </Modal>
      </Flex>
    </Fragment >
  );
};

export default AllLeavesAdmin;
