import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    vacationList: [],
    userVacationList: [],
    requestList: [],
    userLeaveList: {},
    vacation: {},
    totalRecords: 0,
    loading: false,
    requestLoading: false,
    approveLoading: false,
    rejectLoading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
    userLeaves: {}
};

export const slice = createSlice({
    name: 'vacation',
    initialState,
    reducers: {
        getLeavesList: (state, action) => {
            state.vacation = {};
            state.isUpdating = false;
            state.vacationList = action.payload;
        },
        getUserLeaves: (state, action) => {
            state.userVacationList = action.payload;
        },
        getRequestLeavesList: (state, action) => {
            state.requestList = action.payload;
        },
        getUserLeavesList: (state, action) => {
            state.userLeaveList = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setRequestLoading: (state, action) => {
            state.requestLoading = action.payload
        },
        setApproveLoading: (state, action) => {
            state.approveLoading = action.payload
        },
        setRejectLoading: (state, action) => {
            state.rejectLoading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.vacation = {};
            state.isSuccess = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        setUserLeaves: (state, action) => {
            state.userLeaves = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
    },
});

export const getAdminLeavesListData = createAsyncThunk('getLeavesListData', (leaveData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.get(`/vacation/organizer?month=${leaveData?.month}&year=${leaveData?.year}&organizer=${leaveData?.organizer}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getLeavesList(response.data.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            dispatch(getLeavesList([]));
            dispatch(setTotalrecords(0))
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getUserLeavesListData = createAsyncThunk('getUserLeavesListData', (leaveData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.get(`/vacation/user?month=${leaveData?.month}&year=${leaveData?.year}&user=${leaveData?.user}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getUserLeaves(response.data.records))
        } else {
            dispatch(getUserLeaves([]));
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getUserLeaveListData = createAsyncThunk('getUserLeaveListData', (leaveData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.get(`/vacation/user/details?user=${leaveData?.user}&year=${leaveData?.year}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getUserLeavesList(response.data.records))
        } else {
            dispatch(getUserLeavesList({}));
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getRequestedLeavesListData = createAsyncThunk('getRequestedLeavesListData', (leaveData, { dispatch }) => {
    dispatch(setRequestLoading(true));
    axios.get(`/vacation/pending?organizer=${leaveData?.organizer}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getRequestLeavesList(response.data.records))
        } else {
            dispatch(getRequestLeavesList([]));
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setRequestLoading(false))
    })
});

export const rejectLeave = createAsyncThunk('rejectLeave', (leaveData, { dispatch }) => {
    dispatch(setRejectLoading(true));
    axios.get(`/vacation/reject?id=${leaveData?.id}`).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response?.data.message, type: 'success' });
            dispatch(getRequestedLeavesListData({ organizer: leaveData?.organizer }))
        } else {
            openNotification({ message: response?.data?.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setRejectLoading(false))
    })
});

export const approveLeave = createAsyncThunk('approveLeave', (leaveData, { dispatch }) => {
    dispatch(setApproveLoading(true));
    axios.get(`/vacation/approve?id=${leaveData?.id}`).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response?.data?.message, type: 'success' });
            dispatch(getRequestedLeavesListData({ organizer: leaveData?.organizer }))
        } else {
            openNotification({ message: response?.data?.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setApproveLoading(false))
    })
});

export const getVacationUserLeaves = createAsyncThunk('getVacationUserLeaves', (userId, { dispatch }) => {
    axios.get(`/leave?id=${userId.userId}&year=${userId.year}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(setUserLeaves(response.data?.record));
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getApiData = createAsyncThunk('getApiData', (filterData, { dispatch }) => {
    return axios.post(`/vacation/by-user/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            return response.data.records
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getUserLeaves, setUserLeaves, setRejectLoading, setApproveLoading, getRequestLeavesList, getUserLeavesList, setRequestLoading, getLeave, setIsUpdating, setIsSuccess, getLeavesList, setTotalrecords } = slice.actions;

export default slice.reducer;
